import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function LineChart() {
  const lineChartOptions:any = {
    series: [
      {
        name: 'Targeted Pateints',
        data: [45, 55, 75, 25, 45, 110],
      },
      {
        name: 'Diagnised Patients',
        data: [30, 45, 55, 20, 35, 90],
      },
    ],
    options: {
      chart: {
        height: 300,
        type: 'line',
        toolbar: false,
        background: '#ffffff', 
      },
      colors: ['#4361EE', '#FF5733'], 
      tooltip: {
        marker: false,
        y: {
          formatter(number:any) {
            return '$' + number;
          },
        },
      },
      stroke: {
        width: [2, 2], 
        curve: 'straight', 
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June'],
        axisBorder: {
          color: '#e0e6ed',
        },
      },
      yaxis: {
        opposite: false,
        labels: {
          offsetX: 0,
        },
      },
      grid: {
        borderColor: '#e0e6ed',
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  };

  return (
    <div className="">
      <ReactApexChart
        series={lineChartOptions.series}
        options={lineChartOptions.options}
        className="rounded-lg bg-white shadow-md overflow-hidden"
        type="line"
        height={400}
      />
    </div>
  );
}
