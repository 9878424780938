import React, { useEffect, useState } from "react";
import { useAppSelector } from "@/core/lib";
import { storage } from "@/utils";
import {

  useAcceptPatientAppointmentMutation,
  useGetPatientAppointsQuery,
  useRejectPatientAppointmentMutation,

} from "../redux";
import formatDateToLongForm from "@/utils/DateFormattter";
import { NavLink, Route, Routes } from "react-router-dom";
import PostAppointment from "./PostAppointment";
import EditAppoint from "./EditAppoint";
import DeletAppointmenr from "./DeletAppointment";
import {
  DocumentDuplicateIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import Search from "@/components/Search";
import { CircularProgress } from "@mui/material";
import PaginationButtons from "@/components/paginationbuttons/PaginationButtons";
import GetAvailbleDates from "./GetAvailbleDates";
import { CalendarMonthRounded } from "@mui/icons-material";
import Filter from "@/components/filters/Filter";
import GetAvailablePatientDates from "./GetAvailbleDates";
import PostPatientAppointment from "./PostAppointment";
import AddAppoint from "./PostAppointment";

const PatientAppointments = () => {
  return (
    <div className=" bg-white shadow">
      <Routes>
      <Route path="/" element={<AllAppoints />}></Route>
        <Route path="/new" element={<PostPatientAppointment />}></Route>
     
      </Routes>
    </div>
  );
};

export default PatientAppointments;

function AllAppoints() {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [from, setStartDateFilter] = useState("");
  const [to, setEndDateFilter] = useState("");
  const [search, setSearchValue] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState<string | null>(
    null
  );
  const [actionType, setActionType] = useState("");
  const [sortedData, setSortedData] = useState<any>([]);

  // Define the type for visibleActions
  const [visibleActions, setVisibleActions] = useState<Record<string, boolean>>(
    {}
  );

  const resetFilters = () => {
    setStartDateFilter("");
    setEndDateFilter("");
  };

  const handleSearchChange = (searchValue: string) => {
    setSearchValue(searchValue);
  };
  const { isLoading, refetch, isFetching, data } = useGetPatientAppointsQuery(
    { pageNumber, pageSize, from, to, search },
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );
  const { patientAppoints } = useAppSelector(
    (state) => state.patientAppointState
  );

  useEffect(() => {
    if (patientAppoints) {
      setSortedData(patientAppoints?.list);
    }
  }, [patientAppoints]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        await refetch();
      } catch (error) {
      } finally {
        setIsLoadingData(false);
      }
    };

    if (!isFetching) {
      setIsLoadingData(true);
      fetchData();
    }
  }, [pageNumber, pageSize, from, to, search, isFetching, refetch]);

  const [AcceptAppointment, { isLoading: load }] =
    useAcceptPatientAppointmentMutation();
  const [RejectAppointment, { isLoading: rejectLoad }] =
    useRejectPatientAppointmentMutation();

  const handleAcceptAppointment = async (appointmentId: string) => {
    try {
      await AcceptAppointment(appointmentId);
      toggleActionsVisibility(appointmentId);
    } catch (error) {
      // Handle error
    }
  };
  const handleRejectAppointment = async (appointmentId: string) => {
    try {
      await RejectAppointment(appointmentId);
      toggleActionsVisibility(appointmentId);
    } catch (error) {
      // Handle error
    }
  };

  const toggleActionsVisibility = (appointmentId: string) => {
    setVisibleActions((prevVisibleActions) => ({
      ...prevVisibleActions,
      [appointmentId]: !prevVisibleActions[appointmentId],
    }));
  };

  if (isLoading || isLoadingData) {
    return (
      <div className="flex flex-row items-center  justify-center  p-4 ">
        <CircularProgress size={24} sx={{ color: "black" }} />{" "}
        <p>Please wait </p>
      </div>
    );
  }

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case "pending":
        return "bg-yellow-500 text-white p-1 rounded-full capitalize";
      case "accepted":
        return "bg-green-500 text-white p-1 rounded-full  capitalize";
      case "rejected":
        return "bg-red-500 text-white p-1 rounded-full capitalize";
      default:
        return "";
    }
  };
  return (
    <>
      <div className="p-3">
        <div className="flex flex-row justify-between ">
          <div className="flex flex-row justify-start">
            <p className="my-2 whitespace-nowrap  text-xl font-medium  text-gray-500">
              Patient Appointments
            </p>
          </div>

          <div className="flex w-full  flex-row items-start justify-end text-gray-300    ">
            <Search onChange={handleSearchChange} />
            <AddAppoint/>
            <NavLink to="/dashboard/appoints/available_dates">
              <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
                <CalendarMonthRounded className="h-5 w-6" />
                <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  w-fit  rounded bg-black px-1 py-1 text-xs  text-white group-hover:visible">
                  get available dates for appointment
                </span>
              </button>
            </NavLink>
            <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
              <DocumentDuplicateIcon className="h-6 w-6" />
              <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
                Export appointment
              </span>
            </button>
            <Filter
              resetFilters={resetFilters}
              children={
                <>
                  <div className="flex flex-row justify-center">
                    <h2 className="text-md mb-2 font-semibold">
                      Date Range Filter
                    </h2>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="fromDate">From :</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="h-12 w-full rounded border border-teal-400   focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                      value={from}
                      onChange={(e) => setStartDateFilter(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="toDate" className="">
                      To
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className=" h-12 w-full rounded border border-teal-400  focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40 "
                      value={to}
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </div>
                </>
              }
            />
          </div>
        </div>
        <div
          className="scrollbar-hidden overflow-scroll
"
        >
          <table className="min-w-full table-auto leading-normal">
            <thead>
              <tr>
                <th className="border-b-2 border-gray-200 bg-light px-1 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  ID
                </th>

                <th className="whitespace-nowrap border-b-2 border-gray-200 bg-light px-1 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Full Names
                </th>
                <th className="border-b-2 border-gray-200 bg-light px-1 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  DOB
                </th>
                <th className="border-b-2 border-gray-200 bg-light px-1 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Gender
                </th>
                <th className="border-b-2 border-gray-200 bg-light px-1 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Phone
                </th>
                <th className="border-b-2 border-gray-200 bg-light px-1 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Email
                </th>
                <th className="border-b-2 border-gray-200 bg-light px-1 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Reason
                </th>
                <th className="border-b-2 border-gray-200 bg-light px-1 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Created At
                </th>
                <th className="border-b-2 border-gray-200 bg-light px-1 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Status
                </th>
                <th className="border-b-2 border-gray-200 bg-light px-1 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="border-b border-gray-300">
              {sortedData.map((appointment: any) => (
                <tr key={appointment.id}>
                  <td className="border-b-1 whitespace-nowrap border-gray-200 px-1 py-1.5 text-left text-sm text-gray-600">
                    {appointment.id}
                  </td>
                  <td className="border-b-1 whitespace-nowrap border-gray-200 px-1 py-1.5 text-left text-sm text-gray-600">
                    {appointment?.patient?.firstName}{" "}
                    {appointment?.patient?.lastName}
                  </td>

                  <td className="border-b-1 whitespace-nowrap border-gray-200 px-1 py-1.5 text-left text-sm text-gray-600">
                    {formatDateToLongForm(appointment?.patient?.dob)}
                  </td>
                  <td className="border-b-1 whitespace-nowrap border-gray-200 px-1 py-1.5 text-left text-sm text-gray-600">
                    {appointment?.patient?.gender}
                  </td>
                  <td className="border-b-1 whitespace-nowrap border-gray-200 px-1 py-1.5 text-left text-sm text-gray-600">
                    {appointment?.patient?.phone_1}
                  </td>
                  <td className="border-b-1 border-gray-200 px-1 py-1.5 text-left text-sm text-gray-600">
                    {appointment?.patient?.email}
                  </td>
                  <td className="border-b-1 capitalize  whitespace-nowrap border-gray-200 px-1 py-1.5 text-left text-sm text-gray-600">
                    {appointment.reason}
                  </td>
                  <td className="border-b-1 whitespace-nowrap border-gray-200 px-1 py-1.5 text-left text-sm text-gray-600">
                    {formatDateToLongForm(appointment.created_at)}
                  </td>
                  <td className="border-b-1 border-gray-200 px-1 py-1.5 text-left text-sm">
                    <span className={getStatusBadgeClass(appointment.status)}>
                      {appointment.status}
                    </span>
                  </td>
                  <td className="border-b-1 whitespace-nowrap border-gray-200 px-2 py-1.5 text-left text-sm text-gray-600">
                    <div>
                      <EditAppoint data={appointment} />
                      <DeletAppointmenr data={appointment} />
                      <button
                        onClick={() => {
                          setSelectedAppointment(appointment.id);
                          setActionType("");
                          toggleActionsVisibility(appointment.id);
                        }}
                        className="text-gray-600 hover:text-gray-800"
                      >
                        &#8230; {/* Three dots character */}
                      </button>
                      {visibleActions[appointment.id] && (
                        <div className=" absolute z-30  flex w-[7vw]  flex-col bg-white py-2  shadow-lg  ">
                          {load ? (
                            <button
                              type="submit"
                              className=" py-2 font-semibold  hover:bg-gray-100"
                              disabled
                            >
                              <CircularProgress
                                size={24}
                                sx={{ color: "black" }}
                              />
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                handleAcceptAppointment(appointment.id)
                              }
                              className=" hover py-2 font-semibold hover:bg-gray-100 "
                            >
                              Accept
                            </button>
                          )}
                          {rejectLoad ? (
                            <button
                              type="submit"
                              className=" py-2 font-semibold  hover:bg-gray-100"
                              disabled
                            >
                              <CircularProgress
                                size={24}
                                sx={{ color: "black" }}
                              />
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                handleRejectAppointment(appointment.id)
                              }
                              className=" py-2 font-semibold  hover:bg-gray-100 "
                            >
                              Reject
                            </button>
                          )}

                          <button
                            onClick={() => {
                              setSelectedAppointment(null);
                              setActionType("");
                              toggleActionsVisibility(appointment.id);
                            }}
                            className=" py-2 font-semibold  hover:bg-gray-100 "
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="my-4 flex flex-col items-center justify-between sm:flex-row">
            <div className="mb-2 flex flex-col items-center justify-center sm:mb-0 sm:flex-row">
              <label htmlFor="" className="text-md mb-2 text-gray-500 sm:mb-0">
                Results Per Page
              </label>
              <select
                className="mx-2 rounded border border-gray-300 bg-light text-sm font-normal text-dark outline-none placeholder:text-gray-500 focus:border-primary focus:outline-none focus:ring-0"
                onChange={(e) => setPageSize(e.target.value)}
              >
                <option value="10">10</option>
                <option value="5">5</option>
                <option value="10">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value={patientAppoints?.total}>All</option>
              </select>
            </div>
            <PaginationButtons
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
              totalPages={patientAppoints?.lastPage || 0}
            />
          </div>
        </div>
      </div>
    </>
  );
}
