import { CategoryModel } from "./categories";
import { categorySlice } from "./categories";
import baseApi from "@/core/lib/base";
import { ResponseModel } from "@/core/types";
import toast from "react-hot-toast";
import { storage } from "@/utils";
import { CatInput } from "../../components/PostCategory";

export const CategoriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<ResponseModel<CategoryModel>, { pageNumber?: number, pageSize?: number, from?: string, to?: string, search?: string }>({
      query: (queryParameters) => {
        const { pageNumber, pageSize, from, to, search } = queryParameters;
        return {
          method: "GET",
          url: "/api/v2/category",
          params: {
            pageNumber,
            pageSize,
            from,
            to,
            search,
          },
        };
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
        
          dispatch(categorySlice.actions.setCategories(data));
        } catch (error) {
            
          throw error; 
        }
      },
    }),
    AddCategory: builder.mutation<ResponseModel<null>, CatInput>({
      query: (payload) => ({
        method: "POST",
        url: "/api/v2/category", 
        body: payload,
        
      }),
      
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
        
          const { data }: any = await queryFulfilled;
          toast.success("category added successfully");
     

        } catch (error: any) {
   
          toast.error(error?.error?.data?.message);
        }
      },
    }),
        UpdateCategory: builder.mutation<ResponseModel<null>, { id: string; data: Partial<CatInput> }>({
      query: ({ id, data }):any => ({
        method: "PATCH",
        url: `/api/v2/category/${id}`, 
        body: data,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
    
        
          toast.success('Category  Updated successfully');
        } catch (error:any) {
  
          toast.error(error?.error?.data?.message)
          throw error;
        }
      },
    }),
    DeleteCategory: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/api/v2/category/${id}`, // Change the endpoint URL
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
    
       
          toast.success('Category  Deleted');
      
        } catch (error:any) {
          toast.error(error?.error?.data?.message);
          throw error;
        }
      },
    }),
  }),
});

export const { useGetCategoriesQuery,useAddCategoryMutation,useUpdateCategoryMutation, useDeleteCategoryMutation} = CategoriesApi;
