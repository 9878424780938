import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "@/core/lib";
import { useGetStudentsQuery, useDeleteStudentMutation } from "../API/redux";
import formatDateToLongForm from "@/utils/DateFormattter";
import AddStudent from "./AddStudent";
import EditStudent from "./EditStudent";
import { DocumentDuplicateIcon, PlusCircleIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@mui/material";
import { setStudent } from "../API/redux/students";
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

// Define the type for a student
interface Student {
  id: string;
  firstName: string;
  lastName: string;
  gender: string;
  dob: string; // or Date if you prefer handling dates directly
  placeOfBirth: string;
  phone_1: string;
  motherName: string;
  fatherName: string;
}

const AllStudents = () => {
  return (
    <div className=" ">
      <Routes>
        <Route path="/" element={<StudentTable />} />
        <Route path="/new" element={<AddStudent />} />
        <Route path="/edit" element={<EditStudent />} />
      </Routes>
    </div>
  );
};

export default AllStudents;

function StudentTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoadingData, setIsLoadingData] = useState(false);

  const { data: studentsData, isLoading, isFetching, error, refetch } = useGetStudentsQuery();
  const [deleteStudent] = useDeleteStudentMutation();

  const students = useAppSelector((state: any) => state.studentState.students) || [];

  // Pagination state
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  // Search state
  const [search, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (studentsData) {
      dispatch(setStudent(studentsData.data));
    }
  }, [studentsData, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        await refetch();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingData(false);
      }
    };

    if (!isFetching) {
      fetchData();
    }
  }, [isFetching, refetch]);

  const handleExport = () => {
    console.log("Export functionality not implemented yet.");
  };

  const handleEdit = (student: Student) => {
    navigate(`/dashboard/students/edit`, { state: { student } });
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteStudent(id).unwrap();
      // Optionally refetch students after deletion
      await refetch();
    } catch (error) {
      console.error("Error deleting student:", error);
    }
  };

  const renderError = (error: FetchBaseQueryError | SerializedError) => {
    if ('status' in error) {
      return `Error: ${error.status} - ${JSON.stringify(error.data)}`;
    } else if ('message' in error) {
      return `Error: ${error.message}`;
    }
    return 'An unknown error occurred.';
  };

  // Filtering and pagination logic
  const filteredStudents = students.filter((student: Student) =>
    `${student.firstName} ${student.lastName} ${student.gender} ${student.dob} ${student.placeOfBirth} ${student.phone_1} ${student.motherName} ${student.fatherName}`
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedStudents = filteredStudents.slice(startIndex, endIndex);

  return (
    <div className="bg-white p-3">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row justify-start">
          <p className="my-2 text-2xl font-medium text-gray-500">Students</p>
        </div>

        <div className="flex w-full flex-row items-start justify-end p-3 text-gray-300">
          <NavLink to="/dashboard/students/new">
            <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
              <PlusCircleIcon className="h-6 w-6" />
              <span className="whitespace-no-wrap invisible absolute top-10 z-30 -mt-2 ml-2 rounded bg-black px-1 py-1 text-xs text-white group-hover:visible">
                Register Student
              </span>
            </button>
          </NavLink>

          <button onClick={handleExport} className="group relative mx-1 rounded bg-primary p-2 capitalize">
            <DocumentDuplicateIcon className="h-6 w-6" />
            <span className="whitespace-no-wrap invisible absolute top-10 z-30 -mt-2 ml-2 rounded bg-black px-1 py-1 text-xs text-white group-hover:visible">
              Export Student
            </span>
          </button>

          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearchValue(e.target.value)}
            className="mx-1 p-2 border border-gray-300 rounded"
          />
        </div>
      </div>

      {isLoading ? (
        <div className="flex flex-row items-center justify-center p-4">
          <CircularProgress size={24} sx={{ color: "black" }} />
          <p>Please wait</p>
        </div>
      ) : error ? (
        <div className="text-red-500">Error loading data: {renderError(error)}</div>
      ) : (
        <>
          <div className="overflow-x-scroll scrollbar-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold whitespace-nowrap uppercase tracking-wider text-gray-600">ID</th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold whitespace-nowrap uppercase tracking-wider text-gray-600">Full Name</th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold whitespace-nowrap uppercase tracking-wider text-gray-600">Gender</th>
                  <th className="group relative border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase whitespace-nowrap tracking-wider text-gray-600">
                    DOB
                    <span className="whitespace-no-wrap invisible absolute top-10 z-30 -mt-2 ml-2 rounded bg-black px-1 py-1 text-xs text-white group-hover:visible">
                      Date Of Birth
                    </span>
                  </th>
                  <th className="group relative border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase whitespace-nowrap tracking-wider text-gray-600">
                    POB
                    <span className="whitespace-no-wrap invisible absolute top-10 z-30 -mt-2 ml-2 rounded bg-black px-1 py-1 text-xs text-white group-hover:visible">
                      Place Of Birth
                    </span>
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase whitespace-nowrap text-gray-600">Phone 1</th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase whitespace-nowrap text-gray-600">Mother Name</th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase whitespace-nowrap text-gray-600">Father Name</th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase whitespace-nowrap text-gray-600">Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedStudents.map((student: Student) => (
                  <tr key={student.id}>
                    <td className="border-b border-gray-200 bg-white px-2 py-3 text-sm text-gray-800">{student.id}</td>
                    <td className="border-b border-gray-200 bg-white px-2 py-3 text-sm text-gray-800">{student.firstName} {student.lastName}</td>
                    <td className="border-b border-gray-200 bg-white px-2 py-3 text-sm text-gray-800">{student.gender}</td>
                    <td className="border-b border-gray-200 bg-white px-2 py-3 text-sm text-gray-800">{formatDateToLongForm(student.dob)}</td>
                    <td className="border-b border-gray-200 bg-white px-2 py-3 text-sm text-gray-800">{student.placeOfBirth}</td>
                    <td className="border-b border-gray-200 bg-white px-2 py-3 text-sm text-gray-800">{student.phone_1}</td>
                    <td className="border-b border-gray-200 bg-white px-2 py-3 text-sm text-gray-800">{student.motherName}</td>
                    <td className="border-b border-gray-200 bg-white px-2 py-3 text-sm text-gray-800">{student.fatherName}</td>
                    <td className="border-b border-gray-200 bg-white px-2 py-3 text-sm text-gray-800">
                      <button onClick={() => handleEdit(student)} className="text-blue-500 hover:text-blue-700">
                        <PencilIcon className="h-6 w-6" />
                      </button>
                      <button onClick={() => handleDelete(student.id)} className="text-red-500 hover:text-red-700">
                        <TrashIcon className="h-6 w-6" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <label className="mr-2">Page Size:</label>
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="p-2 border border-gray-300 rounded"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md"
              >
                Previous
              </button>
              <span className="mx-4">Page {pageNumber}</span>
              <button
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={paginatedStudents.length < pageSize}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md"
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
