import React from "react";
import { useForm } from "react-hook-form";
import { useUpdateStudentMutation } from "../API/redux";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import CircularProgress from '@mui/material/CircularProgress';

// Define the schema for validation
const schema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  gender: z.string().min(1, "Gender is required"),
  dob: z.string().nonempty("Date of birth is required"),
  placeOfBirth: z.string().min(1, "Place of birth is required"),
  phone_1: z.string().min(1, "Phone number is required"),
  motherName: z.string().min(1, "Mother's name is required"),
  fatherName: z.string().min(1, "Father's name is required"),
});

type StudentInput = z.infer<typeof schema>;

const EditStudent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [updateStudent, { isLoading, isSuccess, isError }] = useUpdateStudentMutation();

  const student = location.state?.student || {
    id: '',
    firstName: '',
    lastName: '',
    gender: '',
    dob: '', // Ensure this is a valid date string
    placeOfBirth: '',
    phone_1: '',
    motherName: '',
    fatherName: ''
  };

  // Ensure date is in YYYY-MM-DD format
  const formattedDob = student.dob ? new Date(student.dob).toISOString().split('T')[0] : '';

  const { register, handleSubmit, formState } = useForm<StudentInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: student.firstName,
      lastName: student.lastName,
      gender: student.gender,
      dob: formattedDob,
      placeOfBirth: student.placeOfBirth,
      phone_1: student.phone_1,
      motherName: student.motherName,
      fatherName: student.fatherName
    }
  });

  const onSubmit = async (data: StudentInput) => {
    try {
      await updateStudent({ id: student.id, data }).unwrap();
      navigate('/dashboard/students'); // Redirect after success
    } catch (error) {
      console.error('Update failed:', error);
    }
  };

  return (
    <div className="bg-white p-3">
      <h1 className="text-2xl font-medium text-gray-500">Edit Student</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label className="block text-gray-700">First Name</label>
          <input
            type="text"
            {...register('firstName')}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary"
          />
          {formState.errors.firstName && <p className="text-red-500">{formState.errors.firstName.message}</p>}
        </div>
        <div>
          <label className="block text-gray-700">Last Name</label>
          <input
            type="text"
            {...register('lastName')}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary"
          />
          {formState.errors.lastName && <p className="text-red-500">{formState.errors.lastName.message}</p>}
        </div>
        <div>
          <label className="block text-gray-700">Gender</label>
          <input
            type="text"
            {...register('gender')}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary"
          />
          {formState.errors.gender && <p className="text-red-500">{formState.errors.gender.message}</p>}
        </div>
        <div>
          <label className="block text-gray-700">Date of Birth</label>
          <input
            type="date"
            {...register('dob')}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary"
          />
          {formState.errors.dob && <p className="text-red-500">{formState.errors.dob.message}</p>}
        </div>
        <div>
          <label className="block text-gray-700">Place of Birth</label>
          <input
            type="text"
            {...register('placeOfBirth')}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary"
          />
          {formState.errors.placeOfBirth && <p className="text-red-500">{formState.errors.placeOfBirth.message}</p>}
        </div>
        <div>
          <label className="block text-gray-700">Phone 1</label>
          <input
            type="text"
            {...register('phone_1')}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary"
          />
          {formState.errors.phone_1 && <p className="text-red-500">{formState.errors.phone_1.message}</p>}
        </div>
        <div>
          <label className="block text-gray-700">Mother's Name</label>
          <input
            type="text"
            {...register('motherName')}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary"
          />
          {formState.errors.motherName && <p className="text-red-500">{formState.errors.motherName.message}</p>}
        </div>
        <div>
          <label className="block text-gray-700">Father's Name</label>
          <input
            type="text"
            {...register('fatherName')}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary"
          />
          {formState.errors.fatherName && <p className="text-red-500">{formState.errors.fatherName.message}</p>}
        </div>
        <button
          type="submit"
          className="mt-4 w-full bg-primary text-white p-2 rounded"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Save Changes"}
        </button>
        {isError && <p className="text-red-500">Failed to update student</p>}
      </form>
    </div>
  );
};

export default EditStudent;
