import { PatientAppointmentModel, patientAppointSlice } from "./appoints";

import baseApi from "@/core/lib/base";
import { ResponseModel } from "@/core/types";
import toast from "react-hot-toast";
import { AppointmentInput } from "../components/PostAppointment";
import { AvailableDates, DatesSlice } from "./appoints/available";

export const patientAppoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    GetPatientAppoints: builder.query<ResponseModel<PatientAppointmentModel>, { pageNumber?: number, pageSize?: number, from?: string, to?: string, status?: string, search?: string }>({
      query: (queryParameters) => {
        const { pageNumber, pageSize, from, to, status,search } = queryParameters;
        return {
          method: "GET",
          url: "/api/v2/patient_appointment",
          params: {
            pageNumber,
            pageSize,
            from,
            to,
            status,
            search
          }
        }
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          dispatch(patientAppointSlice.actions.setPatientAppoints
            (data));
        } catch (error) {
          throw error;
        }
      },
    }),
    addPatientAppointment: builder.mutation<ResponseModel<null>, AppointmentInput>({
      query: (payload) => ({
        method: "POST",
        url: "/api/v2/patient_appointment", 
        body: payload,
        
      }),
      
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
        
          const { data }: any = await queryFulfilled;
          toast.success("appointment added successfully");
         

        } catch (error: any) {
   
          toast.error(error?.error?.data?.message);
        }
      },
    }),
        UpdatePatientAppointment: builder.mutation<ResponseModel<null>, { id: string; data: Partial<AppointmentInput> }>({
      query: ({ id, data }):any => ({
        method: "PATCH",
        url: `/api/v2/patient_appointment/${id}`, 
        body: data,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
    
        
          toast.success('appointment  Updated successfully');
        } catch (error:any) {
  
          toast.error(error?.error?.data?.message)
          throw error;
        }
      },
    }),
    DeletePatientAppoint: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/api/v2/patient_appointment/${id}`, // Change the endpoint URL
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
    
       
          toast.success('appointment Deleted ');
      
        } catch (error:any) {
          toast.error(error?.error?.data?.message);
          throw error;
        }
      },
    }),
    GetAvailableDatesPatient: builder.query<ResponseModel<null>, AvailableDates>({
      query: (queryParameters) => {

        return {
          method: "GET",
          url: "/api/v2/patient_appointment/available-dates/all",
      
        }
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          dispatch(DatesSlice.actions.setDate(data));
        } catch (error) {
          throw error;
        }
      },
    }),
    AddAvailablePatientDate: builder.mutation<ResponseModel<null>,   any>({
      query: (payload) => ({
        method: "POST",
        url: "/api/v2/patient_appointment/available-dates", 
        body: payload,
        
      }),
      
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
        
          const { data }: any = await queryFulfilled;
          toast.success("Date added successfully");
         

        } catch (error: any) {
   
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    AcceptPatientAppointment: builder.mutation<ResponseModel<null>, string>({
      query: (appointmentId) => ({
        method: "POST",
        url: `/api/v2/patient_appointment/${appointmentId}/accept`, 
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          toast.success("Appointment accepted successfully");
        } catch (error: any) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    RejectPatientAppointment: builder.mutation<ResponseModel<null>, string>({
      query: (appointmentId) => ({
        method: "POST", 
        url: `/api/v2/patient_appointment/${appointmentId}/reject`, 
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          toast.success("Appointment rejected successfully");
        } catch (error: any) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
  }),
  })


export const { useGetPatientAppointsQuery, useUpdatePatientAppointmentMutation,useDeletePatientAppointMutation,useAddPatientAppointmentMutation,useGetAvailableDatesPatientQuery,useAddAvailablePatientDateMutation, useAcceptPatientAppointmentMutation,useRejectPatientAppointmentMutation} = patientAppoints;
