import React from 'react';
import ReactDOM from 'react-dom/client';
import 'apexcharts/dist/apexcharts.css';
import i18n from "./i18n"; 
import App from '@/App';


import '@/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { I18nextProvider } from 'react-i18next';

ReactDOM.createRoot(document.getElementById('app-root') as HTMLElement).render(
	<React.StrictMode>
  <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
	</React.StrictMode>
);

