import { CategoryModel} from './model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	categories: CategoryModel | null;
}

const initialState: InitialState = {
	categories: null,
};
console.log(initialState)
export const categorySlice = createSlice({
	initialState,
	name: 'category',
	reducers: {
		setCategories: (state, action: PayloadAction<CategoryModel>) => {
			state.categories = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
