import React, { useEffect, useState, useTransition } from "react";
import { useAppSelector } from "@/core/lib";
import { useGetQuestionsQuery } from "../API/redux";
import { storage } from "@/utils";
import PaginationButtons from "@/components/paginationbuttons/PaginationButtons";
import formatDateToLongForm from "@/utils/DateFormattter";
import AddQuestion from "./AddQuestion";
import EditQuestion from "./EditQuestion";
import DeleteQuestion from "./DeleteQuestion";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { DocumentDuplicateIcon, FunnelIcon } from "@heroicons/react/24/outline";
import Search from "@/components/Search";
import Select from "@/components/select/SelectField";
import Filter from "@/components/filters/Filter";

const AllQuestions = () => {
  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [status, SetStatusFilter] = useState("");
  const [from, setStartDateFilter] = useState("");
  const [to, setEndDateFilter] = useState("");
  const [search, setSearchValue] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);

  const resetFilters = () => {
    SetStatusFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
    setShowFilterModal(false);
  };

  const handleFilterClick = () => {
    setShowFilterModal(true);
  };

  const applyDateFilter = () => {
    refetch();
    setShowFilterModal(false);
  };

  const { isLoading, refetch, isFetching } = useGetQuestionsQuery(
    { pageNumber, pageSize, status, from, to, search },
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );

  const handleSearchChange = (searchValue: any) => {
    setSearchValue(searchValue);
  };
  const [sortedData, setSortedData] = useState<any>([]);
  const { questions } = useAppSelector((state: any) => state.questionState);

  useEffect(() => {
    if (questions) {
      setSortedData(questions?.list);
    }
  }, [questions]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        await refetch();
      } catch (error) {
      } finally {
        setIsLoadingData(false);
      }
    };

    if (!isFetching) {
      setIsLoadingData(true);
      fetchData();
    }
  }, [pageNumber, pageSize, from, to, status, search, isFetching, refetch]);

  return (
    <div className="px-4 ">
      <div className="flex w-full flex-row justify-end ">
        {" "}
        <div className="mb-4 flex w-1/4  flex-row items-center justify-center">
          <label htmlFor="languageSelect" className="mr-2  whitespace-nowrap">
            Select Language:
          </label>
          <Select
            id="languageSelect"
            className="borde-gray-400 rounded border px-2 py-1"
            onChange={(e) => setSelectedLanguage(e.target.value)}
            value={selectedLanguage}
          >
            <option value="en">English</option>
            <option value="fr">French</option>
            <option value="ki">Kinyarwanda</option>
          </Select>
        </div>
      </div>

      <div className=" bg-white  p-3 ">
        <div className="flex flex-row justify-between ">
          <div className="flex flex-row justify-start">
            <p className="my-2 text-2xl  font-medium  text-gray-500">
              Questions
            </p>
          </div>
          <div className="flex w-full flex-row  items-start justify-end p-3 text-gray-300    ">
            <Search onChange={handleSearchChange} />

            <AddQuestion />
            <button className="group relative mx-1 rounded bg-primary p-2  capitalize hover:bg-teal-700">
              <DocumentDuplicateIcon className="h-6 w-6" />
              <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
                Export Questions
              </span>
            </button>
           
            <Filter
            resetFilters={resetFilters}
              children={
                <>
                <div className="flex flex-row justify-center">
                <h2 className="mb-2 text-md font-semibold">
                    Date Range Filter
                  </h2>
                </div>
               
                  <div className="mb-4">
                    <label htmlFor="fromDate">From :</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="h-12 w-full rounded border border-teal-400   focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                      value={from}
                      onChange={(e) => setStartDateFilter(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="toDate" className="font-semibold">
                      To Date:
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className=" h-12 w-full rounded border border-teal-400  focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40 "
                      value={to}
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </div>
                
                </>
              }
            />
          </div>
        </div>
        {isLoading || !sortedData ? (
          <div className="flex flex-row items-center  justify-center  p-4 ">
            <CircularProgress size={24} sx={{ color: "black" }} />{" "}
            <p>Please wait </p>
          </div>
        ) : (
          <>
            <table className="min-w-full table-auto leading-normal">
              <thead>
                <tr>
                  <th className="border-b-2 border-gray-200 bg-light  px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    id
                  </th>

                  <th className="border-b-2 border-gray-200 bg-light  px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    title
                  </th>

                  <th className="border-b-2 border-gray-200 bg-light  px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    category Description
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light  px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    level
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light  px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    marks
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light  px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    createdAt
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light  px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="border-b border-gray-300 ">
                {sortedData.map((question: any, index: any) => (
                  <tr key={question.id}>
                    <td className='border-b-1 text-gray-600" border-gray-200 px-5 py-3 text-left text-sm '>
                      {question.id}
                    </td>

                    <td className="border-b-1 border-gray-200 px-5   py-3 text-left text-sm capitalize   text-gray-600 ">
                      {question.title[selectedLanguage]}
                    </td>

                    <td className="border-b-1 whitespace-nowrap   border-gray-200  px-5 py-3  text-left text-sm capitalize   text-gray-600 ">
                      {question.category.description[selectedLanguage]}
                    </td>

                    <td className="border-b-1 whitespace-nowrap  border-gray-200  px-5 py-3 text-left text-sm  text-gray-600 ">
                      {question.category.lowLevel} to{" "}
                      {question.category.highLevel}
                    </td>
                    <td className="border-b-1 border-gray-200  px-5 py-3 text-center text-sm  text-gray-600 ">
                      {question.marks}
                    </td>
                    <td className="border-b-1 whitespace-nowrap border-gray-200 px-5 py-3 text-left text-sm  text-gray-600 ">
                      {formatDateToLongForm(question.created_at)}
                    </td>
                    <td className="border-b-1 border-gray-200  px-5 py-3 text-center text-sm  text-gray-600 ">
                      <div className="flex flex-row ">
                        <EditQuestion data={question} />
                        <DeleteQuestion data={question} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="my-4 flex flex-col items-center justify-between sm:flex-row">
              <div className="mb-2 flex flex-col items-center justify-center sm:mb-0 sm:flex-row">
                <label
                  htmlFor=""
                  className="text-md mb-2 text-gray-500 sm:mb-0"
                >
                  Results Per Page
                </label>
                <select
                  className="mx-2 rounded border border-gray-300 bg-light text-sm font-normal text-dark outline-none placeholder:text-gray-500 focus:border-primary focus:outline-none focus:ring-0"
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="5">5</option>
                  <option value="10">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value={sortedData?.total}>All</option>
                </select>
              </div>
              <PaginationButtons
                currentPage={pageNumber}
                setCurrentPage={setPageNumber}
                totalPages={questions?.lastPage || 0}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AllQuestions;
