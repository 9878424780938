import React from "react";
import IMG from "@/assets/images/igaju logo.png";
import { Outlet, ScrollRestoration } from "react-router-dom";
import DarkModeToggle from "@/utils/Darkmode";

const   AuthLayout = () => (
  /* eslint-disable react/style-prop-object */
  <>

    <div className="bg-white ">
      <div className="flex h-screen justify-center">
        <div className="mx-auto flex w-full max-w-md items-center  px-6  h-full lg:w-2/6">
          <div className="flex-1">
            <div className="text-center">
              <div className="mx-auto flex  flex-col items-center justify-center my-5 ">
                <img className="w-32  " src={IMG} alt="" />
<p className="text-lg font-medium text-teal-900">IGAJU</p>
              </div>


            </div>

            <div className="mt-10">
              <Outlet />
            </div>
          </div>
        </div>
        <div className="bg-teal-800  hidden bg-cover lg:block lg:w-2/3">
          <div className="flex h-full items-center bg-gray-900 bg-opacity-40 px-20">
            <div>
              <h2 className="text-2xl font-bold text-white sm:text-3xl">IGAJU</h2>
            
              <p className="mt-3 max-w-2xl text-2xl text-gray-300">
                Every child is a puzzle piece, beautifully unique, and when connected, they create a masterpiece.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div><ScrollRestoration /></>
);

export default AuthLayout;
