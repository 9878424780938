
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface AvailableDate {
    id: string;
    date: string; 
    status: "available" | "unavailable" | "pending" | string;
  }
  
  export interface AvailableDates {
    [date: string]: AvailableDate[];
  }
  

interface InitialState {
	availableDates: AvailableDates | null;
}

const initialState: InitialState = {
	availableDates: null,
};
console.log(initialState)

export const DatesSlice = createSlice({
	initialState,
	name: 'dates',
	reducers: {
		setDate: (state, action: PayloadAction<AvailableDates>) => {
			state.availableDates = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
