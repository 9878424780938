import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";

import { z } from "zod";
import { Form } from "@/components/form";
import { useAddStudentMutation } from "../API/redux";
import Select from "@/components/select/SelectField";
import { NavLink } from "react-router-dom";

const schema = z.object({
  locationId: z.string().min(0, "Location ID is required"),
  email: z.string().min(1, "Email is required"),
  firstName: z.string().min(1, "First Name is required"),
  lastName: z.string().min(1, "Last Name is required"),
  dob: z.string().min(1, "Date of Birth is required"),
  placeOfBirth: z.string().min(1, "Place of Birth is required"),
  phone_1: z.string().min(1, "Phone 1 is required"),
  phone_2: z.string(),
  guardian: z.string().min(1, "Guardian is required"),
  fatherName: z.string().min(1, "Father's Name is required"),
  motherName: z.string().min(1, "Mother's Name is required"),
  gender: z.string().min(1, "Gender is required"),
});

export type StudentInput = z.TypeOf<typeof schema>;

export default function AddStudent({ handleFilterAndAdd }: any) {
  const [OnPost, { isLoading }] = useAddStudentMutation();
  const [selectedRole, setSelectedRole] = useState("");

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(event.target.value);
    console.log(selectedRole)
  };
  

  return (
    <div className="rounded bg-white p-3 shadow">
      <div className="flex flex-row items-center justify-between pb-5    ">
        <h3 className="  text-2xl font-medium   text-gray-600">Add Student</h3>
        <NavLink to="/dashboard/students">
          <button className="mx-5 rounded  bg-primary px-2  py-1  text-white ">
            {" "}
            All Students
          </button>
        </NavLink>
      </div>
      <Form<StudentInput, typeof schema> schema={schema} onSubmit={OnPost}>
        {({ register, formState }) => (
          <div className="">
            <div className="mt-2  grid grid-cols-3 gap-2">
              <InputField
                type="number"
                label="Location ID"
                placeholder="Location ID"
                loading={isLoading}
                error={formState.errors.locationId}
                registration={register("locationId")}
                className="h-13"
              />
              <InputField
                type="text"
                label="Email"
                placeholder="Email"
                loading={isLoading}
                error={formState.errors.email}
                registration={register("email")}
                className="h-13"
              />
              <InputField
                type="text"
                label="First Name"
                placeholder="First Name"
                loading={isLoading}
                error={formState.errors.firstName}
                registration={register("firstName")}
                className="h-13"
              />
              <InputField
                type="text"
                label="Last Name"
                placeholder="Last Name"
                loading={isLoading}
                error={formState.errors.lastName}
                registration={register("lastName")}
                className="h-13"
              />
              <InputField
                type="date"
                label="Date of Birth"
                placeholder="Date of Birth"
                loading={isLoading}
                error={formState.errors.dob}
                registration={register("dob")}
                className="h-13"
              />
              <InputField
                type="text"
                label="Place of Birth"
                placeholder="Place of Birth"
                loading={isLoading}
                error={formState.errors.placeOfBirth}
                registration={register("placeOfBirth")}
                className="h-13"
              />
              <InputField
                type="text"
                label="Phone 1"
                placeholder="Phone 1"
                loading={isLoading}
                error={formState.errors.phone_1}
                registration={register("phone_1")}
                className="h-13"
              />
              <InputField
                type="text"
                label="Phone 2"
                placeholder="Phone 2"
                loading={isLoading}
                error={formState.errors.phone_2}
                registration={register("phone_2")}
                className="h-13"
              />
              <InputField
                type="text"
                label="Guardian"
                placeholder="Guardian"
                loading={isLoading}
                error={formState.errors.guardian}
                registration={register("guardian")}
                className="h-13"
              />
              <InputField
                type="text"
                label="Father's Name"
                placeholder="Father's Name"
                loading={isLoading}
                error={formState.errors.fatherName}
                registration={register("fatherName")}
                className="h-13"
              />
              <InputField
                type="text"
                label="Mother's Name"
                placeholder="Mother's Name"
                loading={isLoading}
                error={formState.errors.motherName}
                registration={register("motherName")}
                className="h-13"
              />
              <div className="flex flex-col  ">
                <label
                  htmlFor=""
                  className="mb-2 block text-sm text-gray-600 dark:text-gray-400"
                >
                  {" "}
                  Gender
                </label>
                <Select
                  name="gender"
                  className="h-12 rounded border border-gray-400 text-gray-400"
                  registration={register("gender")}
                  onChange={handleRoleChange}
                  value={selectedRole}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Select>
                {formState.errors.gender && (
                  <span className="font-italics mt-2 block text-sm font-medium capitalize text-danger">
                    {formState.errors.gender.message}{" "}
                    {/* Access the error message */}
                  </span>
                )}
              </div>
            </div>
            {isLoading ? (
              <button
                type="submit"
                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                disabled
              >
                <CircularProgress size={24} sx={{ color: "white" }} />
              </button>
            ) : (
              <button
                type="submit"
                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
              >
                Add Student
              </button>
            )}
          </div>
        )}
      </Form>
    </div>
  );
}
