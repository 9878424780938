import React, { useEffect, useState, useTransition } from "react";
import { useAppSelector } from "@/core/lib";
import { storage } from "@/utils";
import PaginationButtons from "@/components/paginationbuttons/PaginationButtons";
import formatDateToLongForm from "@/utils/DateFormattter";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import {
  DocumentDuplicateIcon,
  FunnelIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import Search from "@/components/Search";
import { useGetUsersQuery } from "@/app/auth/API";
import UpdateUser from "./EditUser";
import DeleteUser from "./DeletUser";
import { NavLink, Route, Routes } from "react-router-dom";
import AddUser from "./AddUser";
import Filter from "@/components/filters/Filter";
import Select from "@/components/select/SelectField";

const Users = () => {
  return (
    <div className=" ">
      <Routes>
        <Route path="/" element={<GetUsers />}></Route>

        <Route path="/new" element={<AddUser />}></Route>
      </Routes>
    </div>
  );
};

export default Users;

function GetUsers() {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [status, SetStatusFilter] = useState("");
  const [from, setStartDateFilter] = useState("");
  const [to, setEndDateFilter] = useState("");
  const [occupation, setOccupation] = useState("");
  const [role, setRole] = useState("");
  const [search, setSearchValue] = useState("");

  const resetFilters = () => {
    SetStatusFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
  };

  const { isLoading, refetch, isFetching } = useGetUsersQuery(
    { pageNumber, pageSize, from, to, search, status, occupation, role },
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );

  const handleSearchChange = (searchValue: any) => {
    setSearchValue(searchValue);
  };
  const [sortedData, setSortedData] = useState<any>([]);
  const { users } = useAppSelector((state: any) => state.usersState);

  useEffect(() => {
    if (users) {
      setSortedData(users?.list);
    }
  }, [users]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        await refetch();
      } catch (error) {
      } finally {
        setIsLoadingData(false);
      }
    };

    if (!isFetching) {
      setIsLoadingData(true);
      fetchData();
    }
  }, [
    pageNumber,
    pageSize,
    from,
    to,
    search,
    status,
    occupation,
    isFetching,
    role,
    refetch,
  ]);

  return (
    <div className=" bg-white  p-3 ">
      <div className="flex flex-row justify-between ">
        <div className="flex flex-row justify-start">
          <p className="my-2 text-2xl  font-medium  text-gray-500">Users</p>
        </div>
        <div className="flex w-full flex-row  items-start justify-end p-3 text-gray-300    ">
          <Search onChange={handleSearchChange} />

          <NavLink to="/dashboard/users/new">
            <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
              <PlusCircleIcon className="h-6 w-6" />
              <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
                Register User
              </span>
            </button>
          </NavLink>
          <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
            <DocumentDuplicateIcon className="h-6 w-6" />
            <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
              Export users
            </span>
          </button>

          <Filter
            resetFilters={resetFilters}
            children={
              <>
                <div className="flex flex-row justify-center">
                  <h2 className="text-md mb-2 font-semibold">Filter Users</h2>
                </div>
                <div className="grid grid-cols-2 gap-3 ">
                  <div className="mb-4">
                    <label htmlFor="fromDate">Occupation :</label>
                    <input
                      type="text"
                      placeholder="Input occupation"
                      className="h-12 w-full rounded border border-teal-400   focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                      onChange={(e) => setOccupation(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="fromDate">Role :</label>
                    <input
                      type="text"
                      placeholder="Input user role"
                      className="h-12 w-full rounded border border-teal-400   focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                      onChange={(e) => setRole(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="fromDate">status :</label>
                    <select
                      className="h-12 w-full rounded border border-teal-400   focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                      name="role"
                      onChange={(e) => SetStatusFilter(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="fromDate">From :</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="h-12 w-full rounded border border-teal-400   focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                      value={from}
                      onChange={(e) => setStartDateFilter(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="toDate" className="">
                      To
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className=" h-12 w-full rounded border border-teal-400  focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40 "
                      value={to}
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-row items-center  justify-center  p-4 ">
          <CircularProgress size={24} sx={{ color: "black" }} />{" "}
          <p>Please wait </p>
        </div>
      ) : (
        <>
          <div className="scrollbar-hidden overflow-scroll">
            <table className="min-w-full table-auto leading-normal">
              <thead>
                <tr>
                  <th className="border-b-2 border-gray-200 bg-light  px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    id
                  </th>

                  <th className="border-b-2 border-gray-200 bg-light  px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Full Names
                  </th>

                  <th className="border-b-2 border-gray-200 bg-light  px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Username
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light  px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Email
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light  px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    phone
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light  px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    role
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light  px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Occupation
                  </th>
                
                  <th className="border-b-2 border-gray-200 bg-light  px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Created
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light  px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    status
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light  px-2 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="border-b border-gray-300 ">
                {sortedData.map((user: any, index: any) => (
                  <tr key={user.id}>
                    <td className='border-b-1 text-gray-600" border-gray-200 px-2 py-2 text-left text-sm '>
                      {index + 1}
                    </td>

                    <td className="border-b-1 whitespace-nowrap border-gray-200  px-2   py-2  text-left text-sm font-medium capitalize   text-gray-600 ">
                      {user.firstName} {user.lastName}
                    </td>
                    <td className="border-b-1 whitespace-nowrap   border-gray-200  px-2 py-2  text-left text-sm capitalize   text-gray-600 ">
                      {user.username}
                    </td>

                    <td className="border-b-1 border-gray-200   px-2 py-2 text-left text-sm  text-gray-600 ">
                      {user.email}
                    </td>
                    <td className="border-b-1 border-gray-200  py-2 pr-2 text-start text-sm  text-gray-600 ">
                      {user.phoneNumber}
                    </td>
                    <td className="border-b-1 border-gray-200  px-2 py-2 text-start text-sm capitalize  text-gray-600 ">
                      {user.role}
                    </td>
                     <td className="border-b-1 border-gray-200  px-2 py-2 text-start text-sm capitalize  text-gray-600 ">
                      {user.occupation}
                    </td>
                    <td className="border-b-1 whitespace-nowrap border-gray-200 px-2 py-2 text-left text-sm  text-gray-600 ">
                      {formatDateToLongForm(user.created_at)}
                    </td>
                    <td>
                      {user?.status === 1 ? (
                        <span className="rounded-lg bg-green-500 py-1 px-3 text-xs text-white">
                          Active
                        </span>
                      ) : (
                        <span className="rounded-lg bg-red-400 py-1 px-2 text-xs text-white">
                          Inactive
                        </span>
                      )}
                    </td>

                    <td className="border-b-1 border-gray-200  px-2 py-2 text-center text-sm  text-gray-600 ">
                      <div className="flex flex-row ">
                        <UpdateUser data={user} />
                        <DeleteUser data={user} />
                      </div>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="my-4 flex flex-col items-center justify-between sm:flex-row">
            <div className="mb-2 flex flex-col items-center justify-center sm:mb-0 sm:flex-row">
              <label htmlFor="" className="text-md mb-2 text-gray-500 sm:mb-0">
                Results Per Page
              </label>
              <select
                className="mx-2 rounded border border-gray-300 bg-light text-sm font-normal text-dark outline-none placeholder:text-gray-500 focus:border-primary focus:outline-none focus:ring-0"
                onChange={(e) => setPageSize(e.target.value)}
              >
                <option value="10">10</option>
                <option value="5">5</option>
                <option value="10">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value={sortedData?.total}>All</option>
              </select>
            </div>
            <PaginationButtons
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
              totalPages={users?.lastPage || 0}
            />
          </div>
        </>
      )}
    </div>
  );
}
