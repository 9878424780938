import React, { useEffect, useState } from "react";
import { useParams, NavLink, Route, Routes } from "react-router-dom";
import { useGetAssignedStudentsParentsQuery } from "../API/redux";
import CircularProgress from "@mui/material/CircularProgress";
import Search from "@/components/Search";
import Filter from "@/components/filters/Filter";
import AssignParent from "./AssignParents";
import EditAssignParent from "./EditAssignParent"; // Adjust import path if needed
import DeleteAssignment from "./DeleteAssignParent"; // Adjust import path if needed
import { PlusCircleIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";

const AllParentsAssignments = () => {
  return (
    <div className="bg-white p-3">
      <Routes>
        <Route path="/" element={<AssignmentTable />} />
        <Route path="/new" element={<AssignParent />} />
      </Routes>
    </div>
  );
};

export default AllParentsAssignments;

function AssignmentTable() {
  const { studentId: studentIdParam } = useParams<{ studentId: string }>();
  const studentId: number = Number(studentIdParam) || 0;

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [status, setStatusFilter] = useState("");
  const [from, setStartDateFilter] = useState("");
  const [to, setEndDateFilter] = useState("");
  const [search, setSearchValue] = useState("");

  const resetFilters = () => {
    setStatusFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
  };

  const handleSearchChange = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const { data: assignedParents, error, isLoading, refetch, isFetching } = useGetAssignedStudentsParentsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [sortedData, setSortedData] = useState<any>([]);

  useEffect(() => {
    if (assignedParents) {
      setSortedData(assignedParents);
    }
  }, [assignedParents]);

  const filteredData = sortedData
    .filter((assignment: any) => 
      (assignment.firstName + " " + assignment.lastName).toLowerCase().includes(search.toLowerCase())
    )
    .filter((assignment: any) => 
      (status ? assignment.status === status : true) &&
      (from ? new Date(assignment.date) >= new Date(from) : true) &&
      (to ? new Date(assignment.date) <= new Date(to) : true)
    );

  const paginatedData = filteredData.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= Math.ceil(filteredData.length / pageSize)) {
      setPageNumber(newPage);
    }
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPageNumber(1); // Reset to first page on page size change
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetch();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (!isFetching) {
      fetchData();
    }
  }, [pageNumber, pageSize, from, to, status, search, isFetching, refetch]);

  return (
    <div className="bg-white p-3">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row justify-start">
          <p className="my-2 text-2xl font-medium text-gray-500">Parent Assignments</p>
        </div>

        <div className="flex w-full flex-row items-start justify-end p-3 text-gray-300">
          <Search onChange={handleSearchChange} />

          <NavLink to="/dashboard/assignparents/new">
            <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
              <PlusCircleIcon className="h-6 w-6" />
              <span className="whitespace-no-wrap invisible absolute top-10 z-30 -mt-2 ml-2 rounded bg-black px-1 py-1 text-xs text-white group-hover:visible">
                Assign Parent
              </span>
            </button>
          </NavLink>

          <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
            <DocumentDuplicateIcon className="h-6 w-6" />
            <span className="whitespace-no-wrap invisible absolute top-10 z-30 -mt-2 ml-2 rounded bg-black px-1 py-1 text-xs text-white group-hover:visible">
              Export Assignments
            </span>
          </button>

          <Filter
            resetFilters={resetFilters}
            children={
              <>
                <div className="flex flex-row justify-center">
                  <h2 className="mb-2 text-md font-semibold">Date Range Filter</h2>
                </div>
                <div className="mb-4">
                  <label htmlFor="fromDate">From :</label>
                  <input
                    type="date"
                    id="fromDate"
                    className="h-12 w-full rounded border border-teal-400 focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                    value={from}
                    onChange={(e) => setStartDateFilter(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="toDate">To :</label>
                  <input
                    type="date"
                    id="toDate"
                    className="h-12 w-full rounded border border-teal-400 focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                    value={to}
                    onChange={(e) => setEndDateFilter(e.target.value)}
                  />
                </div>
              </>
            }
          />
        </div>
      </div>

      {isLoading ? (
        <div className="flex flex-row items-center justify-center p-4">
          <CircularProgress size={24} sx={{ color: "black" }} /> <p>Please wait</p>
        </div>
      ) : error ? (
        <p className="text-red-500">Error loading assignments</p>
      ) : (
        <div className="overflow-x-scroll scrollbar-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Student Name
                </th>
                <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Parent Name(s)
                </th>
                <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((assignment: any) => (
                <tr key={assignment.id}>
                  <td className="capitalize border-b-1 text-gray-600 border-gray-200 px-2 py-1.5 text-left text-sm">
                    {assignment.firstName} {assignment.lastName} {assignment.email}
                  </td>
                  <td className="capitalize border-b-1 text-gray-600 border-gray-200 px-2 py-1.5 text-left text-sm">
                    {assignment.parents.map((parent: any) => (
                      <div key={parent.id}>
                        {parent.firstName} {parent.lastName}
                      </div>
                    ))}
                  </td>
                  <td className="border-b-1 text-gray-600 border-gray-200 px-2 py-1.5 text-left text-sm flex">
                    {assignment.parents.map((parent: any) => (
                      <DeleteAssignment
                        key={parent.id}
                        data={{
                          id: parent.id, // Parent ID
                          assignmentName: `${assignment.firstName} ${assignment.lastName}`, // Example assignment name
                          studentId: assignment.id // Assuming assignment.id is the student ID
                        }}
                        handleFilter={() => setSearchValue(search)}
                      />
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(pageNumber - 1)}
          disabled={pageNumber === 1}
          className="px-4 py-2 bg-gray-300 rounded"
        >
          Previous
        </button>
        <span>
          Page {pageNumber} of {Math.ceil(filteredData.length / pageSize)}
        </span>
        <button
          onClick={() => handlePageChange(pageNumber + 1)}
          disabled={pageNumber * pageSize >= filteredData.length}
          className="px-4 py-2 bg-gray-300 rounded"
        >
          Next
        </button>
      </div>

      {/* Page Size Selector */}
      <div className="flex justify-end mt-4">
        <label htmlFor="pageSize" className="mr-2">Items per page:</label>
        <select
          id="pageSize"
          value={pageSize}
          onChange={(e) => handlePageSizeChange(Number(e.target.value))}
          className="p-2 border border-gray-300 rounded"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
    </div>
  );
}
