import React, { useEffect, useState } from "react";
import { useParams, NavLink, Route, Routes } from "react-router-dom";
import { useGetAssignedStudentsTeachersQuery } from "../API/redux";
import CircularProgress from "@mui/material/CircularProgress";
import Search from "@/components/Search";
import Filter from "@/components/filters/Filter";
import AssignTeacher from "./AssignTeachers";
import EditAssignTeacher from "./EditAssignTeacher";
import DeleteAssignment from "./DeleteAssignTeacher";
import { PlusCircleIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";

const AllTeachersAssignments = () => {
  return (
    <div className="bg-white p-3">
      <Routes>
        <Route path="/" element={<AssignmentTable />} />
        <Route path="/new" element={<AssignTeacher />} />
      </Routes>
    </div>
  );
};

export default AllTeachersAssignments;

function AssignmentTable() {
  const { studentId: studentIdParam } = useParams<{ studentId: string }>();
  const studentId: number = Number(studentIdParam) || 0;

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [status, setStatusFilter] = useState("");
  const [from, setStartDateFilter] = useState("");
  const [to, setEndDateFilter] = useState("");
  const [search, setSearchValue] = useState("");

  const resetFilters = () => {
    setStatusFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
  };

  const handleSearchChange = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const { data: assignedTeachers, error, isLoading, refetch, isFetching } = useGetAssignedStudentsTeachersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [sortedData, setSortedData] = useState<any>([]);

  useEffect(() => {
    if (assignedTeachers) {
      setSortedData(assignedTeachers);
    }
  }, [assignedTeachers]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetch();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (!isFetching) {
      fetchData();
    }
  }, [pageNumber, pageSize, from, to, status, search, isFetching, refetch]);

  // Filtering and pagination
  const filteredData = sortedData.filter((item: any) => {
    const studentName = `${item.firstName} ${item.lastName}`.toLowerCase();
    const teacherNames = item.teachers
      .map((teacher: any) => `${teacher.firstName} ${teacher.lastName}`.toLowerCase())
      .join(" ");
    return studentName.includes(search.toLowerCase()) || teacherNames.includes(search.toLowerCase());
  });

  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="bg-white p-3">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row justify-start">
          <p className="my-2 text-2xl font-medium text-gray-500">Assign Teachers To Students</p>
        </div>

        <div className="flex w-full flex-row items-start justify-end p-3 text-gray-300">
          <Search onChange={handleSearchChange} />

          <NavLink to="/dashboard/assignteachers/new">
            <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
              <PlusCircleIcon className="h-6 w-6" />
              <span className="whitespace-no-wrap invisible absolute top-10 z-30 -mt-2 ml-2 rounded bg-black px-1 py-1 text-xs text-white group-hover:visible">
                Assign Teacher
              </span>
            </button>
          </NavLink>

          <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
            <DocumentDuplicateIcon className="h-6 w-6" />
            <span className="whitespace-no-wrap invisible absolute top-10 z-30 -mt-2 ml-2 rounded bg-black px-1 py-1 text-xs text-white group-hover:visible">
              Export Assignments
            </span>
          </button>

          <Filter
            resetFilters={resetFilters}
            children={
              <>
                <div className="flex flex-row justify-center">
                  <h2 className="mb-2 text-md font-semibold">Date Range Filter</h2>
                </div>
                <div className="mb-4">
                  <label htmlFor="fromDate">From :</label>
                  <input
                    type="date"
                    id="fromDate"
                    className="h-12 w-full rounded border border-teal-400 focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                    value={from}
                    onChange={(e) => setStartDateFilter(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="toDate">To :</label>
                  <input
                    type="date"
                    id="toDate"
                    className="h-12 w-full rounded border border-teal-400 focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                    value={to}
                    onChange={(e) => setEndDateFilter(e.target.value)}
                  />
                </div>
              </>
            }
          />
        </div>
      </div>

      {isLoading ? (
        <div className="flex flex-row items-center justify-center p-4">
          <CircularProgress size={24} sx={{ color: "black" }} /> <p>Please wait</p>
        </div>
      ) : error ? (
        <p className="text-red-500">Error loading assignments</p>
      ) : (
        <>
          <div className="overflow-x-scroll scrollbar-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Student Name
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Teacher Name(s)
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((assignment: any) => (
                  <tr key={assignment.id}>
                    <td className="capitalize border-b-1 text-gray-600 border-gray-200 px-2 py-1.5 text-left text-sm">
                      {assignment.firstName} {assignment.lastName} {assignment.email}
                    </td>
                    <td className="capitalize border-b-1 text-gray-600 border-gray-200 px-2 py-1.5 text-left text-sm">
                      {assignment.teachers.map((teacher: any) => (
                        <div key={teacher.id}>
                          {teacher.firstName} {teacher.lastName}
                        </div>
                      ))}
                    </td>
                    <td className="border-b-1 text-gray-600 border-gray-200 px-2 py-1.5 text-left text-sm flex">
                      {assignment.teachers.map((teacher: any) => (
                        <DeleteAssignment
                          key={teacher.id}
                          data={{
                            id: teacher.id, // Teacher ID
                            assignmentName: `${assignment.firstName} ${assignment.lastName}`, // Example assignment name
                            studentId: assignment.id // Assuming assignment.id is the student ID
                          }}
                          handleFilter={() => setSearchValue(search)}
                        />
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <label htmlFor="pageSize" className="mr-2">Entries per page:</label>
              <select
                id="pageSize"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="border border-gray-300 rounded-md p-1"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
            </div>
            <div className="flex">
              <button
                onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
                disabled={pageNumber === 1}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md"
              >
                Previous
              </button>
              <span className="mx-2">{pageNumber}</span>
              <button
                onClick={() => setPageNumber(prev => prev + 1)}
                disabled={paginatedData.length < pageSize}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md"
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
