import React, { useEffect, useState } from "react";
import { useAppSelector } from "@/core/lib";
import { useGetCategoriesQuery } from "../Api/redux";
import { storage } from "@/utils";
import PaginationButtons from "@/components/paginationbuttons/PaginationButtons";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import AddCategory from "./PostCategory";
import EditCategory from "./EditCategory";
import DeleteCategory from "./DeleteCategory";
import {
  DocumentDuplicateIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import Search from "@/components/Search";
import { CircularProgress } from "@mui/material";
import formatDateToLongForm from "@/utils/DateFormattter";
import { useTranslation } from "react-i18next";
import Select from "@/components/select/SelectField";
import Filter from "@/components/filters/Filter";

const GetCategories = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  return (
    <>
      <div className="flex w-full flex-row justify-end ">
        {" "}
        <div className="mb-4 flex w-1/4  flex-row items-center justify-center">
          <label htmlFor="languageSelect" className="mr-2  whitespace-nowrap">
            Select Language:
          </label>
          <Select
            id="languageSelect"
            className="borde-gray-400 rounded border px-2 py-1"
            onChange={(e) => setSelectedLanguage(e.target.value)}
            value={selectedLanguage}
          >
            <option value="en">English</option>
            <option value="fr">French</option>
            <option value="ki">Kinyarwanda</option>
          </Select>
        </div>
      </div>

      <div className=" bg-white  p-3 ">
        <Routes>
          <Route
            path="/"
            element={<AllCats selectedLanguage={selectedLanguage} />}
          ></Route>

          <Route path="/new" element={<AddCategory />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default GetCategories;

function AllCats({ selectedLanguage }: any) {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [from, setStartDateFilter] = useState("");
  const [to, setEndDateFilter] = useState("");
  const [search, setSearchValue] = useState("");

  const { t } = useTranslation();
  const resetFilters = () => {
    setStartDateFilter("");
    setEndDateFilter("");
  };

  const handleSearchChange = (searchValue: any) => {
    setSearchValue(searchValue);
  };
  const { isLoading, refetch, isFetching } = useGetCategoriesQuery(
    { pageNumber, pageSize, from, to, search },
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );

  const [sortedData, setSortedData] = useState<any>([]);
  const { categories } = useAppSelector((state) => state.categoryState);

  useEffect(() => {
    if (categories) {
      setSortedData(categories?.list);
    }
  }, [categories]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        await refetch();
      } catch (error) {
      } finally {
        setIsLoadingData(false);
      }
    };

    if (!isFetching) {
      setIsLoadingData(true);
      fetchData();
    }
  }, [pageNumber, pageSize, from, to, search, isFetching, refetch]);
  return (
    <div>
      <div className="flex flex-row justify-between ">
        <div className="flex flex-row justify-start">
          <p className="my-2 text-2xl  font-medium  text-gray-500"> categories</p>
        </div>

        <div className="flex w-full flex-row items-start justify-end p-3 text-gray-300">
          <Search onChange={handleSearchChange} />

          <Link to="/dashboard/categories/new">
            <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
              <PlusCircleIcon className="h-6 w-6" />
              <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
                Add New Category
              </span>
            </button>
          </Link>

          <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
            <DocumentDuplicateIcon className="h-6 w-6" />
            <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
              Export Category
            </span>
          </button>

          <Filter
          resetFilters={resetFilters}
              children={
                <>
                <div className="flex flex-row justify-center">
                <h2 className="mb-2 text-md font-semibold">
                    Date Range Filter
                  </h2>
                </div>
               
                  <div className="mb-4">
                    <label htmlFor="fromDate">From :</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="h-12 w-full rounded border border-teal-400   focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                      value={from}
                      onChange={(e) => setStartDateFilter(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="toDate" className="">
                      To 
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className=" h-12 w-full rounded border border-teal-400  focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40 "
                      value={to}
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </div>
                
                </>
              }
            />
        </div>
      </div>
      {isLoading  || isLoadingData ? (
        <div className="flex flex-row items-center  justify-center  p-4 ">
          <CircularProgress size={24} sx={{ color: "black" }} />{" "}
          <p>Please wait </p>
        </div>
      ) : (
        <>
          <div className=" scrollbar-hidden overflow-scroll">
            <table className="min-w-full table-auto leading-normal">
              <thead>
                <tr>
                  <th className="border-b-2 border-gray-200 bg-light px-3 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    No
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-3 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    id
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-3 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Age
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-3 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Level
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-3 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Description
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-3 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Icon
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-3 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Created At
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-3 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Questions
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-3 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="border-b border-gray-300">
                {sortedData.map((category: any, index: number) => (
                  <tr key={category.id}>
                    <td className="border-b-1 border-gray-200 px-3 py-2 text-left text-sm text-gray-600">
                      {index + 1}
                    </td>
                    <td className="border-b-1 border-gray-200 px-3 py-2 text-left text-sm text-gray-600">
                      {category.id}
                    </td>
                    <td className="border-b-1 whitespace-nowrap border-gray-200 px-3 py-2 text-left text-sm text-gray-600">
                      {category.rangeFrom} months to {category.rangeTo} months
                    </td>
                    <td className="border-b-1 whitespace-nowrap border-gray-200 px-3 py-2 text-left text-sm text-gray-600">
                      {category.lowLevel} to {category.highLevel}
                    </td>
                    <td className="border-b-1 whitespace-nowrap border-gray-200 px-3 py-2 text-left text-sm text-gray-600">
                      {category.description[selectedLanguage]}
                    </td>
                    <td className="border-b-1 border-gray-200 px-3 py-2 text-left text-sm text-gray-600">
                      {category.icon}
                    </td>
                    <td className="border-b-1 whitespace-nowrap border-gray-200 px-3 py-2  text-left text-sm text-gray-600">
                      {formatDateToLongForm(category.created_at)}
                    </td>
                    <td className="border-b-1 border-gray-200 px-3 py-2 text-left text-primary hover:underline">
                      <NavLink
                        to={`/dashboard/category/${category.id}/questions`}
                      >
                        Question
                      </NavLink>
                    </td>

                    <td className="   border-b-1 border-gray-200 px-3 py-2 text-left text-sm text-gray-600">
                      <div className="flex flex-row ">
                        <EditCategory data={category} />
                        <DeleteCategory data={category} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="my-4 flex flex-col items-center justify-between sm:flex-row">
            <div className="mb-2 flex flex-col items-center justify-center sm:mb-0 sm:flex-row">
              <label htmlFor="" className="text-md mb-2 text-gray-500 sm:mb-0">
                Results Per Page
              </label>
              <select
                className="mx-2 rounded border border-gray-300 bg-light text-sm font-normal text-dark outline-none placeholder:text-gray-500 focus:border-primary focus:outline-none focus:ring-0"
                onChange={(e) => setPageSize(e.target.value)}
              >
                <option value="10">10</option>
                <option value="5">5</option>
                <option value="10">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value={sortedData?.total}>All</option>
              </select>
            </div>
            <PaginationButtons
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
              totalPages={categories?.lastPage || 0}
            />
          </div>
        </>
      )}
    </div>
  );
}
