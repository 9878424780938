import React from "react";
import { Link } from "react-router-dom";

const Register = () => (
  <>
    <div className="flex flex-row items-center  justify-center">
      <p className="my-10 mt-3  text-3xl text-teal-500 ">Register</p>
    </div>
    <form>
      <div>
        <label
          htmlFor="email"
          className="mb-2 block text-sm text-gray-600 dark:text-gray-200"
        >
          Email Address
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          className="mt-2 block h-12 w-full rounded-lg border border-gray-200 bg-white px-4 py-2 text-gray-700 placeholder-gray-400 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-40 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 dark:placeholder-gray-600 dark:focus:border-blue-400"
        />
      </div>
      <div>
        <label
          htmlFor="email"
          className="mb-2 block text-sm text-gray-600 dark:text-gray-200"
        >
        First Name
        </label>
        <input
          type="text"
          name="email"
          id="email"
          placeholder="Ex. John"
          className="mt-2 block h-12 w-full rounded-lg border border-gray-200 bg-white px-4 py-2 text-gray-700 placeholder-gray-400 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-40 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 dark:placeholder-gray-600 dark:focus:border-blue-400"
        />
      </div>{" "}
      <div>
        <label
          htmlFor="email"
          className="mb-2 block text-sm text-gray-600 dark:text-gray-200"
        >
          Last Name
        </label>
        <input
          type="text"
          name="email"
          id="email"
          placeholder="Ex.Doe"
          className="mt-2 block h-12 w-full rounded-lg border border-gray-200 bg-white px-4 py-2 text-gray-700 placeholder-gray-400 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-40 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 dark:placeholder-gray-600 dark:focus:border-blue-400"
        />
      </div>{" "}
      <div>
        <label
          htmlFor="email"
          className="mb-2 block text-sm text-gray-600 dark:text-gray-200"
        >
         Phone Number
        </label>
        <input
          type="text"
          name="email"
          id="email"
          placeholder="0781120101"
          className="mt-2 block h-12 w-full rounded-lg border border-gray-200 bg-white px-4 py-2 text-gray-700 placeholder-gray-400 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-40 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 dark:placeholder-gray-600 dark:focus:border-blue-400"
        />
      </div>{" "}
      <div>
        <label
          htmlFor="email"
          className="mb-2 block text-sm text-gray-600 dark:text-gray-200"
        >
     Occupation
        </label>
        <input
          type="text"
          name="email"
          id="email"
          placeholder="Teacher"
          className="mt-2 block h-12 w-full rounded-lg border border-gray-200 bg-white px-4 py-2 text-gray-700 placeholder-gray-400 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-40 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 dark:placeholder-gray-600 dark:focus:border-blue-400"
        />
      </div>
      <div className="mt-6">
        <div className="mb-2 flex justify-between">
          <label
            htmlFor="password"
            className="text-sm text-gray-600 dark:text-gray-200"
          >
            Password
          </label>
        </div>

        <input
          type="password"
          name="password"
          id="password"
          placeholder="Your Password"
          className="mt-2 block h-12  w-full  rounded-lg border border-gray-200 bg-white px-4 py-2 text-gray-700 placeholder-gray-400 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-40 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 dark:placeholder-gray-600 dark:focus:border-blue-400"
        />
      </div>
      <div className="mt-6">
        <button className="h-12 w-full   transform  rounded-lg bg-teal-700 px-4 py-2 tracking-wide text-white transition-colors duration-300 hover:bg-teal-400 focus:bg-teal-400 focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-50">
          Register
        </button>
      </div>
    </form>
    <p className="mt-6 text-center text-sm text-gray-400">
      Already have an account yet?{" "}
      <Link
        to="/login"
        className="text-teal-500 hover:underline focus:underline focus:outline-none"
      >
        Login
      </Link>
      .
    </p>
  </>
);

export default Register;
