import { QuestionModel} from './model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	questions: QuestionModel | null;
}

const initialState: InitialState = {
	questions: null,
};
console.log(initialState)
export const questionsSlice = createSlice({
	initialState,
	name: 'category',
	reducers: {
		setQuestions: (state, action: PayloadAction<QuestionModel>) => {
			state.questions = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
