import React, { useRef, useState, ChangeEvent } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";
import { z } from "zod";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Form } from "@/components/form";
import { useUpdateUserMutation } from "@/app/auth/API";
import Select from "@/components/select/SelectField";

const schema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  username: z.string().min(1, "Username is required"),
  email: z.string().min(1, "Email is required").email(),
  phoneNumber: z.string().min(1, "Phone number is required"),
  occupation: z.string().min(1, "Occupation is required"),
  role: z.string().min(1, "Role is required"),
});

export type UserInput = z.TypeOf<typeof schema>;

export default function UpdateUser({ data }: any) {
  const dialog = useRef<HTMLDialogElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [updateUser, { isLoading: isUpdating, error }] = useUpdateUserMutation();

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };

  return (
    <>
      <dialog
        ref={dialog}
        className="dialog w-[40%] rounded-lg shadow-lg"
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="rounded p-3">
          <div className="flex flex-row items-center justify-center">
            <h3 className="mb-5 text-lg text-gray-600 font-bold">Edit User</h3>
          </div>
          <Form<UserInput, typeof schema>
            schema={schema}
            onSubmit={async (payload) => {
              try {
                await updateUser({ id: data.id, data: payload });
                closeModel();
              } catch (err) {
                console.error("Update failed:", err);
              }
            }}
          >
            {({ register, formState, setValue }: any) => (
              <>
                <div className="grid grid-cols-2 gap-2">
                  <InputField
                    type="text"
                    label="First Name"
                    defaultValue={data.firstName}
                    loading={isLoading || isUpdating}
                    error={formState.errors.firstName}
                    registration={register("firstName")}
                    className="h-13 mt-0"
                  />
                  <InputField
                    type="text"
                    label="Last Name"
                    defaultValue={data.lastName}
                    error={formState.errors.lastName}
                    registration={register("lastName")}
                    className="h-13 mt-0"
                  />
                  <InputField
                    type="text"
                    label="Username"
                    defaultValue={data.username}
                    error={formState.errors.username}
                    registration={register("username")}
                    className="h-13 mt-0"
                  />
                  <InputField
                    type="text"
                    label="Email"
                    defaultValue={data.email}
                    error={formState.errors.email}
                    registration={register("email")}
                    className="h-13 mt-0"
                  />
                  <InputField
                    type="text"
                    label="Phone Number"
                    defaultValue={data.phoneNumber}
                    loading={isLoading || isUpdating}
                    error={formState.errors.phoneNumber}
                    registration={register("phoneNumber")}
                    className="h-13 mt-0"
                  />
                  <InputField
                    type="text"
                    label="Occupation"
                    defaultValue={data.occupation}
                    loading={isLoading || isUpdating}
                    error={formState.errors.occupation}
                    registration={register("occupation")}
                    className="h-13 mt-0"
                  />
                  <Select
                    value={data.role}
                    options={[
                      { value: "admin", text: "Admin" },
                      { value: "user", text: "User" },
                      { value: "super_admin", text: "Super Admin" },
                      { value: "teacher", text: "Teacher" },
                      { value: "parent", text: "Parent" },
                    ]}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => setValue("role", e.target.value)}
                    className="h-13 mt-0"
                  />
                </div>
                <button
                  type="submit"
                  className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                  disabled={isLoading || isUpdating}
                >
                  {isLoading || isUpdating ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Update User"
                  )}
                </button>
                {error && <p className="text-red-500 mt-2">Update failed: </p>}
              </>
            )}
          </Form>
        </div>
      </dialog>
      <button onClick={() => openModel()} className="m-2 text-gray-600 hover:text-black">
        <PencilIcon className="h-5 w-5 " />
      </button>
    </>
  );
}
