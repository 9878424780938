import { createBrowserRouter, Navigate } from "react-router-dom";
import { Login } from "@/app/auth/login";
import Register from "@/app/auth/signup";
import AuthLayout from "@/components/layout/AuthLayout";
import { Dashlayout } from "./components/layout";
import  Home  from "./app/dashboard/dashbordSt";
import Categories from "@/app/dashboard/category/components/GetCategories";
import Questions from "@/app/dashboard/questions/components/GetQuestions";
import AllQuestions from "@/app/dashboard/questions/components/Questions";
import AllAnswers from "./app/dashboard/answers/components/Answers";
import Apoints from "./app/dashboard/appointment/components/GetAppoints";
import AllPatients from "./app/dashboard/patients/components/GetPatients";
import AllStudents from "./app/dashboard/students/components/GetStudents";
import AllParentsAssignments from "./app/dashboard/assignparents/components/GetAssignParents";
import AllTeachersAssignments from "./app/dashboard/assignteachers/components/GetAssignTeachers";
import Users from "./app/dashboard/users/GetUsers";
import{ Settings} from "./app/dashboard/settings/Settings";
import PatientAppointments from "./app/dashboard/appointment-Appointment/components/GetAppoints";
const Router = createBrowserRouter([
  { path: "/", element: <Navigate to="/login" replace /> },

  {
    element: <AuthLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
    ],
  },

  {
    element: <Dashlayout />,
    children: [
      { path: "dashboard", element: <Home /> },
      { path: "/dashboard/categories/*", element: <Categories /> },

      {
        path: "/dashboard/category/:categoryId/questions",
        element: <Questions />,
      },
      {
        path: "/dashboard/questions",
        element: <AllQuestions />,
      },
      {
        path: "/dashboard/answers",
        element: <AllAnswers />,
      },
      {
        path: "/dashboard/appoints/*",
        element: <Apoints />,
      },
      {
        path: "/dashboard/patients/*",
        element: <AllPatients />,
      },
      {
        path: "/dashboard/students/*",
        element: <AllStudents />,
      },

      {
        path: "/dashboard/assignparents/*",
        element: <AllParentsAssignments />,
      },

      {
        path: "/dashboard/assignteachers/*",
        element: <AllTeachersAssignments />,
      },
      {
        path: "/dashboard/users/*",
        element: <Users />,
      },
      {
        path: "/dashboard/patient-appointments/*",
        element: <PatientAppointments />,
      },
       {
        path: "/dashboard/settings/*",
        element: <Settings />,
      },
    ],
  },
]);
export default Router;
