import React, { useRef } from "react";
import { FunnelIcon } from "@heroicons/react/24/outline";

export default function Filter({ children, handleFilter, resetFilters }: any) {
  const dialog = useRef<HTMLDialogElement>(null);

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };

  return (
    <>
      <dialog
        ref={dialog}
        className="fixed rounded shadow-lg min-w-[25vw] left-[35vw] -top-[40vh]"
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="mt-2 flex flex-col justify-center text-gray-500">
          {children}
        </div>
        <div className="grid grid-cols-2 gap-3 ">
          <button
            className=" w-full rounded bg-primary py-2 text-white hover:bg-teal-700 mr-2"
            onClick={() => resetFilters()} // Call the resetFilters function here
          >
            Reset Filters
          </button>
          <button
            className=" w-full rounded bg-primary  py-2 text-white hover:bg-teal-700"
            onClick={() => closeModel()}
          >
            Cancel
          </button>
        </div>
      </dialog>
      <button
        className="group relative mx-1 rounded bg-primary p-2 capitalize"
        onClick={() => openModel()}
      >
        <FunnelIcon className="h-6 w-6" />
        <span className="whitespace-no-wrap invisible absolute top-0 z-30 -mt-2 ml-2 rounded bg-black px-1 py-1 text-xs text-white group-hover:visible">
          Filter
        </span>
      </button>
    </>
  );
}
