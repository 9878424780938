import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function PaginationButtons({ currentPage, setCurrentPage, totalPages }: any) {

    console.log(totalPages)
  const handleNextPage = () => {
    setCurrentPage((prev: any) => prev + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev: any) => prev - 1);
  };

  const handleCustomPage = (id: any) => {
    setCurrentPage(id);
  };

  const getPageNumbers = ({ pages }: any) => {
    const buttons = [];

    if (pages <= 5) {
      // If there are 5 or fewer pages, display all page buttons
      for (let i = 1; i <= pages; i++) {
        buttons.push(
          <button
            type="button"
            key={i}
            id={i.toString()}
            className={`m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 ${
              currentPage === i ? "bg-gradient-to-tr  from-teal-800 to-teal-600 text-white shadow-md shadow-pink-500/20" : ""
            }`}
            onClick={() => handleCustomPage(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      // If there are more than 5 pages, display a subset with dots
      const visiblePages = 5; // Number of visible page buttons

      if (currentPage <= visiblePages - 2) {
        // Display the first 'visiblePages - 1' pages
        for (let i = 1; i <= visiblePages - 1; i++) {
          buttons.push(
            <button
              type="button"
              key={i}
              id={i.toString()}
              className={`m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 ${
                currentPage === i ? "bg-gradient-to-tr from-teal-800 to-teal-600 text-white shadow-md shadow-pink-500/20" : ""
              }`}
              onClick={() => handleCustomPage(i)}
            >
              {i}
            </button>
          );
        }

        // Add dots
        buttons.push(
          <button
            key="dots"
            className="m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500"
            disabled={true}
          >
            ...
          </button>
        );

        // Add the last page
        buttons.push(
          <button
            type="button"
            key={pages}
            id={pages.toString()}
            className={`m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 ${
              currentPage === pages ? "bg-gradient-to-tr  from-teal-800 to-teal-600 text-white shadow-md shadow-pink-500/20" : ""
            }`}
            onClick={() => handleCustomPage(pages)}
          >
            {pages}
          </button>
        );
      } else if (currentPage >= pages - (visiblePages - 2)) {
        // Display the last 'visiblePages - 1' pages
        buttons.push(
          <button
            type="button"
            key="1"
            id="1"
            className={`m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 ${
              currentPage === 1 ? "bg-gradient-to-tr  from-teal-800 to-teal-600 text-white shadow-md shadow-pink-500/20" : ""
            }`}
            onClick={() => handleCustomPage(1)}
          >
            1
          </button>
        );

        // Add dots
        buttons.push(
          <button
            key="dots"
            className="m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500"
            disabled={true}
          >
            ...
          </button>
        );

        for (let i = pages - (visiblePages - 2); i <= pages; i++) {
          buttons.push(
            <button
              type="button"
              key={i}
              id={i.toString()}
              className={`m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 ${
                currentPage === i ? "bg-gradient-to-tr  from-teal-800 to-teal-600 text-white shadow-md shadow-pink-500/20" : ""
              }`}
              onClick={() => handleCustomPage(i)}
            >
              {i}
            </button>
          );
        }
      } else {
        // Display a subset of pages around the current page
        const leftDots = currentPage - 2 > 1;
        const rightDots = currentPage + 2 < pages;

        buttons.push(
          <button
            type="button"
            key="1"
            id="1"
            className={`m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 ${
              currentPage === 1 ? "bg-gradient-to-tr  from-teal-800 to-teal-600 text-white shadow-md shadow-pink-500/20" : ""
            }`}
            onClick={() => handleCustomPage(1)}
          >
            1
          </button>
        );

        if (leftDots) {
          // Add left dots
          buttons.push(
            <button
              key="left-dots"
              className="m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500"
              disabled={true}
            >
              ...
            </button>
          );
        }

        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          buttons.push(
            <button
              type="button"
              key={i}
              id={i.toString()}
              className={`m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 ${
                currentPage === i ? "bg-gradient-to-tr from-teal-800 to-teal-600 text-white shadow-md shadow-pink-500/20" : ""
              }`}
              onClick={() => handleCustomPage(i)}
            >
              {i}
            </button>
          );
        }

        if (rightDots) {
          // Add right dots
          buttons.push(
            <button
              key="right-dots"
              className="m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500"
              disabled={true}
            >
              ...
            </button>
          );
        }

        buttons.push(
          <button
            type="button"
            key={pages}
            id={pages.toString()}
            className={`m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 ${
              currentPage === pages ? "bg-gradient-to-tr  from-teal-800 to-teal-600 text-white shadow-md shadow-pink-500/20" : ""
            }`}
            onClick={() => handleCustomPage(pages)}
          >
            {pages}
          </button>
        );
      }
    }

    return buttons;
  };

  return (
    <div className="flex items-center">
      <button
        type="button"
        className={`m-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-200 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 ${
          currentPage <= 1 ? "cursor-not-allowed bg-gray-300 text-gray-400 opacity-50" : ""
        }`}
        onClick={handlePrevPage}
        disabled={currentPage <= 1}
        data-testid="prev-button"
      >
        <FaChevronLeft />
      </button>

      {getPageNumbers({ pages: totalPages })}

      <button
        type="button"
        className={`m-1 flex h-9 w-9 items-center justify-center rounded-full border border-prim,ary bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300 ${
          currentPage >= totalPages ? "cursor-not-allowed bg-gray-300 text-gray-400 opacity-50" : ""
        }`}
        onClick={handleNextPage}
        disabled={currentPage >= totalPages}
        data-testid="next-button"
      >
        <FaChevronRight />
      </button>
    </div>
  );
}

export default PaginationButtons;
