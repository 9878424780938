import React, { useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";
import { z } from "zod";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Form } from "@/components/form";
import { useUpdatePatientMutation } from "../API/redux";
import Select from "@/components/select/SelectField";

const schema = z.object({
  locationId: z.string().min(1, "Location ID is required"),
  email: z.string().min(1, "Email is required"),
  firstName: z.string().min(1, "First Name is required"),
  lastName: z.string().min(1, "Last Name is required"),
  dob: z
    .string()
    .min(1, "Date of Birth is required")
    .regex(/^\d{4}-\d{2}-\d{2}$/),

  placeOfBirth: z.string().min(1, "Place of Birth is required"),
  phone_1: z.string().min(1, "Phone 1 is required"),
  phone_2: z.string(),
  guardian: z.string().min(1, "Guardian is required"),
  fatherName: z.string().min(1, "Father's Name is required"),
  motherName: z.string().min(1, "Mother's Name is required"),
  gender: z.string().min(1, "Gender is required"),
});

export type PatientInput = z.TypeOf<typeof schema>;

export default function EditPatient({ data }: any) {
  const dialog = useRef<HTMLDialogElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(data.dob));

  const [UpdatePatient, { isLoading: isUpdating }] = useUpdatePatientMutation();

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };

  return (
    <>
      <dialog
        ref={dialog}
        className="dialog z-30 w-[60%] rounded-lg shadow-lg"
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="rounded p-3">
          <div className="flex flex-row items-center justify-center">
            <h3 className="p-2 text-lg text-gray-600">Edit Patient</h3>
          </div>
          <Form<PatientInput, typeof schema>
            schema={schema}
            onSubmit={(payload) => {
              
              UpdatePatient({ id: data.id, data: payload });
              closeModel()
            }}
          >
            {({ register, formState }) => (
              <div className="">
                <div className="grid grid-cols-3 gap-2">
                  <InputField
                    type="number"
                    label="Location ID"
                    placeholder={data.location.Id}
                    defaultValue={data.location.id}
                    loading={isLoading}
                    error={formState.errors.locationId}
                    registration={register("locationId")}
                    className="h-13"
                  />
                  <InputField
                    type="text"
                    label="Email"
                    placeholder="Email"
                    defaultValue={data.email}
                    loading={isLoading}
                    error={formState.errors.email}
                    registration={register("email")}
                    className="h-13"
                  />
                  <InputField
                    type="text"
                    label="First Name"
                    placeholder="First Name"
                    loading={isLoading}
                    defaultValue={data.firstName}
                    error={formState.errors.firstName}
                    registration={register("firstName")}
                    className="h-13"
                  />
                  <InputField
                    type="text"
                    label="Last Name"
                    placeholder="Last Name"
                    loading={isLoading}
                    defaultValue={data.lastName}
                    error={formState.errors.lastName}
                    registration={register("lastName")}
                    className="h-13"
                  />
                  <InputField
                    type="date"
                    label="Date of birth"
                    placeholder="Date of Birth"
                    loading={isLoading}
                    defaultValue={selectedDate.toISOString().split("T")[0]}
                    onChange={(e:any) => setSelectedDate(new Date(e.target.dob))}
                    error={formState.errors.dob}
                    registration={register("dob")}
                    className="h-13"
                  />
             
                  <InputField
                    type="text"
                    label="Place of Birth"
                    defaultValue={data.placeOfBirth}
                    placeholder="Place of Birth"
                    loading={isLoading}
                    error={formState.errors.placeOfBirth}
                    registration={register("placeOfBirth")}
                    className="h-13"
                  />
                  <InputField
                    type="text"
                    label="Phone 1"
                    placeholder="Phone 1"
                    defaultValue={data.phone_1}
                    loading={isLoading}
                    error={formState.errors.phone_1}
                    registration={register("phone_1")}
                    className="h-13"
                  />
                  <InputField
                    type="text"
                    label="Phone 2"
                    placeholder="Phone 2"
                    loading={isLoading}
                    error={formState.errors.phone_2}
                    defaultValue={data.phone_2}
                    registration={register("phone_2")}
                    className="h-13"
                  />
                  <InputField
                    type="text"
                    label="Guardian"
                    placeholder="Guardian"
                    loading={isLoading}
                    defaultValue={data.guardian}
                    error={formState.errors.guardian}
                    registration={register("guardian")}
                    className="h-13"
                  />
                  <InputField
                    type="text"
                    label="Father's Name"
                    placeholder="Father's Name"
                    loading={isLoading}
                    defaultValue={data.fatherName}
                    error={formState.errors.fatherName}
                    registration={register("fatherName")}
                    className="h-13"
                  />
                  <InputField
                    type="text"
                    label="Mother's Name"
                    placeholder="Mother's Name"
                    loading={isLoading}
                    defaultValue={data.motherName}
                    error={formState.errors.motherName}
                    registration={register("motherName")}
                    className="h-13"
                  />
                  <div className="flex flex-col  ">
                    <label
                      htmlFor=""
                      className="mb-2 block text-sm text-gray-600 dark:text-gray-400"
                    >
                      {" "}
                      Gender
                    </label>

                    <Select
                      name="gender"
                      className=" h-12 rounded  border border-gray-400 text-gray-500 "
                      registration={register("gender")}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Select>
                    {formState.errors.gender && (
                      <span className="font-italics mt-2 block text-sm font-medium capitalize text-danger">
                        {formState.errors.gender.message}{" "}
                        {/* Access the error message */}
                      </span>
                    )}
                  </div>
                </div>
                {isLoading ? (
                  <button
                    type="submit"
                    className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                    disabled
                  >
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                  >
                    Edit  Patient
                  </button>
                )}
              </div>
            )}
          </Form>
        </div>
      </dialog>
      <button onClick={() => openModel()} className="m-2 text-gray-600 hover:text-black   ">
        <PencilIcon className="h-5 w-5 " />
      </button>
    </>
  );
}
