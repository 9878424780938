import React, { useState } from 'react';
import { CircularProgress, MenuItem, Select, SelectChangeEvent, Button } from '@mui/material';
import { useGetStudentssQuery, useGetParentsQuery, useAssignParentsMutation } from '../API/redux';

// Define the type for Student
interface Student {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

// Define the type for Parent
interface Parent {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

// Define the response type
interface UserResponse {
  
}

// Define a custom error type
interface CustomError {
  data?: {
    message?: string;
  };
}

// Type guard to check if the error is a CustomError
const isCustomError = (error: unknown): error is CustomError => {
  return (error as CustomError).data !== undefined;
};

// Utility function to get error message
const getErrorMessage = (error: unknown): string => {
  if (isCustomError(error)) {
    return error.data?.message || 'An unexpected error occurred';
  }
  return 'An unexpected error occurred';
};

const AssignmentsComponent = () => {
  const { data: studentsData, isLoading: studentsLoading, error: studentsError } = useGetStudentssQuery();
  const { data: parentsData, isLoading: parentsLoading, error: parentsError } = useGetParentsQuery();
  const [assignParents] = useAssignParentsMutation();

  const [selectedStudent, setSelectedStudent] = useState<string | null>(null);
  const [selectedParent, setSelectedParent] = useState<string | null>(null);

  // Loading states
  if (studentsLoading || parentsLoading) return <div>Loading...</div>;

  // Error handling
  const studentsErrorMessage = studentsError ? getErrorMessage(studentsError) : '';
  const parentsErrorMessage = parentsError ? getErrorMessage(parentsError) : '';

  const handleStudentChange = (event: SelectChangeEvent<string>) => {
    setSelectedStudent(event.target.value);
  };

  const handleParentChange = (event: SelectChangeEvent<string>) => {
    setSelectedParent(event.target.value);
  };

  const handleAssign = async () => {
    if (selectedStudent && selectedParent) {
      try {
        await assignParents({
          studentId: parseInt(selectedStudent, 10),
          parentId: parseInt(selectedParent, 10),
        }).unwrap();
        // Success handling here
      } catch (error) {
        console.error('Failed to assign parents:', getErrorMessage(error));
      }
    } else {
      console.error("Both student and parent must be selected");
    }
  };

  return (
    <div>
      {studentsError && <div>Error fetching students: {studentsErrorMessage}</div>}
      {parentsError && <div>Error fetching parents: {parentsErrorMessage}</div>}

      {!studentsLoading && studentsData && (
        <div>
          <h2>Assign Parents</h2>

          <div className="flex flex-col mb-4">
            <label htmlFor="student" className="mb-2 block text-sm text-gray-600">Student</label>
            <Select
              value={selectedStudent ?? ''}
              onChange={handleStudentChange}
              className="h-12 rounded border border-gray-400"
              displayEmpty
            >
              {studentsLoading ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                </MenuItem>
              ) : studentsData.length > 0 ? (
                studentsData.map(student => (
                  <MenuItem key={student.id} value={student.id.toString()}>
                    {student.firstName} {student.lastName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No students available</MenuItem>
              )}
            </Select>
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="parent" className="mb-2 block text-sm text-gray-600">Parent</label>
            <Select
              value={selectedParent ?? ''}
              onChange={handleParentChange}
              className="h-12 rounded border border-gray-400"
              displayEmpty
            >
              {parentsLoading ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                </MenuItem>
              ) : parentsData?.list && parentsData.list.length > 0 ? (
                parentsData.list.map(parent => (
                  <MenuItem key={parent.id} value={parent.id.toString()}>
                    {parent.firstName} {parent.lastName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No parents available</MenuItem>
              )}
            </Select>
          </div>

          <Button
            onClick={handleAssign}
            variant="contained"
            color="primary"
            disabled={studentsLoading || parentsLoading}
          >
            Assign Parents
          </Button>
        </div>
      )}
    </div>
  );
};

export default AssignmentsComponent;
