import React, { useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";
import { z } from "zod";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Form } from "@/components/form";
import { useUpdateAnswerMutation } from "../Api/redux";

const schema = z.object({
  title: z.object({
    en: z.string().min(1, "English title is required"),
    fr: z.string().min(1, "French title is required"),
    ki: z.string().min(1, "Kinyarwanda title is required"),
  }),
  correct: z.string().min(1, "required, either true or false"),
  questionId: z.string().min(1, "Question Id is required"),
});

export type AnswerInput = z.TypeOf<typeof schema>;

export default function EditAnswer({ data }: any) {
  const dialog = useRef<HTMLDialogElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [OnPost, { isLoading: isUpdating }] = useUpdateAnswerMutation();

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };

  return (
    <>
      <dialog
        ref={dialog}
        className="dialog w-[40%] rounded-lg shadow-lg"
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="rounded p-3">
          <div className="flex flex-row items-center justify-center">
            <h3 className="pb-2 text-lg text-gray-600">Update Answer</h3>
          </div>
          <Form<AnswerInput, typeof schema>
            schema={schema}
            onSubmit={(payload) => OnPost({ id: data.id, data: payload })}
          >
            {({ register, formState }: any) => (
              <>
                <div className=" grid grid-cols-2 mt-2   gap-2">
                  <InputField
                    type="text"
                    label="Correct"
                    placeholder="Correct .ex.true "
                    defaultValue={data?.correct}
                    loading={isLoading}
                    error={formState.errors.correct}
                    registration={register("correct")}
                    className="h-13"
                  />
                  <InputField
                    type="text"
                    label="English title"
                    placeholder="English title"
                    defaultValue={data?.title.en}
                    loading={isLoading}
                    error={formState.errors?.title?.fr}
                    registration={register("title.en")}
                    className="h-13 mt-0"
                  />
                  <InputField
                    type="text"
                    label="French title"
                    placeholder="French title"
                    defaultValue={data?.title.fr}
                    loading={isLoading}
                    error={formState.errors.title?.fr}
                    registration={register("title.fr")}
                    className="h-13 mt-0"
                  />

                  <InputField
                    type="text"
                    label="Kinyarwanda title"
                    placeholder="Kinyarwanda title"
                    defaultValue={data?.title.ki}
                    loading={isLoading}
                    error={formState.errors.title?.ki}
                    registration={register("title.ki")}
                    className="h-13 mt-0"
                  />

                  <InputField
                    type="text"
                    label="Question Id"
                    placeholder="Question Id ex.20"
                    defaultValue={data?.question.id}
                    loading={isLoading}
                    error={formState.errors.questionId}
                    registration={register("questionId")}
                    className="h-13"
                  />
                </div>
                <button
                  type="submit"
                  className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                  disabled={isLoading || isUpdating}
                >
                  {isLoading || isUpdating ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Edit Answer"
                  )}
                </button>
              </>
            )}
          </Form>
        </div>
      </dialog>
      <button onClick={() => openModel()} className="m-2 text-gray-600 hover:text-black   ">
        <PencilIcon className="h-5 w-5 " />
      </button>
    </>
  );
}
