/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";
import { z } from "zod";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Form } from "@/components/form";
import { format } from "date-fns";
import Select from "@/components/select/SelectField";
import { useUpdatePatientAppointmentMutation } from "../redux";
import { useGetAvailableDatesQuery } from "../../appointment/redux";
import { storage } from "@/utils";

const schema = z.object({
  Reason: z.string().min(1, " Reason is required"),

  patientId: z.string().min(1, "patient ID is required"),


});

export type AppointmentInput = z.TypeOf<typeof schema>;
export default function EditAppoint({ data }: any) {
  const dialog = useRef<HTMLDialogElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDateId, setSelectedDateId] = useState<string>("");

  const handleDateSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDateId(event.target.value);
  };
  const { dates }: any = useGetAvailableDatesQuery(
    {},
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );
  console.log(data);

  if (!data) {
    return <div> loading</div>;
  }
  const [UpdateAppoint, { isLoading: isUpdating }] =
    useUpdatePatientAppointmentMutation();

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };

  return (
    <>
      <dialog
        ref={dialog}
        className="dialog w-[50%] rounded-lg shadow-lg"
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="rounded p-3">
          <div className="flex flex-row items-center justify-center">
            <h3 className="p-2 text-lg text-gray-600">Update Appointment</h3>
          </div>
          <Form<AppointmentInput, typeof schema>
            schema={schema}
            onSubmit={(payload) => {
              UpdateAppoint({ id: data.id, data: payload });
            }}
          >
            {({ register, formState }: any) => (
              <>
                <div className=" grid grid-cols-2  gap-2">
                  <InputField
                    type="text"
                    label="Patient ID"
                    defaultValue={data?.location?.id || "-"}
                    placeholder="patient ID"
                    loading={isLoading}
                    error={formState.errors.locationId}
                    registration={register("locationId")}
                    className="h-13"
                  />

                  <InputField
                    type="text"
                    label="reason"
                    placeholder="reason"
                    defaultValue={data.reason}
                    loading={isLoading}
                    error={formState.errors.reason}
                    registration={register("reason")}
                    className="h-13"
                  />
{/* 
                  <div className="flex flex-col  ">
                    <label
                      htmlFor="availableDateId"
                      className="mb-2 block text-sm text-gray-600 dark:text-gray-400"
                    >
                      Available Date
                    </label>

                    <Select
                      name="availableDateId"
                      id="availableDateId"
                      className="h-12 rounded border border-gray-400 bg-white text-gray-500 "
                      registration={register("availableDateId")}
                      value={selectedDateId}
                      onChange={handleDateSelect}
                    >
                      <option value="" className="text-gray-500" disabled>
                        Select an available date
                      </option>
                      {dates &&
                        Object.entries(dates).map(
                          ([date, slots]: [string, any]) =>
                            slots.map((slot: any) => (
                              <option key={slot.id} value={slot.id}>
                                {`${format(
                                  new Date(slot.date),
                                  "yyyy-MM-dd hh:mm a"
                                )}`}
                              </option>
                            ))
                        )}
                    </Select>

                    {formState.errors.availableDateId && (
                      <span className="font-italics mt-2 block text-sm font-medium capitalize text-danger">
                        {formState.errors.availableDateId.message}
                      </span>
                    )}
                  </div> */}
                </div>
                <button
                  type="submit"
                  className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                  disabled={isLoading || isUpdating}
                >
                  {isLoading || isUpdating ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Update Appointment"
                  )}
                </button>
              </>
            )}
          </Form>
        </div>
      </dialog>
      <button
        onClick={() => openModel()}
        className="m-2 text-gray-600 hover:text-black   "
      >
        <PencilIcon className="h-5 w-5 " />
      </button>
    </>
  );
}
