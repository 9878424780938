import React, { useState } from "react";
import {
  useAddAvailableDateMutation,
  useGetAvailableDatesQuery,
} from "../redux";
import { storage } from "@/utils";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formatDateToLongForm from "@/utils/DateFormattter";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";

function NewDateModal({ isOpen, onClose, onAddDates }: any) {
  const [newDate, setNewDate] = useState<Date | null>(null);
  const [newTime, setNewTime] = useState<Date | null>(null);

  const [OnPost, { isSuccess, isLoading }] = useAddAvailableDateMutation();

  const handleAddDates = () => {
    if (newDate && newTime) {
      const combinedDateTime = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        newTime.getHours(),
        newTime.getMinutes(),
        newTime.getSeconds(),
        newTime.getMilliseconds()
      );

      const dateTimeString = combinedDateTime.toISOString();
      const dataToSend = {
        dates: [dateTimeString],
      };

      console.log(dataToSend);
      OnPost(dataToSend)
        .then(() => {
          onAddDates(dateTimeString);
          setNewDate(null);
          setNewTime(null);
          onClose();
        })
        .catch((error: any) => {
          toast.error(error.message);
        });
    }
  };

  const handleModalClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      } bg-black bg-opacity-50`}
      onClick={onClose}
    >
      <div
        className="w-80 rounded bg-white p-4 shadow-lg"
        onClick={handleModalClick}
      >
        <h2 className="text-LG mb-4 font-semibold">Add Available DAates</h2>
        <div className="mb-4">
          <DatePicker
            selected={newDate}
            onChange={(date: Date) => setNewDate(date)}
            dateFormat="yyyy-MM-dd"
            className=" w-full  rounded border  border-gray-400  focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
            placeholderText="Select Date"
          />
        </div>
        <div className="mb-4">
          <DatePicker
            selected={newTime}
            onChange={(date: Date) => setNewTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            className=" w-full  rounded border  border-gray-400  focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40 "
            dateFormat="HH:mm"
            placeholderText="Select Time"
          />
        </div>
        <button
          onClick={handleAddDates}
          className="rounded bg-teal-500 px-4 py-2 text-white focus:outline-none"
        >
          {isLoading ? (
            <span>
              <CircularProgress size={24} sx={{ color: "white" }} />
            </span>
          ) : (
            <span> Add Date </span>
          )}
        </button>
        <button
          onClick={onClose}
          className="mx-2 mt-2 rounded  bg-gray-300 px-4 py-2 text-gray-700 focus:outline-none"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

function GetAvailableDates() {
  const { data }: any = useGetAvailableDatesQuery(
    {},
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );

  const [copiedSlotId, setCopiedSlotId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newAvailableDates, setNewAvailableDates] = useState<string[]>([]);

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    setCopiedSlotId(text);
    setTimeout(() => setCopiedSlotId(null), 2000);
  }

  const handleAddDates = (dateTime: string) => {
    setNewAvailableDates([...newAvailableDates, dateTime]);
  };

  if (!data) {
    return (
      <div className="p-4">
        <h2 className="mb-4 text-2xl font-semibold text-gray-700">
          Loading...
        </h2>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex flex-row justify-between">
        <h2 className="mb-4 text-2xl font-semibold text-gray-700">
          Available Dates
        </h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="mb-4 rounded bg-teal-500 px-4  py-2 text-white hover:bg-teal-600 focus:outline-none"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add New Date
        </button>
      </div>

      {Object.entries(data).map(([date, slots]: [string, any]) => (
        <div key={date} className="mt-6 ">
          <h3 className=" text-lg font-medium text-gray-700">
            {formatDateToLongForm(date)}
          </h3>
          <ul className="grid grid-cols-1 gap-4 md:grid-cols-3 ">
            {slots.map((slot: any) => (
              <li key={slot.id} className=" rounded border bg-gray-100 p-4">
                <div className="flex flex-row justify-between  space-y-2">
                  <div className="flex flex-col ">
                    <div>
                      <span
                        className={`${
                          slot.status === "available"
                            ? "bg-green-500 text-white"
                            : "bg-red-500 text-white"
                        } mr-2 rounded px-2 py-1`}
                      >
                        {format(new Date(slot.date), "hh:mm a")}
                      </span>
                    </div>
                    <div>
                      <span
                        className={`flex-grow cursor-pointer ${
                          copiedSlotId ? "opacity-50" : ""
                        }`}
                      >
                        Status: {slot.status}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => copyToClipboard(slot.id)}
                      className={`${
                        copiedSlotId === slot.id
                          ? "bg-green-500 text-white"
                          : "bg-teal-500 text-white hover:bg-teal-600"
                      } rounded-full px-4 py-2 focus:outline-none`}
                      disabled={copiedSlotId === slot.id}
                    >
                      {copiedSlotId === slot.id ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        <FontAwesomeIcon icon={faCopy} />
                      )}
                    </button>
                    {copiedSlotId === slot.id && (
                      <span className="text-sx text-green-500"> Id Copied</span>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <NewDateModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddDates={handleAddDates}
      />
    </div>
  );
}

export default GetAvailableDates;
