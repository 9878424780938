import React, { useEffect, useState } from "react";
import { useAppSelector } from "@/core/lib";
import { useGetAnswersQuery } from "../Api/redux";
import { storage } from "@/utils";
import PaginationButtons from "@/components/paginationbuttons/PaginationButtons";
import formatDateToLongForm from "@/utils/DateFormattter";
import AddAnswer from "./AddAnswer";
import EditAnswer from "./UpdateAnswer";
import DeleteAnswer from "./DeleteAnswer";
import { DocumentDuplicateIcon, FunnelIcon } from "@heroicons/react/24/outline";
import Search from "@/components/Search";
import Filter from "@/components/filters/Filter";


const AllAnswers = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [status, SetStatusFilter] = useState("");
  const [from, setStartDateFilter] = useState("");
  const [to, setEndDateFilter] = useState("");
  const [search, setSearchValue] = useState("");

  const handleSearchChange = (searchValue:any) => {
    setSearchValue(searchValue)

  };
  const resetFilters = () => {
    SetStatusFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
  };

  const { isLoading, refetch, isFetching } = useGetAnswersQuery(
    { pageNumber, pageSize, status, from, to,search },
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );

  const [sortedData, setSortedData] = useState<any>([]);
  const { answers } = useAppSelector((state: any) => state.answerState);

  useEffect(() => {
    if (answers) {
      setSortedData(answers?.list);
    }
  }, [answers]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        await refetch();
      } catch (error) {
      } finally {
        setIsLoadingData(false);
      }
    };

    if (!isFetching) {
      setIsLoadingData(true);
      fetchData();
    }
  }, [pageNumber, pageSize, from, to, search,status, isFetching, refetch]);

  return (
    <div className="px-4 ">
      <div className="mb-4 flex flex-row justify-end">
        <label htmlFor="languageSelect" className="mr-2 ">
          Select Language:
        </label>
        <select
          id="languageSelect"
          className="rounded border p-1"
          onChange={(e) => setSelectedLanguage(e.target.value)}
          value={selectedLanguage}
        >
          <option value="en">English</option>
          <option value="fr">French</option>
          <option value="ki">Kinyarwanda</option>
        </select>
      </div>
      <div className=" bg-white  p-3 ">
      <div className="flex flex-row justify-between ">
        <div className="flex flex-row justify-start">
          <p className="my-2 text-2xl  font-medium  text-gray-500">
            Answers
          </p>
        </div>

        <div className="flex w-full  flex-row items-start justify-end text-gray-300    ">
        <Search onChange={handleSearchChange} />

        <AddAnswer/>
        <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
            <DocumentDuplicateIcon className="h-6 w-6" />
            <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
              Export Answers
            </span>
          </button>

            <Filter
          resetFilters={resetFilters}
              children={
                <>
                <div className="flex flex-row justify-center">
                <h2 className="mb-2 text-md font-semibold">
                    Date Range Filter
                  </h2>
                </div>
               
                  <div className="mb-4">
                    <label htmlFor="fromDate">From :</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="h-12 w-full rounded border border-teal-400   focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                      value={from}
                      onChange={(e) => setStartDateFilter(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="toDate" className="">
                      To 
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className=" h-12 w-full rounded border border-teal-400  focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40 "
                      value={to}
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </div>
                
                </>
              }
            />
        </div>
        </div>
        <table className="min-w-full table-auto leading-normal">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-200 bg-light  px-2 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                id
              </th>

              <th className="border-b-2 border-gray-200 bg-light  px-2 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                category
              </th>
            
              <th className="border-b-2 border-gray-200 bg-light  px-2 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                question
              </th>

              <th className="border-b-2 border-gray-200 bg-light  px-2 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                correct Answer 
              </th>
              <th className="border-b-2 border-gray-200 bg-light  px-2 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                Mark
              </th>
             
              <th className="border-b-2 border-gray-200 bg-light  px-2 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                createdAt
              </th>
              <th className="border-b-2 border-gray-200 bg-light  px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="border-b border-gray-400">
            {sortedData.map((question: any, index: any) => (
              <tr key={question.id}>
                <td className='border-b-1 text-gray-600" border-gray-200 px-2 py-3 text-left text-sm '>
                  {question.id}
                </td>

                <td className="border-b-1 border-gray-200  whitespace-nowrap  px-2 py-3 text-left text-sm   text-gray-600 ">
                  {question?.question?.category?.description[selectedLanguage]}
                </td>
                


                <td className="border-b-1 whitespace-wrap  border-gray-200 flex  px-2 py-3  text-left text-sm capitalize   text-gray-600 ">
                {question.question.title[selectedLanguage]}
                </td>

                <td className="border-b-1 border-gray-200   px-2 py-3 text-center  text-sm  text-gray-600 ">
                {question.title[selectedLanguage]}
                </td>
                <td className="border-b-1 border-gray-200  px-2 py-3 text-center text-sm  text-gray-600 ">
                {question.question.marks}
                </td>
                <td className="border-b-1 whitespace-nowrap border-gray-200 px-2 py-3 text-left text-sm  text-gray-600 ">
                  {formatDateToLongForm(question.created_at)}
                </td> <td className="border-b-1 border-gray-200  px-4 py-3 text-center text-sm  text-gray-600 ">
           <div className="flex flex-row ">
            <EditAnswer data={question}/>
            <DeleteAnswer  data={question} />
           </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {sortedData?.list && sortedData?.list.length === 0 ? (
          <div> </div>
        ) : (
          <div className="my-4 flex flex-col items-center justify-between sm:flex-row">
            <div className="mb-2 flex flex-col items-center justify-center sm:mb-0 sm:flex-row">
              <label htmlFor="" className="text-md mb-2 text-gray-500 sm:mb-0">
                Results Per Page
              </label>
              <select
                className="mx-2 rounded border border-gray-300 bg-light text-sm font-normal text-dark outline-none placeholder:text-gray-500 focus:border-primary focus:outline-none focus:ring-0"
                onChange={(e) => setPageSize(e.target.value)}
              >
                <option value="10">10</option>
                <option value="5">5</option>
                <option value="10">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value={answers?.total}>All</option>
              </select>
            </div>
            <PaginationButtons
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
              totalPages={answers?.lastPage || 0}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AllAnswers;
