import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentModel } from './model';

interface InitialState {
  students: StudentModel[] | null;  // Handle an array of students
}

const initialState: InitialState = {
  students: null,
};

export const studentSlice = createSlice({
  initialState,
  name: 'student',
  reducers: {
    setStudent: (state, action: PayloadAction<StudentModel[]>) => {
      state.students = action.payload;  // Handle array of students
    },
    logout: () => {
      return initialState;
    },
  },
});

export const { setStudent, logout } = studentSlice.actions;
export default studentSlice.reducer;
