import { RouterProvider } from "react-router-dom";

import Router from "@/router";
import { Provider } from "react-redux";
import { Toaster } from 'react-hot-toast'

import { store } from "./core/lib/store";

const App = () => {
  return (
    <Provider store={store}>
      <RouterProvider router={Router} />
	  <Toaster position='top-center' reverseOrder={false} />
    </Provider>
  );
};

export default App;
