import { PatientModel} from './model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	patients: PatientModel | null;
}

const initialState: InitialState = {
	patients: null,
};
console.log(initialState)
export const patientSlice = createSlice({
	initialState,
	name: 'patient',
	reducers: {
		setPatient: (state, action: PayloadAction<PatientModel>) => {
			state.patients = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
