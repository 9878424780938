import React, { useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";
import { z } from "zod";
import { Form } from "@/components/form";
import Select from "@/components/select/SelectField";
import { storage } from "@/utils";
import { useAddPatientAppointmentMutation } from "../redux";
import { useGetAvailableDatesQuery } from "../../appointment/redux";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";

const schema = z.object({
  patientId: z.string().min(1, "Patient  ID is required"),
  reason: z.string().min(1, "Reason is required"),
  availableDateId: z.string().min(1, "Available Date ID is required"),
});

export type AppointmentInput = z.TypeOf<typeof schema>;

export default function AddAppoint() {
  const dialog = useRef<HTMLDialogElement>(null);
  const [selectedDateId, setSelectedDateId] = useState<string>("");

  const handleDateSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDateId(event.target.value);
  };
  
  
  const [OnPost, { isLoading }] = useAddPatientAppointmentMutation();

  const handleSubmit = (payload: AppointmentInput) => {
    payload.availableDateId = selectedDateId;
    OnPost(payload)
      .then(() => {})
      .catch((error: any) => {});
  };

  const { data }: any = useGetAvailableDatesQuery(
    {},
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );
  console.log(data);

  if (!data) {
    return <div> loading</div>;
  }

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };

  return (
    <>
      <dialog
        ref={dialog}
        className="dialog w-[50%] rounded-lg shadow-lg"
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="rounded p-3">
          <div className="flex flex-row items-center justify-center">
            <h3 className="p-2 text-lg text-gray-600">Add  Appointment</h3>
          </div>
          <Form<AppointmentInput, typeof schema>
            schema={schema}
            onSubmit={handleSubmit}
          >
            {({ register, formState }: any) => (
              <>
                <div className=" grid grid-cols-2  gap-2">
                  <InputField
                    type="text"
                    label="Patient  ID"
                    placeholder="Patient ID"
                    loading={isLoading}
                    error={formState.errors.patientId}
                    registration={register("patientId")}
                    className="h-13"
                  />

                  <InputField
                    type="text"
                    label="Reason"
                    placeholder="Reason for Appointment"
                    loading={isLoading}
                    error={formState.errors.reason}
                    registration={register("reason")}
                    className="h-13"
                  />
               <div className="flex flex-col  ">
                <label
                  htmlFor="availableDateId"
                  className="mb-2 block text-sm text-gray-600 dark:text-gray-400"
                >
                  Available Date
                </label>

                <Select
                  name="availableDateId"
                  id="availableDateId"
                  className="h-12 rounded border border-gray-400 text-gray-500 bg-white "
                  registration={register("availableDateId")}
                  value={selectedDateId}
                  onChange={handleDateSelect}
                >
                  <option value="" className="text-gray-500" disabled>
                    Select an available date
                  </option >
                  {data &&
                    Object.entries(data).map(([date, slots]: [string, any]) =>
                      slots.map((slot: any) => (
                        <option key={slot.id} value={slot.id}>
                          {`${format(
                            new Date(slot.date),
                            "yyyy-MM-dd hh:mm a"
                          )}`}
                        </option>
                      ))
                    )}
                </Select>

                {formState.errors.availableDateId && (
                  <span className="font-italics mt-2 block text-sm font-medium capitalize text-danger">
                    {formState.errors.availableDateId.message}
                  </span>
                )}
              </div>
            
                </div>
                <button
                  type="submit"
                  className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Add Appointment"
                  )}
                </button>
              </>
            )}
          </Form>
        </div>
      </dialog>

      <button
        className="group relative mx-1 rounded bg-primary p-2 capitalize"
        onClick={() => openModel()}
      >
        <PlusCircleIcon className="h-6 w-6" />
        <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
          Request Appointment
        </span>
      </button>
    </>
  );
}
