import Barcharts from "@/components/charts/Barcharts";
import BarChart from "@/components/charts/Barcharts";
import LineChart from "@/components/charts/Linechart";
import PieChart from "@/components/charts/Piechart";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { FaUser, FaCalendar, FaQuestionCircle, FaComments, FaList, FaUsers } from "react-icons/fa";

// Import your chart components (BarChart and PieChart) here

const DashCard = ({ title, value, icon, backgroundColor, iconColor }: any) => {
  const iconStyles = {
    backgroundColor: backgroundColor,
    color: iconColor,
  };

  return (
    <div className="relative p-6 rounded-lg shadow-md flex flex-col justify-between bg-white">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-gray-600 text-md">{title}</div>
          <div className="text-3xl font-semibold text-gray-800 mt-2">{value}</div>
        </div>
        <div className="">
          <div
            className="absolute -top-2 text-[40px] p-5 shadow rounded right-1"
            style={iconStyles}
          >
            {icon}
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
 
  const barChartData = {
    series: [
      {
        name: "Patients",
        data: [120, 180, 230, 320, 280, 390, 290,300,360,220,320,200],
      },
      {
        name: "Appointments",
        data: [80, 120, 190, 260, 220, 310, 210,340,310,200,140,150],
      },
    ],
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug","Sept","Oct","Nov","Dec"],
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
  };

  const pieChartData = {
    series: [44, 55, 13, 43, 22],
    chart: {
      height: 350,
      type: "pie",
    },
    labels: ["General", "Dental", "Orthopedic", "Neurology", "Cardiology"],
  };
  return (
    <div className=" container mx-auto max-w-7xl mt-8">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <DashCard
          title="Users"
          value={120}
          icon={<FaUsers />}
          backgroundColor="#FF9800"
          iconColor="#FFFFFF"
        />
        <DashCard
          title="Patients"
          value={20}
          icon={<FaUser />}
          backgroundColor="#00BFA5"
          iconColor="#FFFFFF"
        />
        <DashCard
          title="Appointments"
          value={34}
          icon={<FaCalendar />}
          backgroundColor="#2196F3"
          iconColor="#FFFFFF"
        />
        <DashCard
          title="Categories"
          value={8}
          icon={<FaList />}
          backgroundColor="#9C27B0"
          iconColor="#FFFFFF"
        />
        <DashCard
          title="Questions"
          value={345}
          icon={<FaQuestionCircle />}
          backgroundColor="#F44336"
          iconColor="#FFFFFF"
        />
        <DashCard
          title="Answers"
          value={345}
          icon={<FaComments />}
          backgroundColor="#4CAF50"
          iconColor="#FFFFFF"
        />
      </div>
      <div className="mt-8">
        <Barcharts chartData={barChartData} />
      </div>
      <div className="mt-8  grid grid-cols-2 gap-2   ">
        <PieChart chartData={pieChartData} />
        <LineChart/>
      </div>
    </div>
  );
};

export default Home;
