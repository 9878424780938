import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  HomeIcon,
  UserCircleIcon,
  ChartPieIcon,
  XMarkIcon,
  CalendarDaysIcon,
  UserIcon,
  UsersIcon,
  Cog6ToothIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/outline";
import { ExitToApp } from "@mui/icons-material";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import logo from "@/assets/images/igaju logo.png";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useCheckTokenMutation, useWhoamiQuery } from "@/app/auth/API";
import { storage } from "@/utils";
import { useAppSelector } from "@/core/lib";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
export const Dashlayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, data } = useWhoamiQuery(null, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  const { user } = useAppSelector((state: any) => state.userState);

  const storedToken: any = storage.getToken();
 
  useEffect(() => {
    if (!storedToken && !user) {
      navigate("/login");
    }
  }, [navigate, storedToken, user]);

  const [checkToken] = useCheckTokenMutation();

  useEffect(() => {
    if (!storedToken) {
      // checkToken(storedToken)
      //   .unwrap()
      //   .then(() => {
    
      //   })
      //   .catch((error:any) => {
          
      //     if (error?.error?.data?.message === "Token expired") {
        
      //       storage.removeToken();
            navigate("/login");
            toast.error("Your session has expired. Please log in again.");
       
    }
  }, [checkToken, storedToken, navigate]);

  const logout = () => {
    storage.removeToken();
    navigate("/login");
    toast.error("You logged out");
  };



  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>
            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-teal-900  px-6 pb-4">
                    <div className="ml-5 flex shrink-0  flex-col  items-start justify-center">
                      <div className="flex flex-col items-center justify-center">
                        <img className=" w-[80px]" src={logo} alt="IGAJU" />
                        <p className="text-lg font-medium text-white ">IGAJU</p>
                      </div>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="-mx-2 space-y-1">
                        {/* Dashboard */}
                        <li className="w-[250px]">
                          <Link
                            to="/dashboard"
                            className={classNames(
                              location.pathname === "/dashboard"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <HomeIcon
                              className={classNames(
                                location.pathname === "/dashboard"
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Dashboard")}
                          </Link>
                        </li>
                        {/* Category */}
                        <li className="w-[250px]">
                          <Link
                            to="/dashboard/categories"
                            className={classNames(
                              location.pathname === "/dashboard/categories"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <ChartPieIcon
                              className={classNames(
                                location.pathname === "/dashboard/categories"
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Category")}
                          </Link>
                        </li>
                        {/* Question */}
                        <li className="w-[250px]">
                          <Link
                            to="/dashboard/questions"
                            className={classNames(
                              location.pathname === "/dashboard/questions"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <QuestionMarkCircleIcon
                              className={classNames(
                                location.pathname === "/dashboard/questions"
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Questions")}
                          </Link>
                        </li>

                        <li className="w-[250px]">
                          <Link
                            to="/dashboard/answers"
                            className={classNames(
                              location.pathname === "/dashboard/answers"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <AcademicCapIcon
                              className={classNames(
                                location.pathname === "/dashboard/answers"
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Answers")}
                          </Link>
                        </li>
                        {/* Patients */}
                        <li className="w-[250px]">
                          <Link
                            to="/dashboard/patients"
                            className={classNames(
                              location.pathname === "/dashboard/patients"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <UserIcon
                              className={classNames(
                                location.pathname === "/dashboard/patients"
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Patients")}
                          </Link>
                        </li>
                         {/* Assign-Parent */}
                         <li className="w-[250px]">
                          <Link
                            to="/dashboard/assignparents"
                            className={classNames(
                              location.pathname === "/dashboard/assignparents"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <UserIcon
                              className={classNames(
                                location.pathname === "/dashboard/assignparents"
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Assign-Parent")}
                          </Link>
                        </li>

                         {/* Assign-Teacher */}
                         <li className="w-[250px]">
                          <Link
                            to="/dashboard/assignteachers"
                            className={classNames(
                              location.pathname === "/dashboard/assignteachers"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <UserIcon
                              className={classNames(
                                location.pathname === "/dashboard/assignteachers"
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Assign-Teacher")}
                          </Link>
                        </li>

                        {/* Students */}
                        <li className="w-[250px]">
                          <Link
                            to="/dashboard/students"
                            className={classNames(
                              location.pathname === "/dashboard/students"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <UserIcon
                              className={classNames(
                                location.pathname === "/dashboard/students"
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Students")}
                          </Link>
                        </li>

                        {/* Appointments */}
                        <li className="w-[250px]">
                          <Link
                            to="/dashboard/appoints"
                            className={classNames(
                              location.pathname === "/dashboard/appoints"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <CalendarDaysIcon
                              className={classNames(
                                location.pathname === "/dashboard/appoints"
                                  ? " text-white"
                                  : " text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Appointments")}
                          </Link>
                        </li>
                        <li className="w-[250px]">
                          <Link
                            to="/dashboard/patient-appointments"
                            className={classNames(
                              location.pathname === "/dashboard/patient-appointments"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <CalendarDaysIcon
                              className={classNames(
                                location.pathname === "/dashboard/patient-appointments"
                                  ? " text-white"
                                  : " text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t(" Patient Appointments")}
                          </Link>
                        </li>
                        <li className="w-[250px]">
                          <Link
                            to="/dashboard/users"
                            className={classNames(
                              location.pathname === "/dashboard/users"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <CalendarDaysIcon
                              className={classNames(
                                location.pathname === "/dashboard/users"
                                  ? " text-white"
                                  : " text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Users")}
                          </Link>
                        </li>
                        <li className="w-[250px]">
                          <Link
                            to="/dashboard/settings"
                            className={classNames(
                              location.pathname === "/dashboard/settings"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <Cog6ToothIcon
                              className={classNames(
                                location.pathname === "/dashboard/settings"
                                  ? " text-white"
                                  : " text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t("Settings")}
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto  bg-teal-900  px-6 pb-4">
            <div className="mt-5 ml-5 flex  shrink-0  flex-col  items-start justify-center">
              <div className="flex flex-col items-center justify-center">
                <img className=" w-[80px]" src={logo} alt="IGAJU" />
                <p className="text-lg font-medium text-white ">IGAJU</p>
              </div>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="-mx-2 space-y-1">
                {/* Dashboard */}
                <li className="w-[250px]">
                  <Link
                    to="/dashboard"
                    className={classNames(
                      location.pathname === "/dashboard"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <HomeIcon
                      className={classNames(
                        location.pathname === "/dashboard"
                          ? "text-white"
                          : "text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Dashboard")}
                  </Link>
                </li>
                {/* Category */}
                <li className="w-[250px]">
                  <Link
                    to="/dashboard/categories"
                    className={classNames(
                      location.pathname === "/dashboard/categories"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <ChartPieIcon
                      className={classNames(
                        location.pathname === "/dashboard/categories"
                          ? "text-white"
                          : "text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Category")}
                  </Link>
                </li>
                {/* Question */}
                <li className="w-[250px]">
                  <Link
                    to="/dashboard/questions"
                    className={classNames(
                      location.pathname === "/dashboard/questions"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <QuestionMarkCircleIcon
                      className={classNames(
                        location.pathname === "/dashboard/questions"
                          ? "text-white"
                          : "text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Question")}
                  </Link>
                </li>
                {/* Answers */}
                <li className="w-[250px]">
                  <Link
                    to="/dashboard/answers"
                    className={classNames(
                      location.pathname === "/dashboard/answers"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <AcademicCapIcon
                      className={classNames(
                        location.pathname === "/dashboard/answers"
                          ? "text-white"
                          : "text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Answers")}
                  </Link>
                </li>
                {/* Patients */}
                <li className="w-[250px]">
                  <Link
                    to="/dashboard/patients"
                    className={classNames(
                      location.pathname === "/dashboard/patients"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <UserIcon
                      className={classNames(
                        location.pathname === "/dashboard/patients"
                          ? "text-white"
                          : "text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Patients")}
                  </Link>
                </li>
                 {/* Parents */}
                 <li className="w-[250px]">
                  <Link
                    to="/dashboard/assignparents"
                    className={classNames(
                      location.pathname === "/dashboard/assignparents"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <UserIcon
                      className={classNames(
                        location.pathname === "/dashboard/assignparents"
                          ? "text-white"
                          : "text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Parents")}
                  </Link>
                </li>

                {/* Teachers */}
                <li className="w-[250px]">
                  <Link
                    to="/dashboard/assignteachers"
                    className={classNames(
                      location.pathname === "/dashboard/assignteachers"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <UserIcon
                      className={classNames(
                        location.pathname === "/dashboard/assignteachers"
                          ? "text-white"
                          : "text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Teachers")}
                  </Link>
                </li>

                 {/* Students */}
                 <li className="w-[250px]">
                  <Link
                    to="/dashboard/students"
                    className={classNames(
                      location.pathname === "/dashboard/students"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <UserIcon
                      className={classNames(
                        location.pathname === "/dashboard/students"
                          ? "text-white"
                          : "text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Students")}
                  </Link>
                </li>
                {/* Answers (second occurrence, if needed) */}

                {/* Appointments */}
                <li className="w-[250px]">
                  <Link
                    to="/dashboard/appoints"
                    className={classNames(
                      location.pathname === "/dashboard/appoints"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <CalendarDaysIcon
                      className={classNames(
                        location.pathname === "/dashboard/appoints"
                          ? " text-white"
                          : " text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Appointments")}
                  </Link>
                </li>
                <li className="w-[250px]">
                          <Link
                            to="/dashboard/patient-appointments"
                            className={classNames(
                              location.pathname === "/dashboard/patient-appointments"
                                ? "bg-teal-600 text-white"
                                : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                            )}
                          >
                            <CalendarDaysIcon
                              className={classNames(
                                location.pathname === "/dashboard/patient-appointments"
                                  ? " text-white"
                                  : " text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {t(" Patient Appointments")}
                          </Link>
                        </li>
                <li className="w-[250px]">
                  <Link
                    to="/dashboard/users"
                    className={classNames(
                      location.pathname === "/dashboard/users"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <UsersIcon
                      className={classNames(
                        location.pathname === "/dashboard/users"
                          ? " text-white"
                          : " text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Users")}
                  </Link>
                </li>
                <li className="w-[250px]">
                  <Link
                    to="/dashboard/settings"
                    className={classNames(
                      location.pathname === "/dashboard/settings"
                        ? "bg-teal-600 text-white"
                        : "text-indigo-200 hover:bg-teal-600 hover:text-white",
                      "group flex gap-x-3 rounded-md p-2 text-lg leading-6"
                    )}
                  >
                    <Cog6ToothIcon
                      className={classNames(
                        location.pathname === "/dashboard/settings"
                          ? " text-white"
                          : " text-indigo-200 group-hover:text-white",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {t("Settings")}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="bg-gray-100 lg:pl-72 ">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                />
              </form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />
                {/* Profile dropdown */}

                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-10 w-10 rounded-full bg-gray-50"
                      src={logo}
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="text-md ml-4 font-semibold capitalize leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {user?.name}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0   z-10 mt-2.5 w-[15vw]    origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        <div className="flex w-full flex-row justify-start  bg-gradient-to-tr from-[#148FB6] to-green-800  p-4">
                          <div className=" flex flex-row items-center justify-center  rounded-md">
                            <img
                              className="h-12 w-12 rounded-full bg-gray-50"
                              src={logo}
                              alt=""
                            />
                          </div>
                          <div className="  flex flex-col items-center justify-center px-2 capitalize ">
                            <p className=" text-lg  font-bold text-white">
                              {user?.lastName}
                            </p>
                            <p className=" text-sm  text-white">
                              {" "}
                              {user?.lastName}
                            </p>
                          </div>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/dashboard/settings"
                            className={`${
                              active ? "bg-gray-100" : ""
                            } text-md flex flex-row px-4 py-2 text-gray-700`}
                          >
                            <UserCircleIcon className="mr-2 h-6 w-6" />
                            Profile
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? "bg-gray-100" : ""
                            } text-md flex  w-full flex-row px-4 py-2 text-gray-700 `}
                            onClick={logout}
                          >
                            <ExitToApp className="mr-2 h-4 w-4" />
                            Logout
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className="min-h-[92vh]  overflow-hidden bg-gray-200  py-10 ">
            <div className="px-4 sm:px-6 lg:px-8 ">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
