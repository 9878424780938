import React, { useEffect, useState, useTransition } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useAppSelector } from "@/core/lib";
import { useGetCategoryQuestionsQuery } from "../API/redux";
import { storage } from "@/utils";
import formatDateToLongForm from "@/utils/DateFormattter";
import Select from "@/components/select/SelectField";

const Questions = () => {
  const t = useTransition();
  const { categoryId } = useParams();
  const [sortedData, setSortedData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const getAnswers = (question: any) => {
    const answers = question.answer.map((ans: any) => ({
      title: ans.title[selectedLanguage],
      isCorrect:
        ans.title[selectedLanguage] === "Yes" ||
        ans.title[selectedLanguage] === "Oui" ||
        ans.title[selectedLanguage] === "Ego",
    }));
    return answers;
  };

  const {
    isLoading,
    refetch,
    isFetching,
    data: categoryData,
  } = useGetCategoryQuestionsQuery(
    { id: categoryId },
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );

  const { questions }: any = useAppSelector((state) => state.questionState);
  console.log("questions", questions);

  useEffect(() => {
    if (questions) {
      setSortedData(questions?.questions);
    }
  }, [questions]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetch();
      } catch (error) {
        // Handle error
      }
    };

    if (!isFetching) {
      fetchData();
    }
  }, [categoryId]);

  return (
    <div className="px-4">
     <div className="mb-4 flex w-1/4  flex-row items-center justify-center">
          <label htmlFor="languageSelect" className="mr-2  whitespace-nowrap">
            Select Language:
          </label>
          <Select
            id="languageSelect"
            className="borde-gray-400 rounded border px-2 py-1"
            onChange={(e) => setSelectedLanguage(e.target.value)}
            value={selectedLanguage}
          >
            <option value="en">English</option>
            <option value="fr">French</option>
            <option value="ki">Kinyarwanda</option>
          </Select>
        </div>
      <div className="flex flex-row justify-between "> <h2 className="mb-4 text-2xl font-bold text-gray-500"> Category Question</h2>


      </div>

      {categoryData || !isLoading ? (
        <div
          key={categoryId}
          className="mb-4 rounded border bg-white p-4 shadow"
        >
          <div className="flex flex-row justify-between t">
            <div className="flex flex-row items-between  justify-center">
              <p className="mb-2 text-lg font-semibold text-gray-600">
                {categoryData?.description[selectedLanguage]}
              </p>
            </div>
            <div>
              <NavLink to="/dashboard/questions">
                <button className="p-2  rounded text-md bg-primary text-white ">All Questions </button>

              </NavLink>
              <NavLink to="/dashboard/categories">
                <button className="p-2 rounded mx-2  text-md bg-primary text-white ">All Categories </button>

              </NavLink>
            </div>
          </div>

          <table className="mt-4 w-full table-auto bg-gray-50">
            <thead className="border-b border-gray-400 text-gray-600">
              <tr>
                <th className="px-4 py-2 text-center ">#</th>
                <th className="px-4 py-2 text-center ">Questions</th>
                <th className="px-4 py-2 text-center ">Marks</th>
                <th className="px-4 py-2 text-center ">Answers</th>
                <th className="px-4 py-2 text-center ">Date</th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((question: any, questionIndex) => (

                <tr key={questionIndex} className="">
                  <td className="px-4 py-2 text-sm">{questionIndex + 1}</td>

                  <td className="whitespace-pre-wrap px-4 py-2  text-base ">
                    {question.title[selectedLanguage]}
                  </td>
                  <td className="px-4 py-2 text-sm">{question.marks}</td>
                  <td className="px-4 py-2">
                    <ul className=" flex flex-row justify-center ">
                      {getAnswers(question).map(
                        (answer: any, answerIndex: any) => (
                          <li
                            key={answerIndex}
                            className={
                              answer.isCorrect
                                ? "text-green-600 text-sm"
                                : "text-red-600 text-sm"
                            }
                          >
                            {answer.title}
                            {answer.isCorrect ? " (Correct)" : " (Incorrect)"}
                          </li>
                        )
                      )}
                    </ul>
                  </td>
                  <td className="px-4 py-2 text-center whitespace-nowrap text-sm ">

                    {formatDateToLongForm(questions.created_at)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default Questions;
