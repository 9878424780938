import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import baseApi from "@/core/lib/base";
import toast from "react-hot-toast";

// Define the type for UserResponse
interface UserResponse {
  list: Teacher[]; // Assuming the API response has a 'list' field that contains an array of teachers
}

// Define the type for Teacher
interface Teacher {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phoneNumber: string;
  password: string;
  occupation: string;
  role: string;
  creatorId: string | null;
  status: number;
  created_at: string;
  updated_at: string;
}

// Define the type for Student
interface Student {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone_1: string;
  phone_2: string;
  motherName: string;
  fatherName: string;
  gender: string;
  dob: string;
  placeOfBirth: string;
  photo: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  location: string | null;
  parents: any[]; // Adjust if you have a specific type for teachers
  teachers: Teacher[];
  post: any[]; // Adjust if you have a specific type for post
}

// Define the type for AssignTeacherModel
export interface AssignTeacherModel {
  // Define properties of AssignTeacherModel here
}

// Define a generic ResponseModel type
interface ResponseModel<T = void> {
  data: T;
  message?: string;
}

// Define the API endpoints for managing teacher assignments
export const AssignTeachersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch assignments
    getAssignments: builder.query<AssignTeacherModel[], { studentId: number }>({
      query: (params) => ({
        method: "GET",
        url: `/api/v2/student/${params.studentId}/teachers`,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(assignTeacherSlice.actions.setAssignment(data)); // Directly set data
        } catch (error) {
          throw error;
        }
      },
    }),

    // Fetch all assigned students' teachers
    getAssignedStudentsTeachers: builder.query<UserResponse, void>({
      query: () => ({
        method: "GET",
        url: '/api/v2/student/assigned-teachers',
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        dispatch(assignTeacherSlice.actions.fetchAssignedStudentsTeachersRequest());
        try {
          const { data } = await queryFulfilled;
          dispatch(assignTeacherSlice.actions.fetchAssignedStudentsTeachersSuccess(data.list)); // Access 'list' field
        } catch (error) {
          dispatch(assignTeacherSlice.actions.fetchAssignedStudentsTeachersFailure('Failed to fetch assigned students and teachers'));
        }
      },
    }),

    // Assign teachers to a student
    assignTeachers: builder.mutation<ResponseModel<null>, { studentId: number; teacherId: string }>({
      query: (payload) => ({
        method: "POST",
        url: `/api/v2/student/${payload.studentId}/assign-teacher`,
        body: {
          teacherId: payload.teacherId,
        },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success("Teachers assigned successfully");
        } catch (error: any) {
          console.error("Error details:", error);
          toast.error(error?.data?.message || "Failed to assign teachers");
        }
      },
    }),

    // Update assignments
    updateAssignment: builder.mutation<ResponseModel<null>, { studentId: number; teacherIds: number[] }>({
      query: ({ studentId, teacherIds }) => ({
        method: "PATCH",
        url: `/api/v2/student/${studentId}/update-teachers`,
        body: { teacherIds },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success("Assignment updated successfully");
        } catch (error: any) {
          toast.error(error?.data?.message || "Failed to update assignment");
        }
      },
    }),

    // Delete assignments
    deleteTeacherAssignment: builder.mutation<void, { studentId: number; teacherId: string }>({
      query: ({ studentId, teacherId }) => ({
        method: "DELETE",
        url: `/api/v2/student/${studentId}/remove-teacher/${teacherId}`,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success("Teacher removed from assignment");
        } catch (error: any) {
          console.error("Error details:", error);
          const errorMessage = error?.data?.message || "Failed to remove teachers";
          toast.error(errorMessage);
        }
      },
    }),

    // Fetch teachers with role 'Teacher'
    getTeachers: builder.query<UserResponse, void>({
      query: () => ({
        method: "GET",
        url: '/api/v2/user',
        params: {
          role: "teacher", // Correct role to fetch teachers
        },
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        dispatch(assignTeacherSlice.actions.fetchTeachersRequest());
        try {
          const { data } = await queryFulfilled;
          dispatch(assignTeacherSlice.actions.fetchTeachersSuccess(data.list)); // Access 'list' field
        } catch (error) {
          dispatch(assignTeacherSlice.actions.fetchTeachersFailure('Failed to fetch teachers'));
        }
      },
    }),

    // Fetch students
    getStudentss: builder.query<Student[], void>({
      query: () => ({
        method: "GET",
        url: "/api/v2/student",
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        dispatch(assignTeacherSlice.actions.fetchStudentsRequest());
        try {
          const { data } = await queryFulfilled;
          dispatch(assignTeacherSlice.actions.fetchStudentsSuccess(data)); // Directly use data array
        } catch (error) {
          dispatch(assignTeacherSlice.actions.fetchStudentsFailure('Failed to fetch students'));
        }
      },
    }),
  }),
});

// Slice for handling teacher assignment and user state
export const assignTeacherSlice = createSlice({
  name: 'assignTeachers',
  initialState: {
    assignments: [] as AssignTeacherModel[],
    teachers: [] as Teacher[], // Updated state for teachers
    students: [] as Student[], // Updated state for students
    assignedStudentsTeachers: [] as Teacher[], // Added state for assigned students' teachers
    loading: false,
    error: null as string | null,
  },
  reducers: {
    setAssignment: (state, action: PayloadAction<AssignTeacherModel[]>) => {
      state.assignments = action.payload;
    },
    fetchAssignedStudentsTeachersRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchAssignedStudentsTeachersSuccess: (state, action: PayloadAction<Teacher[]>) => {
      state.assignedStudentsTeachers = action.payload;
      state.loading = false;
    },
    fetchAssignedStudentsTeachersFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchTeachersRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchTeachersSuccess: (state, action: PayloadAction<Teacher[]>) => {
      state.teachers = action.payload;
      state.loading = false;
    },
    fetchTeachersFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchStudentsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchStudentsSuccess: (state, action: PayloadAction<Student[]>) => {
      state.students = action.payload;
      state.loading = false;
    },
    fetchStudentsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Export hooks for using the API in components
export const { 
  useGetAssignmentsQuery,
  useAssignTeachersMutation,
  useUpdateAssignmentMutation,
  useDeleteTeacherAssignmentMutation,
  useGetTeachersQuery, // Export the hook for fetching teachers
  useGetStudentssQuery, // Export the hook for fetching students
  useGetAssignedStudentsTeachersQuery, // Export the hook for fetching assigned students' teachers
} = AssignTeachersApi;

export const { 
  setAssignment, 
  fetchTeachersRequest, 
  fetchTeachersSuccess, 
  fetchTeachersFailure,
  fetchStudentsRequest,
  fetchStudentsSuccess,
  fetchStudentsFailure,
  fetchAssignedStudentsTeachersRequest,
  fetchAssignedStudentsTeachersSuccess,
  fetchAssignedStudentsTeachersFailure,
} = assignTeacherSlice.actions;

export default assignTeacherSlice.reducer;
