import { AnswerModel } from './model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	answers: AnswerModel | null;
}

const initialState: InitialState = {
	answers: null,
};
console.log(initialState)
export const answerSlice = createSlice({
	initialState,
	name: 'answers',
	reducers: {
		setAnswers: (state, action: PayloadAction<AnswerModel>) => {
			state.answers = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
