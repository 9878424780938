import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

import { CircularProgress } from "@mui/material";
import { useDeletPatientMutation } from "../API/redux";
import { TrashIcon } from "@heroicons/react/24/outline";

export default function DeletePatient({ data, handleFilter }: any) {
  const dialog = useRef<HTMLDialogElement>(null);

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };
  const [deleteUser, { isLoading, isSuccess }] = useDeletPatientMutation();

  const handleDelete = () => {
    deleteUser(data.id)
      .then(() => {})
      .catch((error) => {
        toast.error("deleting failed");
      });
  };

  return (
    <>
      <dialog
        ref={dialog}
        className=" rounded-lg shadow-lg   "
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="rounded p-3">
          <div className="flex flex-row items-center justify-center">
            <h3 className="p-2 text-lg text-gray-600">
              <i className="fas fa-exclamation-triangle mx-2 text-xl text-red-500"></i>{" "}
              Delete Pateint
            </h3>
          </div>
          <div>
            <div className="form-group">
              <div className="flex flex-col justify-center items-start">
                <p className="text-lg text-gray-500 capitalize">
                  are you sure you want to Delete this Pateint
                </p>
                <p className="font-bold text-md capitalize text-gray-500 ">
                 Names:  <span className="font-medium"> {data.firstName} {data.lastName}</span>
                </p>
              </div>
            </div>

            <div className="mt-2 flex flex-row justify-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="rounded bg-primary px-4  py-2  text-white"
                  disabled
                >
                  <CircularProgress size={24} sx={{ color: "white" }} />
                </button>
              ) : (
                <button
                  type="submit"
                  className="rounded bg-primary px-4  py-2  text-white"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              )}
              <button
                type="button"
                className="mx-2 cursor-pointer  rounded bg-primary   px-4 py-2 text-white"
                onClick={closeModel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </dialog>
      <button
        onClick={() => openModel()}
        className=" mx-1  rounded    text-red-400 hover:text-red-600"
      >
        {}
     <TrashIcon className="w-5 h-5 "/>
      </button>
    </>
  );
}
