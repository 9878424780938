import React, { useEffect, useState } from "react";
import { dummyCats } from "@/Dummy/categories";
import { NavLink, Route, Routes, useParams } from "react-router-dom";
import { useAppSelector } from "@/core/lib";
import { useGetPateintsQuery } from "../API/redux";
import { storage } from "@/utils";
import PaginationButtons from "@/components/paginationbuttons/PaginationButtons";
import formatDateToLongForm from "@/utils/DateFormattter";
import AddPatient from "./AddPatient";
import EditPatient from "./EditPatient";
import DeletePatient from "./DeletePatient";
import { DocumentDuplicateIcon, FunnelIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import Search from "@/components/Search";
import { CircularProgress } from "@mui/material";
import Filter from "@/components/filters/Filter";
const AllPatients = () => {
 
  return (
    <div className=" ">
     

      <Routes>
            <Route path="/" element={<PatientTable />}></Route>

            <Route path="/new" element={<AddPatient />}></Route>
          </Routes>
    </div>
  );
};

export default AllPatients;


function PatientTable(){
  const { categoryId } = useParams();

  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [status, SetStatusFilter] = useState("");
  const [from, setStartDateFilter] = useState("");
  const [to, setEndDateFilter] = useState("");
  const [search, setSearchValue] = useState("");

  const resetFilters = () => {
    SetStatusFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
  };

  const handleSearchChange = (searchValue:any) => {
    setSearchValue(searchValue)

  };
  const { isLoading, refetch, isFetching } = useGetPateintsQuery(
    { pageNumber, pageSize, status, from, to, search },
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );

  const [sortedData, setSortedData] = useState<any>([]);
  const { patients } = useAppSelector((state: any) => state.patientState);

  useEffect(() => {
    if (patients) {
      setSortedData(patients?.list);
    }
  }, [patients]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        await refetch();
      } catch (error) {
      } finally {
        setIsLoadingData(false);
      }
    };

    if (!isFetching) {
      setIsLoadingData(true);
      fetchData();
    }
  }, [pageNumber, pageSize, from, to, status,search, isFetching, refetch]);


  return(



    <div className=" bg-white  p-3 ">
     <div className="flex flex-row justify-between ">
        <div className="flex flex-row justify-start">
          <p className="my-2 text-2xl  font-medium  text-gray-500">
            Patients
          </p>
        </div>

        <div className="flex w-full flex-row items-start justify-end p-3 text-gray-300">
        <Search onChange={handleSearchChange} />

      <NavLink to="/dashboard/patients/new">
      <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
              <PlusCircleIcon className="h-6 w-6" />
              <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
               Register  Patient
              </span>
            </button>
      </NavLink>
    
      
      <button className="group relative mx-1 rounded bg-primary p-2 capitalize">
            <DocumentDuplicateIcon className="h-6 w-6" />
            <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
              Export Patients
            </span>
          </button>
          <Filter
          resetFilters={resetFilters}
              children={
                <>
                <div className="flex flex-row justify-center">
                <h2 className="mb-2 text-md font-semibold">
                    Date Range Filter
                  </h2>
                </div>
               
                  <div className="mb-4">
                    <label htmlFor="fromDate">From :</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="h-12 w-full rounded border border-teal-400   focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40"
                      value={from}
                      onChange={(e) => setStartDateFilter(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="toDate" className="">
                      To 
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className=" h-12 w-full rounded border border-teal-400  focus:border-teal-400 focus:outline-none focus:ring focus:ring-teal-400 focus:ring-opacity-40 "
                      value={to}
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </div>
                
                </>
              }
            />
    </div>
    </div>
    {isLoading ? (
        <div className="flex flex-row items-center  justify-center  p-4 ">
          <CircularProgress size={24} sx={{ color: "black" }} />{" "}
          <p>Please wait </p>
        </div>
      ) : (
          <><div className="overfow-x-scroll scrollbar-hidden">
            <table className="min-w-full  leading-normal">
              <thead>
                <tr>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold  whitespace-nowrap uppercase tracking-wider text-gray-600">
                    ID
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold whitespace-nowrap uppercase tracking-wider text-gray-600">
                    Full  Name
                  </th>

                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold whitespace-nowrap  uppercase tracking-wider text-gray-600">
                    Gender
                  </th>
                  <th className="group relative border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase whitespace-nowrap tracking-wider text-gray-600">
                    DOB
                    <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
                      Date Of Birth
                    </span>
                  </th>
                  <th className="group relative border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase whitespace-nowrap tracking-wider text-gray-600">
                    POB
                    <span className="whitespace-no-wrap invisible absolute  top-10 z-30  -mt-2   ml-2  rounded  bg-black px-1 py-1 text-xs text-white group-hover:visible">
                      Place Of Birth
                    </span>
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider whitespace-nowrap text-gray-600">
                    Phone 1
                  </th>

                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider whitespace-nowrap text-gray-600">
                    Mother Name
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider whitespace-nowrap text-gray-600">
                    Father Name
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider whitespace-nowrap text-gray-600">
                    date Added
                  </th>
                  <th className="border-b-2 border-gray-200 bg-light px-2 py-1.5 text-left text-xs font-semibold uppercase tracking-wider whitespace-nowrap text-gray-600">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="border-b border-gray-300">
                {sortedData?.map((patient: any, index: any) => (
                  <tr key={patient.id}>
                    <td className=" capitalize border-b-1 text-gray-600 border-gray-200 px-2 py-1.5 text-left text-sm">{patient.id}</td>
                    <td className=" capitalize border-b-1 whitespace-nowrap border-gray-200 px-2 py-1.5 text-left text-sm text-gray-600">
                      {patient.firstName}       {patient.lastName}
                    </td>

                    <td className=" capitalize border-b-1 whitespace-nowrap border-gray-200 px-2 py-1.5 text-left text-sm text-gray-600">
                      {patient.gender}
                    </td>
                    <td className=" capitalize border-b-1 whitespace-nowrap border-gray-200 px-2 py-1.5 text-left text-sm text-gray-600">
                      {formatDateToLongForm(patient.dob)}
                    </td>
                    <td className="border-b-1 whitespace-nowrap border-gray-200 px-2 py-1.5 text-left text-sm text-gray-600">
                      {patient.placeOfBirth}
                    </td>
                    <td className="border-b-1 whitespace-nowrap border-gray-200 px-2 py-1.5 text-left text-sm text-gray-600">
                      {patient.phone_1}
                    </td>

                    <td className="border-b-1  capitalize whitespace-nowrap border-gray-200 px-2 py-1.5 text-left text-sm text-gray-600">
                      {patient.motherName}
                    </td>
                    <td className="border-b-1 capitalize whitespace-nowrap border-gray-200 px-2 py-1.5 text-left text-sm text-gray-600">
                      {patient.fatherName}
                    </td>
                    <td className="border-b-1 whitespace-nowrap border-gray-200 px-2 py-1.5 text-left text-sm text-gray-600">
                      {formatDateToLongForm(patient.created_at)}
                    </td>
                    <td className="border-b-1  whitespace-nowrap border-gray-200 px-2 py-1.5 text-left text-sm text-gray-600">
                      <div>
                        <EditPatient data={patient} />
                        <DeletePatient data={patient} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div><div className="my-4 flex flex-col items-center justify-between sm:flex-row">
              <div className="mb-2 flex flex-col items-center justify-center sm:mb-0 sm:flex-row">
                <label htmlFor="" className="text-md mb-2 text-gray-500 sm:mb-0">
                  Results Per Page
                </label>
                <select
                  className="mx-2 rounded border border-gray-300 bg-light text-sm font-normal text-dark outline-none placeholder:text-gray-500 focus:border-primary focus:outline-none focus:ring-0"
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="5">5</option>
                  <option value="10">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value={patients?.total}>All</option>
                </select>
              </div>
              <PaginationButtons
                currentPage={pageNumber}
                setCurrentPage={setPageNumber}
                totalPages={patients?.lastPage || 0} />
            </div></>
  
      )}
    
  </div>
  )
}