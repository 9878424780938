import React, { useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";
import { z } from "zod";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Form } from "@/components/form";
import { useUpdateAppointmentMutation } from "../redux";
import Select from "@/components/select/SelectField";

const schema = z.object({
  firstName: z.string().min(1, "First Name is required"),
  lastName: z.string().min(1, "Last Name is required"),
  locationId: z.string().min(1, "Location ID is required"),
  dob: z.string().min(1, "Date of Birth is required"),
  email: z.string().min(1, "Email is required"),
  motif: z.string().min(1, "Motif is required"),
  gender: z.string().min(1, "Gender is required"),
  phone: z.string().min(1, "Phone is required"),
  availableDateId: z.string().min(1, "Available Date ID is required"),
});

export type AppointmentInput = z.TypeOf<typeof schema>;
export default function EditAppoint({ data }: any) {
  const dialog = useRef<HTMLDialogElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(data.dob));

  const [UpdateAppoint, { isLoading: isUpdating }] =
    useUpdateAppointmentMutation();

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };

  return (
    <>
      <dialog
        ref={dialog}
        className="dialog w-[50%] rounded-lg shadow-lg"
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="rounded p-3">
          <div className="flex flex-row items-center justify-center">
            <h3 className="p-2 text-lg text-gray-600">Update Appointment</h3>
          </div>
          <Form<AppointmentInput, typeof schema>
            schema={schema}
            onSubmit={(payload) => {
              UpdateAppoint({ id: data.id, data: payload });
            }}
          >
            {({ register, formState }: any) => (
              <>
                <div className=" grid grid-cols-2  gap-2">
                  <InputField
                    type="text"
                    label="First Name"
                    placeholder="First Name"
                    loading={isLoading}
                    defaultValue={data.firstName}
                    error={formState.errors.firstName}
                    registration={register("firstName")}
                    className="h-13 mt-0"
                  />
                  <InputField
                    type="text"
                    label="Last Name"
                    placeholder="Last Name"
                    defaultValue={data.lastName}
                    loading={isLoading}
                    error={formState.errors.lastName}
                    registration={register("lastName")}
                    className="h-13 mt-0"
                  />

                  <InputField
                    type="text"
                    label="Phone number "
                    placeholder="Phone Number"
                    defaultValue={data.phone}
                    loading={isLoading}
                    error={formState.errors.phone}
                    registration={register("phone")}
                    className="h-13 mt-0"
                  />
                  <InputField
                    type="text"
                    label="Location ID"
                    defaultValue={data?.location?.id || "-"}
                    placeholder="Location ID"
                    loading={isLoading}
                    error={formState.errors.locationId}
                    registration={register("locationId")}
                    className="h-13"
                  />

                  {/* <InputField
                    type="date"
                    label="Date of birth"
                    placeholder="Date of Birth"
                    loading={isLoading}
                    defaultValue={selectedDate.toISOString().split("T")[0]}
                    onChange={(e: any) =>
                      setSelectedDate(new Date(e.target.dob))
                    }
                    error={formState.errors.dob}
                    registration={register("dob")}
                    className="h-13"
                  /> */}


                  <InputField
                    type="email"
                    label="Email"
                    placeholder="Email"
                    defaultValue={data.email}
                    loading={isLoading}
                    error={formState.errors.email}
                    registration={register("email")}
                    className="h-13"
                  />

                  <InputField
                    type="text"
                    label="Motif"
                    placeholder="Motif"
                    defaultValue={data.motif}
                    loading={isLoading}
                    error={formState.errors.motif}
                    registration={register("motif")}
                    className="h-13"
                  />
 <InputField
                    type="text"
                    label="Available Date"
                    placeholder="Available Date"
                    loading={isLoading}
                    defaultValue={data?.availableDate?.id}
                    error={formState.errors.availableDateId}
                    registration={register("availableDateId")}
                    className="h-13"
                  />
                  <div className="flex flex-col  ">
                    <label
                      htmlFor=""
                      className="mb-2 block text-sm text-gray-600 dark:text-gray-400"
                    >
                      {" "}
                      Gender
                    </label>

                    <Select
                      name="gender"
                      className=" h-12 rounded  border border-gray-400 text-gray-500 "
                      registration={register("gender")}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Select>
                    {formState.errors.gender && (
                      <span className="font-italics mt-2 block text-sm font-medium capitalize text-danger">
                        {formState.errors.gender.message}{" "}
                        {/* Access the error message */}
                      </span>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                  disabled={isLoading || isUpdating}
                >
                  {isLoading || isUpdating ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Update Appointment"
                  )}
                </button>
              </>
            )}
          </Form>
        </div>
      </dialog>
      <button onClick={() => openModel()} className="m-2 text-gray-600 hover:text-black   ">
        <PencilIcon className="h-5 w-5 " />
      </button>
    </>
  );
}
