import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";
import { z } from "zod";
import { format } from "date-fns";
import { Form } from "@/components/form";
import { NavLink } from "react-router-dom";
import { useAddAppointmentMutation, useGetAvailableDatesQuery } from "../redux";
import Select from "@/components/select/SelectField";
import { storage } from "@/utils";

const schema = z.object({
  firstName: z.string().min(1, "First Name is required"),
  lastName: z.string().min(1, "Last Name is required"),
  locationId: z.string().min(1, "Location ID is required"),
  dob: z.string().min(1, "Date of Birth is required"),
  email: z.string().min(1, "Email is required"),
  motif: z.string().min(1, "Motif is required"),
  gender: z.string().min(1, "Gender is required"),
  phone: z.string().min(1, "Phone is required"),
  availableDateId: z.string().min(1, "Available Date ID is required"),
});

export type AppointmentInput = z.TypeOf<typeof schema>;

export default function PostAppointment({ handleFilterAndAdd }: any) {
  const [selectedDateId, setSelectedDateId] = useState<string>("");

  const handleDateSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDateId(event.target.value);
  };
  const [selectedRole, setSelectedRole] = useState("");

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(event.target.value);
    console.log(selectedRole)
  };
  const [OnPost, { isLoading }] = useAddAppointmentMutation();
  
  const handleSubmit = (payload: AppointmentInput) => {
    payload.availableDateId = selectedDateId;
    OnPost(payload)
      .then(() => {
      })
      .catch((error: any) => {
      });
  };


  const { data }: any = useGetAvailableDatesQuery(
    {},
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );
  console.log(data);


  if (!data) {
    return <div> loading</div>;
  }


  return (
    <div className="rounded p-3">
      <div className="flex flex-row items-center justify-between pb-5    ">
        <h3 className="  text-2xl font-medium   text-gray-600">
          Request Appointment
        </h3>
        <NavLink to="/dashboard/appoints">
          <button className="mx-5 rounded  bg-primary px-2  py-1  text-white ">
            {" "}
            All Appointments
          </button>
        </NavLink>
      </div>

      <Form<AppointmentInput, typeof schema>
        schema={schema}
        onSubmit={handleSubmit}
      >
        {({ register, formState }) => (
          <>
            <div className="  mt-2 grid grid-cols-2  gap-2">
              <InputField
                type="text"
                label="First Name"
                placeholder="First Name"
                loading={isLoading}
                error={formState.errors.firstName}
                registration={register("firstName")}
                className="h-13 mt-0"
              />
              <InputField
                type="text"
                label="Last Name"
                placeholder="Last Name"
                loading={isLoading}
                error={formState.errors.lastName}
                registration={register("lastName")}
                className="h-13 mt-0"
              />

              <InputField
                type="number"
                label="Location ID"
                placeholder="Location ID"
                loading={isLoading}
                error={formState.errors.locationId}
                registration={register("locationId")}
                className="h-13"
              />

              <InputField
                type="date"
                label="Date of Birth"
                placeholder="Date of Birth"
                loading={isLoading}
                error={formState.errors.dob}
                registration={register("dob")}
                className="h-13"
              />

              <InputField
                type="email"
                label="Email"
                placeholder="Email"
                loading={isLoading}
                error={formState.errors.email}
                registration={register("email")}
                className="h-13"
              />

              <InputField
                type="text"
                label="Motif"
                placeholder="Motif"
                loading={isLoading}
                error={formState.errors.motif}
                registration={register("motif")}
                className="h-13"
              />

              <div className="flex flex-col  ">
                <label
                  htmlFor=""
                  className="mb-2 block text-sm text-gray-600 dark:text-gray-400"
                >
                  {" "}
                  Gender
                </label>

                <Select
                  name="gender"
                  className=" h-12 rounded  border border-gray-400 text-gray-500 "
                  registration={register("gender")}
                  value={selectedRole}
                  onChange={handleRoleChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Select>
                {formState.errors.gender && (
                  <span className="font-italics mt-2 block text-sm font-medium capitalize text-danger">
                    {formState.errors.gender.message}{" "}
                    {/* Access the error message */}
                  </span>
                )}
              </div>

              <InputField
                type="text"
                label="Phone"
                placeholder="Phone"
                loading={isLoading}
                error={formState.errors.phone}
                registration={register("phone")}
                className="h-13"
              />

              <div className="flex flex-col  ">
                <label
                  htmlFor="availableDateId"
                  className="mb-2 block text-sm text-gray-600 dark:text-gray-400"
                >
                  Available Date
                </label>

                <Select
                  name="availableDateId"
                  id="availableDateId"
                  className="h-12 rounded border border-gray-400 text-gray-500 bg-white "
                  registration={register("availableDateId")}
                  value={selectedDateId}
                  onChange={handleDateSelect}
                >
                  <option value="" disabled>
                    Select an available date
                  </option >
                  {data &&
                    Object.entries(data).map(([date, slots]: [string, any]) =>
                      slots.map((slot: any) => (
                        <option key={slot.id} value={slot.id}>
                          {`${format(
                            new Date(slot.date),
                            "yyyy-MM-dd hh:mm a"
                          )}`}
                        </option>
                      ))
                    )}
                </Select>

                {formState.errors.availableDateId && (
                  <span className="font-italics mt-2 block text-sm font-medium capitalize text-danger">
                    {formState.errors.availableDateId.message}
                  </span>
                )}
              </div>
            </div>
            {isLoading ? (
              <button
                type="submit"
                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                disabled
              >
                <CircularProgress size={24} sx={{ color: "white" }} />
              </button>
            ) : (
              <button
                type="submit"
                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
              >
                Request Appointment
              </button>
            )}
          </>
        )}
      </Form>
    </div>
  );
}
