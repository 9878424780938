import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import React from 'react'

function Search({ onChange }:any ) {
    const handleInputChange = (event:any) => {
    onChange(event.target.value);
  };
  return (
    <form className="relative flex w-[50%]  p-3 rounded border border-gray-200" action="#" method="GET">
    <label htmlFor="search-field" className="sr-only">
      Search
    </label>
    <MagnifyingGlassIcon
      className="pointer-events-none absolute inset-y-0 left-2 h-full w-5 text-gray-400"
      aria-hidden="true"
    />
    <input
      id="search-field"
      className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
      placeholder="Search..."
      type="search"
      name="search"
      onChange={handleInputChange}
    />
  </form>
  )
}

export default Search