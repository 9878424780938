import * as z from "zod";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "@/components/form";
import { InputField } from "@/components/input";
import { useLoginMutation } from "../API";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { storage } from "@/utils";


const schema = z.object({
  username: z.string().min(1, "Email address required"),
  password: z.string().min(1, "Password required"),
});

export type LoginInput = z.TypeOf<typeof schema>;

export const Login = () => {
  const navigate = useNavigate();

  const [onLogin, { isSuccess, isLoading }] = useLoginMutation();

const storedToken = storage.getToken();

	useEffect(() => {
    
		if (isSuccess || storedToken ){
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000)
    }
    else{
    
    }

	}, [isSuccess, navigate, storedToken]);

  return (
    <>
    <div className="flex flex-row items-center  justify-center">
      <p className="my-10 mt-3  text-3xl text-teal-500 ">Register</p>
    </div>

      <Form<LoginInput, typeof schema>
        schema={schema}
        onSubmit={(payload) => onLogin(payload)}
        className="mt-10"
      >
        {({ register, formState }) => (
          <>
            <div className="space-y-4">
              <InputField
                type="text"
                label="Username"
                placeholder="Username"
                loading={isLoading}
                focus
              
                error={formState.errors.username}
                registration={register("username")}
                className="h-13"
              />

              <InputField
                type="password"
                label="Password"
                placeholder="********"
                loading={isLoading}
         
                error={formState.errors.password}
                registration={register("password")}
                className="h-13"
              />

              <div className="flex flex-col  ">
                <div className="flex flex-row items-center  justify-between">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="form-control h-5 w-5 rounded-md"
                    />

                    <span className="text-sm font-normal text-gray-500">
                      Remember me
                    </span>
                  </label>

                  <Link to="#" className="text-sm font-medium text-primary">
                    Forgot password?
                  </Link>
                </div>
              </div>
            </div>
            {isLoading ? (
              <button
                type="submit"
                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase  text-white"
              >
                <CircularProgress size={24} sx={{ color: "white" }} />
              </button>
            ) : (
              <button
                type="submit"
                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
              >
                Log In
              </button>
            )}
          </>
        )}
      </Form>

      {/* <div>
        <p className="text-md mt-2 text-gray-500">
          Don't have an acount?{" "}
          <Link to="/register" className="font-medium text-primary">
            Sign Up
          </Link>
        </p>
      </div> */}
    </>
  );
};
