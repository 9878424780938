import { LoginInput } from "@/app/auth";
import { LoginModel } from "../types";
import baseApi from "@/core/lib/base";
import { userSlice } from "./user";
import { UserModel } from "./user";
import toast from "react-hot-toast";
import { ResponseModel } from "@/core/types";
import { storage } from "@/utils";
import { usersSlice } from "./user/usersSlice";
import { UserInput } from "@/app/dashboard/users/EditUser";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ResponseModel<LoginModel>, LoginInput>({
      query: (payload) => ({
        method: "POST",
        url: "/api/v2/user/login",
        body: payload,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          toast.success("Login successful");

          storage.setToken(data.access_token);
        } catch (error: any) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    whoami: builder.query<ResponseModel<UserModel>, null>({
      query: () => ({
        method: "GET",
        url: "/api/v2/user/check",
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          dispatch(userSlice.actions.setUser(data));
        } catch (error) {}
      },
    }),

    AdminSign: builder.mutation<ResponseModel<null>, UserInput>({
      query: (payload) => ({
        method: "POST",
        url: "/api/v2/user",
        body: payload,
      }),

      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          toast.success("User created successful");
        } catch (error: any) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),

    getUsers: builder.query<
      ResponseModel<UserModel>,
      {
        pageNumber?: number;
        pageSize?: number;
        from?: string;
        to?: string;
        search?: string;
        role?: string;
        status?: string;
        occupation?: string;
      }
    >({
      query: (queryParameters) => {
        const {
          pageNumber,
          pageSize,
          from,
          to,
          search,
          status,
          role,
          occupation,
        } = queryParameters;
        return {
          method: "GET",
          url: "/api/v2/user",
          params: {
            pageNumber,
            pageSize,
            from,
            to,
            role,
            status,
            occupation,
            search,
          },
        };
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          console.log("data", data);
          dispatch(usersSlice.actions.setUsers(data));
        } catch (error) {
          throw error;
        }
      },
    }),
    UpdateUser: builder.mutation<
      ResponseModel<null>,
      { id: string; data: Partial<UserInput> }
    >({
      query: ({ id, data }): any => ({
        method: "PATCH",
        url: `/api/v2/user/${id}`,
        body: data,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          toast.success("User  Updated successfully");
        } catch (error: any) {
          toast.error(error?.error?.data?.message);
          throw error;
        }
      },
    }),
    DeleteUser: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/api/v2/user/${id}`, // Change the endpoint URL
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          toast.success("User Deleted ");
        } catch (error: any) {
          toast.error(error?.error?.data?.message);
          throw error;
        }
      },
    }),
    checkToken: builder.mutation<ResponseModel<null>, string>({
      query: (token) => ({
        method: " post",
        url: `/api/v2/user/check-token/${token}`, // Include the token in the URL
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          storage.removeToken();
        } catch (error: any) {
          toast.error(error?.error?.data?.message);
          throw error;
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useWhoamiQuery,
  useGetUsersQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useAdminSignMutation,
  useCheckTokenMutation
} = authApi;
