import toast from 'react-hot-toast';
import { StudentModel } from './students';
import { studentSlice } from './students';  // Import the correct slice
import baseApi from '@/core/lib/base';  // Adjust import path as necessary
import { ResponseModel } from '@/core/types';
import { StudentInput } from '../../components/AddStudent';

export const StudentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStudents: builder.query<ResponseModel<StudentModel[]>, void>({
      query: () => ({
        method: 'GET',
        url: '/api/v2/student',
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          dispatch(studentSlice.actions.setStudent(data));  // Dispatch array of students
        } catch (error) {
          console.error('Error fetching students:', error);
          throw error;
        }
      },
    }),
    
    AddStudent: builder.mutation<ResponseModel<null>, StudentInput>({
      query: (payload) => ({
        method: 'POST',
        url: '/api/v2/student',
        body: payload,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          toast.success('Student added successfully');
        } catch (error: any) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    
    UpdateStudent: builder.mutation<ResponseModel<null>, { id: string; data: Partial<StudentInput> }>({
      query: ({ id, data }) => ({
        method: 'PATCH',
        url: `/api/v2/student/${id}`,
        body: data,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          toast.success('Student updated successfully');
        } catch (error: any) {
          toast.error(error?.error?.data?.message);
          throw error;
        }
      },
    }),
    
    DeleteStudent: builder.mutation<void, string>({
      query: (id) => ({
        method: 'DELETE',
        url: `/api/v2/student/${id}`,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success('Student deleted successfully');
        } catch (error: any) {
          toast.error(error?.error?.data?.message);
          throw error;
        }
      },
    }),
  }),
});

export const { useGetStudentsQuery, useAddStudentMutation, useDeleteStudentMutation, useUpdateStudentMutation } = StudentsApi;
