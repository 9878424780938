import toast from "react-hot-toast";
import { AnswerModel } from "./answers";
import { answerSlice } from "./answers";
import baseApi from "@/core/lib/base";
import { ResponseModel } from "@/core/types";
import { AnswerInput } from "../../components/AddAnswer";

export const AnswersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnswers: builder.query<ResponseModel<AnswerModel>, { pageNumber?: number, pageSize?: number, from?: string, to?: string, status?: string , search?: string}>({
      query: (queryParameters) => {
        const { pageNumber, pageSize, from, to, status,search } = queryParameters;
        return {
          method: "GET",
          url: "/api/v2/answer",
          params: {
            pageNumber,
            pageSize,
            from,
            to,
            status,
            search
          },
        };
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
        
          dispatch(answerSlice.actions.setAnswers(data));
        } catch (error) {
            
          throw error; 
        }
      },
    }),
    AddAnswer: builder.mutation<ResponseModel<null>, AnswerInput>({
      query: (payload) => ({
        method: "POST",
        url: "/api/v2/answer", 
        body: payload,
        
      }),
      
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
        
          const { data }: any = await queryFulfilled;
          toast.success("Answer added successfully");
         

        } catch (error: any) {
   
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    UpdateAnswer: builder.mutation<ResponseModel<null>, { id: string; data: Partial<AnswerInput> }>({
      query: ({ id, data }):any => ({
        method: "PATCH",
        url: `/api/v2/answer/${id}`, 
        body: data,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
    
        
          toast.success('answer   Updated successfully');
        } catch (error:any) {
  
          toast.error(error?.error?.data?.message)
          throw error;
        }
      },
    }),

    DeleteAnswer: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/api/v2/answer/${id}`,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
    
       
          toast.success('Answer Deleted');
      
        } catch (error:any) {
          toast.error(error?.error?.data?.message);
          throw error;
        }
      },
    }),
  }),
});

export const { useGetAnswersQuery,useAddAnswerMutation,useUpdateAnswerMutation,useDeleteAnswerMutation } = AnswersApi;
