import React, { useState } from 'react';
import { CircularProgress, MenuItem, Select, SelectChangeEvent, Button } from '@mui/material';
import { useGetStudentssQuery, useGetTeachersQuery, useAssignTeachersMutation } from '../API/redux';

// Define the type for Student
interface Student {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

// Define the type for Teacher
interface Teacher {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

// Define the response type
interface UserResponse {
  
}

// Define a custom error type
interface CustomError {
  data?: {
    message?: string;
  };
}

// Type guard to check if the error is a CustomError
const isCustomError = (error: unknown): error is CustomError => {
  return (error as CustomError).data !== undefined;
};

// Utility function to get error message
const getErrorMessage = (error: unknown): string => {
  if (isCustomError(error)) {
    return error.data?.message || 'An unexpected error occurred';
  }
  return 'An unexpected error occurred';
};

const AssignmentsComponent = () => {
  const { data: studentsData, isLoading: studentsLoading, error: studentsError } = useGetStudentssQuery();
  const { data: teachersData, isLoading: teachersLoading, error: teachersError } = useGetTeachersQuery();
  const [assignTeachers] = useAssignTeachersMutation();

  const [selectedStudent, setSelectedStudent] = useState<string | null>(null);
  const [selectedTeacher, setSelectedTeacher] = useState<string | null>(null);

  // Loading states
  if (studentsLoading || teachersLoading) return <div>Loading...</div>;

  // Error handling
  const studentsErrorMessage = studentsError ? getErrorMessage(studentsError) : '';
  const teachersErrorMessage = teachersError ? getErrorMessage(teachersError) : '';

  const handleStudentChange = (event: SelectChangeEvent<string>) => {
    setSelectedStudent(event.target.value);
  };

  const handleTeacherChange = (event: SelectChangeEvent<string>) => {
    setSelectedTeacher(event.target.value);
  };
  const handleAssign = async () => {
    if (selectedStudent && selectedTeacher) {
      try {
        await assignTeachers({
          studentId: parseInt(selectedStudent, 10), // Parse studentId as a number
          teacherId: selectedTeacher, // Keep teacherId as a string
        }).unwrap();
        // Success handling here
      } catch (error) {
        console.error('Failed to assign teachers:', getErrorMessage(error));
      }
    } else {
      console.error("Both student and teacher must be selected");
    }
  };
  
  

  return (
    <div>
      {studentsError && <div>Error fetching students: {studentsErrorMessage}</div>}
      {teachersError && <div>Error fetching teachers: {teachersErrorMessage}</div>}

      {!studentsLoading && studentsData && (
        <div>
          <h2>Assign Teachers</h2>

          <div className="flex flex-col mb-4">
            <label htmlFor="student" className="mb-2 block text-sm text-gray-600">Student</label>
            <Select
              value={selectedStudent ?? ''}
              onChange={handleStudentChange}
              className="h-12 rounded border border-gray-400"
              displayEmpty
            >
              {studentsLoading ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                </MenuItem>
              ) : studentsData.length > 0 ? (
                studentsData.map(student => (
                  <MenuItem key={student.id} value={student.id.toString()}>
                    {student.firstName} {student.lastName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No students available</MenuItem>
              )}
            </Select>
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="teacher" className="mb-2 block text-sm text-gray-600">Teacher</label>
            <Select
              value={selectedTeacher ?? ''}
              onChange={handleTeacherChange}
              className="h-12 rounded border border-gray-400"
              displayEmpty
            >
              {teachersLoading ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                </MenuItem>
              ) : teachersData?.list && teachersData.list.length > 0 ? (
                teachersData.list.map(teacher => (
                  <MenuItem key={teacher.id} value={teacher.id.toString()}>
                    {teacher.firstName} {teacher.lastName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No teachers available</MenuItem>
              )}
            </Select>
          </div>

          <Button
            onClick={handleAssign}
            variant="contained"
            color="primary"
            disabled={studentsLoading || teachersLoading}
          >
            Assign Teachers
          </Button>
        </div>
      )}
    </div>
  );
};

export default AssignmentsComponent;
