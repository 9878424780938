import { AppointmentModel } from './model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	appoints: AppointmentModel | null;
}

const initialState: InitialState = {
	appoints: null,
};
console.log(initialState)
export const appointSlice = createSlice({
	initialState,
	name: 'appoints',
	reducers: {
		setAppoints: (state, action: PayloadAction<AppointmentModel>) => {
			state.appoints = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
