import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import baseApi from "@/core/lib/base";
import toast from "react-hot-toast";

// Define the type for UserResponse
interface UserResponse {
  list: Parent[]; // Assuming the API response has a 'list' field that contains an array of parents
}

// Define the type for Parent
interface Parent {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phoneNumber: string;
  password: string;
  occupation: string;
  role: string;
  creatorId: string | null;
  status: number;
  created_at: string;
  updated_at: string;
}

// Define the type for Student
interface Student {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone_1: string;
  phone_2: string;
  motherName: string;
  fatherName: string;
  gender: string;
  dob: string;
  placeOfBirth: string;
  photo: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  location: string | null;
  teachers: any[]; // Adjust if you have a specific type for teachers
  parents: Parent[];
  post: any[]; // Adjust if you have a specific type for post
}

// Define the type for AssignParentModel
export interface AssignParentModel {
  // Define properties of AssignParentModel here
}

// Define a generic ResponseModel type
interface ResponseModel<T = void> {
  data: T;
  message?: string;
}

// Define the API endpoints for managing parent assignments
export const AssignParentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch assignments
    getAssignments: builder.query<AssignParentModel[], { studentId: number }>({
      query: (params) => ({
        method: "GET",
        url: `/api/v2/student/${params.studentId}/parents`,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(assignParentSlice.actions.setAssignment(data)); // Directly set data
        } catch (error) {
          throw error;
        }
      },
    }),

    // Fetch all assigned students' parents
    getAssignedStudentsParents: builder.query<UserResponse, void>({
      query: () => ({
        method: "GET",
        url: '/api/v2/student/assigned-parents',
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        dispatch(assignParentSlice.actions.fetchAssignedStudentsParentsRequest());
        try {
          const { data } = await queryFulfilled;
          dispatch(assignParentSlice.actions.fetchAssignedStudentsParentsSuccess(data.list)); // Access 'list' field
        } catch (error) {
          dispatch(assignParentSlice.actions.fetchAssignedStudentsParentsFailure('Failed to fetch assigned students and parents'));
        }
      },
    }),

    // Assign parents to a student
    assignParents: builder.mutation<ResponseModel<null>, { studentId: number; parentId: number }>({
      query: (payload) => ({
        method: "POST",
        url: `/api/v2/student/${payload.studentId}/assign-parent`,
        body: {
          parentId: payload.parentId,
        },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success("Parents assigned successfully");
        } catch (error: any) {
          console.error("Error details:", error);
          toast.error(error?.data?.message || "Failed to assign parents");
        }
      },
    }),

    // Update assignments
    updateAssignment: builder.mutation<ResponseModel<null>, { studentId: number; parentIds: number[] }>({
      query: ({ studentId, parentIds }) => ({
        method: "PATCH",
        url: `/api/v2/student/${studentId}/update-parents`,
        body: { parentIds },
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success("Assignment updated successfully");
        } catch (error: any) {
          toast.error(error?.data?.message || "Failed to update assignment");
        }
      },
    }),

    // Delete assignments
    deleteAssignment: builder.mutation<void, { studentId: number; parentId: string }>({
      query: ({ studentId, parentId }) => ({
        method: "DELETE",
        url: `/api/v2/student/${studentId}/remove-parent/${parentId}`,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success("Parent removed from assignment");
        } catch (error: any) {
          console.error("Error details:", error);
          const errorMessage = error?.data?.message || "Failed to remove parent";
          toast.error(errorMessage);
        }
      },
    }),

    // Fetch parents with role 'parent'
    getParents: builder.query<UserResponse, void>({
      query: () => ({
        method: "GET",
        url: '/api/v2/user',
        params: {
          role: "parent", // Correct role to fetch parents
        },
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        dispatch(assignParentSlice.actions.fetchParentsRequest());
        try {
          const { data } = await queryFulfilled;
          dispatch(assignParentSlice.actions.fetchParentsSuccess(data.list)); // Access 'list' field
        } catch (error) {
          dispatch(assignParentSlice.actions.fetchParentsFailure('Failed to fetch parents'));
        }
      },
    }),

    // Fetch students
    getStudentss: builder.query<Student[], void>({
      query: () => ({
        method: "GET",
        url: "/api/v2/student",
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        dispatch(assignParentSlice.actions.fetchStudentsRequest());
        try {
          const { data } = await queryFulfilled;
          dispatch(assignParentSlice.actions.fetchStudentsSuccess(data)); // Directly use data array
        } catch (error) {
          dispatch(assignParentSlice.actions.fetchStudentsFailure('Failed to fetch students'));
        }
      },
    }),
  }),
});

// Slice for handling parent assignment and user state
export const assignParentSlice = createSlice({
  name: 'assignParents',
  initialState: {
    assignments: [] as AssignParentModel[],
    parents: [] as Parent[], // Updated state for parents
    students: [] as Student[], // Updated state for students
    assignedStudentsParents: [] as Parent[], // Added state for assigned students' parents
    loading: false,
    error: null as string | null,
  },
  reducers: {
    setAssignment: (state, action: PayloadAction<AssignParentModel[]>) => {
      state.assignments = action.payload;
    },
    fetchAssignedStudentsParentsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchAssignedStudentsParentsSuccess: (state, action: PayloadAction<Parent[]>) => {
      state.assignedStudentsParents = action.payload;
      state.loading = false;
    },
    fetchAssignedStudentsParentsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchParentsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchParentsSuccess: (state, action: PayloadAction<Parent[]>) => {
      state.parents = action.payload;
      state.loading = false;
    },
    fetchParentsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchStudentsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchStudentsSuccess: (state, action: PayloadAction<Student[]>) => {
      state.students = action.payload;
      state.loading = false;
    },
    fetchStudentsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Export hooks for using the API in components
export const { 
  useGetAssignmentsQuery,
  useAssignParentsMutation,
  useUpdateAssignmentMutation,
  useDeleteAssignmentMutation,
  useGetParentsQuery, // Export the hook for fetching parents
  useGetStudentssQuery, // Export the hook for fetching students
  useGetAssignedStudentsParentsQuery, // Export the hook for fetching assigned students' parents
} = AssignParentsApi;

export const { 
  setAssignment, 
  fetchParentsRequest, 
  fetchParentsSuccess, 
  fetchParentsFailure,
  fetchStudentsRequest,
  fetchStudentsSuccess,
  fetchStudentsFailure,
  fetchAssignedStudentsParentsRequest,
  fetchAssignedStudentsParentsSuccess,
  fetchAssignedStudentsParentsFailure,
} = assignParentSlice.actions;

export default assignParentSlice.reducer;
