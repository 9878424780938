import React, { useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";
import { z } from "zod";
import { useUpdateCategoryMutation } from "../Api/redux";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Form } from "@/components/form";

const schema = z.object({
  description: z.object({
    en: z.string().min(1, "English description is required"),
    fr: z.string().min(1, "French description is required"),
    ki: z.string().min(1, "Kinyarwanda description is required"),
  }),
  icon: z.string().min(1, "Icon is required"),
  rangeFrom: z.string().min(1, "Lower Age is required"),
  rangeTo: z.string().min(1, "Upper age is required"),
  lowLevel: z.string().min(1, "Low level is required"),
  highLevel: z.string().min(1, "High level is required"),
});

export type CatInput = z.TypeOf<typeof schema>;

export default function EditCategory({ data }: any) {
  const dialog = useRef<HTMLDialogElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [UpdateCategory, { isLoading: isUpdating }] =
    useUpdateCategoryMutation();

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };

  return (
    <>
      <dialog
        ref={dialog}
        className="dialog w-[40%] rounded-lg shadow-lg"
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="rounded p-3">
          <div className="flex flex-row items-center justify-center">
            <h3 className="p-2 text-lg text-gray-600">Update Category</h3>
          </div>
          <Form<CatInput, typeof schema>
            schema={schema}
            onSubmit={ async(payload) => {
               
                const updatedPayload: Partial<CatInput> = {
                  description: {
                    en: payload.description.en,
                    fr: payload.description.fr,
                    ki: payload.description.ki,
                  },
                  icon: payload.icon,
                  rangeFrom: String(payload.rangeFrom), 
                  rangeTo: String(payload.rangeTo),
                  lowLevel: String(payload.lowLevel), 
                  highLevel: String(payload.highLevel), 
                };
      
                await UpdateCategory({ id: data.id, data: updatedPayload });
               closeModel()
              }}
          >
            {({ register, formState }: any) => (
              <>
                <div className=" grid grid-cols-2  gap-2">
                  <InputField
                    type="text"
                    label="English Description"
                    defaultValue=      {data.description.en}
                    loading={isLoading}
                    error={formState.errors.description?.fr}
                    registration={register("description.en")}
                    className="h-13 mt-0"
                  />
                  <InputField
                    type="text"
                    label="French Description"
                    defaultValue= {data.description.fr}
                    loading={isLoading}
                    error={formState.errors.description?.fr}
                    registration={register("description.fr")}
                    className="h-13 mt-0"
                  />

                  <InputField
                    type="text"
                    label="Kinyarwanda Description"
                    defaultValue= {data.description.ki}
                    loading={isLoading}
                    error={formState.errors.description?.ki}
                    registration={register("description.ki")}
                    className="h-13 mt-0"
                  />

                  <InputField
                    type="text"
                    label="Icon"
                    defaultValue= {data.icon}
                    loading={isLoading}
                    error={formState.errors.icon}
                    registration={register("icon")}
                    className="h-13"
                  />

                  <InputField
                    type="number"
                    label="Range From"
                    defaultValue= {data.rangeFrom}
                    loading={isLoading}
                    error={formState.errors.rangeFrom}
                    registration={register("rangeFrom")}
                    className="h-13"
                  />

                  <InputField
                    type="number"
                    label="Range To"
                    defaultValue={data.rangeTo}
                    loading={isLoading}
                    error={formState.errors.rangeTo}
                    registration={register("rangeTo")}
                    className="h-13"
                  />

                  <InputField
                    type="number"
                    label="Low Level"
                    defaultValue={data.lowLevel}
                    loading={isLoading}
                    error={formState.errors.lowLevel}
                    registration={register("lowLevel")}
                    className="h-13"
                  />

                  <InputField
                    type="number"
                    label="High Level"
                    defaultValue={data.highLevel}
                    loading={isLoading}
                    error={formState.errors.highLevel}
                    registration={register("highLevel")}
                    className="h-13"
                  />
                </div>
                <button
                  type="submit"
                  className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                  disabled={isLoading || isUpdating}
                >
                  {isLoading || isUpdating ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Update Category"
                  )}
                </button>
              </>
            )}
          </Form>
        </div>
      </dialog>
      <button onClick={() => openModel()} className="m-2 text-gray-600 hover:text-black   ">
        <PencilIcon className="h-5 w-5 " />
      </button>
    </>
  );
}
