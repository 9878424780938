/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useWhoamiQuery } from "@/app/auth/API";
import { useAppSelector } from "@/core/lib";
import { storage } from "@/utils";
import avatr from "@/assets/images/igaju logo.png";

export const Settings = () => {
  const [activeTab, setActiveTab] = useState("account");
  const [editMode, setEditMode] = useState(false);

  const toggleTab = (tab: any) => {
    setActiveTab(tab);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const { isLoading } = useWhoamiQuery(null, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });
  const { user: userData }: any = useAppSelector((state) => state.userState);
  console.log(userData);
  return (
    <div className="scrollbar-hidden container flex h-screen max-w-full justify-center capitalize">
      <div className="setting flex w-[100%] flex-col rounded-lg">
        <div>
          <p className="mb-4 text-3xl text-gray-600">Settings</p>
        </div>
        <div className="flex w-full flex-row justify-start border-primary text-gray-500">
          <div
            className={`cursor-pointer p-2 ${
              activeTab === "account"
                ? "rounded border-b-4 border-teal-900 text-gray-500"
                : ""
            }`}
            onClick={() => toggleTab("account")}
          >
            <p>Account Settings</p>
          </div>
        </div>
        <div className="w-full text-gray-600">
          {activeTab === "account" && (
            <div>
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <div className="flex w-full flex-row rounded-md shadow">
                  <div className="mt-0 flex w-1/4 items-center justify-center bg-gray-100">
                    <div className="p-2 ">
                      <p>
                        Your Account Information click on edit to edit details
                      </p>
                    </div>
                  </div>
                  <div className="mt-0 flex w-3/4 flex-col items-center bg-white p-4">
                    <div className="flex w-full flex-row px-3">
                      <div>
                        <img
                          src={avatr}
                          alt="Profile Picture"
                          className="z-10 h-20 w-20"
                        />
                        <div></div>
                      </div>
                      <div className="ml-4 flex flex-col items-start justify-end">
                        <p className="text-md font-semibold text-gray-500 ">
                          <span className=" font-normal ">
                            Username:
                          </span>{" "}
                          {userData?.username}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-normal">Role:</span>{" "}
                          {userData?.role}
                        </p>
                        {userData?.status === 1 ? (
                          <span className="rounded-lg bg-green-500 text-xs py-1 px-1 text-white">
                            Active
                          </span>
                        ) : (
                          <span className="rounded-lg bg-red-400 text-xs py-1 px-2 text-white">
                            Inactive
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="w-full items-center p-4">
                      <h3 className="mb-2 text-lg font-semibold">
                        Personal Information
                      </h3>
                      <div className="mt-0 grid grid-cols-2 gap-2 ">
                      <div className="mt-2 ">
                          <p className="font-bold text-gray-500">First Name:</p>
                          <p className="text-gray-600">
                            {editMode ? (
                              <input
                                type="text"
                                className="form-control h-10 px-2"
                                defaultValue={userData?.firstName}
                              />
                            ) : (
                              userData?.firstName || "Not Provided"
                            )}
                          </p>
                        </div>
                        <div className="mt-2 ">
                          <p className=" font-bold text-gray-500">Last Name:</p>
                          <p className="text-gray-600">
                            {editMode ? (
                              <input
                                type="text"
                                className="form-control h-10 px-2"
                                defaultValue={userData?.lastName}
                              />
                            ) : (
                              userData?.lastName || "Not Provided"
                            )}
                          </p>
                        </div>
                        <div className="mt-2 ">
                          <p className="font-bold text-gray-500">Username:</p>
                          <p className="text-gray-600">
                            {editMode ? (
                              <input
                                type="text"
                                className="form-control h-10 px-2"
                                defaultValue={userData?.username}
                              />
                            ) : (
                              userData?.username || "Not Provided"
                            )}
                          </p>
                        </div>
                        <div>
                          <p className=" font-bold text-gray-500">Email:</p>
                          <p className="text-gray-600">
                            {editMode ? (
                              <input
                                type="text"
                                disabled
                                className="form-control h-10 px-2"
                                defaultValue={userData?.email}
                              />
                            ) : (
                              userData?.email || "Not Provided"
                            )}
                          </p>
                        </div>

                        <div className="mt-2 ">
                          <p className=" font-bold text-gray-500">
                            Occupation:
                          </p>
                          <p className="text-gray-600">
                            {editMode ? (
                              <input
                                type="text"
                                className="form-control h-10 px-2"
                                defaultValue={userData?.occupation}
                              />
                            ) : (
                              userData?.occupation || "Not Provided"
                            )}
                          </p>
                        </div>

                        <div className="mt-2 ">
                          <p className=" font-bold text-gray-500">
                            Phone Number :
                          </p>
                          <p className="text-gray-600">
                            {editMode ? (
                              <input
                                type="text"
                                className="form-control h-10 px-2"
                                defaultValue={userData?.PhoneNumber}
                              />
                            ) : (
                              userData?.PhoneNumber || "Not Provided"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="mt-3 h-0 w-full border"></div>
                      <div className="mt-2 flex flex-row justify-end">
                        {editMode ? (
                          <>
                            <button
                              type="button"
                              className="cursor-pointer rounded-lg border border-primary px-4 py-2"
                              onClick={toggleEditMode}
                            >
                              Cancel
                            </button>
                            <button className="mx-2 cursor-pointer rounded-lg border bg-primary px-5 py-2 text-white">
                              Save
                            </button>
                          </>
                        ) : (
                          <button
                            className="cursor-pointer rounded-lg border bg-primary px-5 py-2 text-white"
                            onClick={toggleEditMode}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
