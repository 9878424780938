import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';
import translationKI from './locales/ki.json';

// Initialize i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      fr: {
        translation: translationFR,
      },
      ki: {
        translation: translationKI,
      },
      
    },
    fallbackLng: 'en', // Fallback to English if a translation is missing
    debug: false, // Set to true for debugging
    interpolation: {
      escapeValue: false, // React already escapes values, so no need to escape again
    },
  });

export default i18n;
