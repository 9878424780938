import React, { useRef } from "react";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { useDeleteAssignmentMutation } from "../API/redux"; // Adjust import path if needed
import { TrashIcon } from "@heroicons/react/24/outline";

interface DeleteAssignmentProps {
  data: {
    id: string; // Change from string to number
    assignmentName: string;
    studentId: number; // Add studentId
  };
  handleFilter: () => void; // Function to handle filter updates if needed
}

export default function DeleteAssignment({ data, handleFilter }: DeleteAssignmentProps) {
  const dialog = useRef<HTMLDialogElement>(null);

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };

  const [deleteAssignment, { isLoading }] = useDeleteAssignmentMutation();

  const handleDelete = () => {
    deleteAssignment({ studentId: data.studentId, parentId: data.id })
      .then(() => {
        toast.success("Assignment deleted successfully");
        handleFilter(); // Refresh the list or perform any other action after deletion
        closeModel();
      })
      .catch((error) => {
        console.error("Delete failed:", error);
        toast.error("Deleting failed");
      });
  };

  return (
    <>
      <dialog
        ref={dialog}
        className="rounded-lg shadow-lg"
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="rounded p-3">
          <div className="flex flex-row items-center justify-center">
            <h3 className="p-2 text-lg text-gray-600">
              <i className="fas fa-exclamation-triangle mx-2 text-xl text-red-500"></i>
              Delete Assignment
            </h3>
          </div>
          <div>
            <div className="form-group">
              <div className="flex flex-col justify-center items-start">
                <p className="text-lg text-gray-500 capitalize">
                  Are you sure you want to delete this assignment?
                </p>
                <p className="font-bold text-md capitalize text-gray-500">
                  Assignment: <span className="font-medium">{data.assignmentName}</span>
                </p>
              </div>
            </div>

            <div className="mt-2 flex flex-row justify-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="rounded bg-primary px-4 py-2 text-white"
                  disabled
                >
                  <CircularProgress size={24} sx={{ color: "white" }} />
                </button>
              ) : (
                <button
                  type="submit"
                  className="rounded bg-primary px-4 py-2 text-white"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              )}
              <button
                type="button"
                className="mx-2 cursor-pointer rounded bg-primary px-4 py-2 text-white"
                onClick={closeModel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </dialog>
      <button
        onClick={() => openModel()}
        className="mx-1 rounded text-red-400 hover:text-red-600"
      >
        <TrashIcon className="w-5 h-5" />
      </button>
    </>
  );
}
