import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";
import { z } from "zod";
import { Form } from "@/components/form";
import { useAddCategoryMutation } from "../Api/redux";
import { NavLink, useNavigate } from "react-router-dom";

const schema = z.object({
  description: z.object({
    en: z.string().min(1, "English description is required"),
    fr: z.string().min(1, "French description is required"),
    ki: z.string().min(1, "Kinyarwanda description is required"),
  }),
  icon: z.string().min(1, "Icon is required"),
  rangeFrom: z.string().min(1, "Lower Age is required"),
  rangeTo: z.string().min(1, "Upper age is required"),
  lowLevel: z.string().min(1, "Low level is required"),
  highLevel: z.string().min(1, "Hight level is required"),
});

export type CatInput = z.TypeOf<typeof schema>;

export default function AddCategory({ handleFilterAndAdd }: any) {
  const navigate = useNavigate();

  const [OnPost, { isSuccess, isLoading }] = useAddCategoryMutation();

  return (
    <div className="rounded p-3">
      <div className="flex flex-row items-center justify-between pb-5    ">
        <h3 className="  text-2xl font-medium   text-gray-600">Add Category</h3>
        <NavLink to="/dashboard/categories">
          <button className="mx-5 rounded  bg-primary px-2  py-1  text-white ">
            {" "}
            All Categories
          </button>
        </NavLink>
      </div>
      <Form<CatInput, typeof schema>
        schema={schema}
        onSubmit={(payload) => OnPost(payload)}
      >
        {({ register, formState }) => (
          <>
            <div className=" grid grid-cols-2  gap-2">
              <InputField
                type="text"
                label="English Description"
                placeholder="English Description"
                loading={isLoading}
                error={formState.errors.description?.fr}
                registration={register("description.en")}
                className="h-13 mt-0"
              />
              <InputField
                type="text"
                label="French Description"
                placeholder="French Description"
                loading={isLoading}
                error={formState.errors.description?.fr}
                registration={register("description.fr")}
                className="h-13 mt-0"
              />

              <InputField
                type="text"
                label="Kinyarwanda Description"
                placeholder="Kinyarwanda Description"
                loading={isLoading}
                error={formState.errors.description?.ki}
                registration={register("description.ki")}
                className="h-13 mt-0"
              />

              <InputField
                type="text"
                label="Icon"
                placeholder="Icon"
                loading={isLoading}
                error={formState.errors.icon}
                registration={register("icon")}
                className="h-13"
              />

              <InputField
                type="number"
                label="Range From"
                placeholder="Range From"
                loading={isLoading}
                error={formState.errors.rangeFrom}
                registration={register("rangeFrom")}
                className="h-13"
              />

              <InputField
                type="number"
                label="Range To"
                placeholder="Range To"
                loading={isLoading}
                error={formState.errors.rangeTo}
                registration={register("rangeTo")}
                className="h-13"
              />

              <InputField
                type="number"
                label="Low Level"
                placeholder="Low Level"
                loading={isLoading}
                error={formState.errors.lowLevel}
                registration={register("lowLevel")}
                className="h-13"
              />

              <InputField
                type="number"
                label="High Level"
                placeholder="High Level"
                loading={isLoading}
                error={formState.errors.highLevel}
                registration={register("highLevel")}
                className="h-13"
              />
            </div>
            {isLoading ? (
              <button
                type="submit"
                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                disabled
              >
                <CircularProgress size={24} sx={{ color: "white" }} />
              </button>
            ) : (
              <button
                type="submit"
                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
              >
                Add Category
              </button>
            )}
          </>
        )}
      </Form>
    </div>
  );
}
