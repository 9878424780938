import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { InputField } from "@/components/input";
import { z } from "zod";
import { Form } from "@/components/form";
import { NavLink } from "react-router-dom";
import { useAdminSignMutation } from "@/app/auth/API";
import Select from "@/components/select/SelectField";

const schema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  username: z.string().min(1, "Username is required"),
  email: z.string().min(1, "Email is required").email(),
  phoneNumber: z.string().min(1, "Phone number is required"),
  occupation: z.string().min(1, "Occupation is required"),
  password: z.string().min(1, "Password is required"),
  role: z
    .string()
    .min(1, "Role is required")
    .refine((role) => ["admin", "user", "super_admin","teacher","parent"].includes(role), {
      message: "Role must be 'admin,' 'user,' 'teacher' , 'parent' or 'super_admin'",
    }),
});

export type UserInput = z.TypeOf<typeof schema>;

export default function AddUser({ handleFilterAndAdd }: any) {
  const [OnPost, { isLoading }] = useAdminSignMutation();
  const [selectedRole, setSelectedRole] = useState(""); // State to store the selected role
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // State for success message
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // State for error message

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(event.target.value);
  };

  return (
    <div className="rounded bg-white p-3 shadow">
      <div className="flex flex-row items-center justify-between pb-5">
        <h3 className="text-2xl font-medium text-gray-600">Add User</h3>
        <NavLink to="/dashboard/users">
          <button className="mx-5 rounded bg-primary px-2 py-1 text-white">
            All users
          </button>
        </NavLink>
      </div>
      {successMessage && (
        <div className="mb-4 rounded bg-green-100 p-3 text-green-700">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="mb-4 rounded bg-red-100 p-3 text-red-700">
          {errorMessage}
        </div>
      )}
      <Form<UserInput, typeof schema>
        schema={schema}
        onSubmit={async (payload) => {
          try {
            await OnPost(payload);
            setSuccessMessage("User has been successfully registered!");
            setErrorMessage(null); // Clear any previous error messages
          } catch (error: any) {
            setSuccessMessage(null); // Clear success message
            setErrorMessage(error.message || "Registration failed. Please try again.");
          }
        }}
      >
        {({ register, formState }: any) => (
          <>
            <div className="grid grid-cols-2 gap-2">
              <InputField
                type="text"
                label="First Name"
                placeholder="FirstName"
                loading={isLoading}
                error={formState.errors.firstName}
                registration={register("firstName")}
                className="h-13 mt-0"
              />
              <InputField
                type="text"
                label="Last Name"
                placeholder="LastName"
                error={formState.errors.lastName}
                registration={register("lastName")}
                className="h-13 mt-0"
              />
              <InputField
                type="text"
                label="Username"
                placeholder="Username"
                error={formState.errors.username}
                registration={register("username")}
                className="h-13 mt-0"
              />
              <InputField
                type="text"
                label="Email"
                placeholder="Email"
                error={formState.errors.email}
                registration={register("email")}
                className="h-13 mt-0"
              />

              <InputField
                type="text"
                label="Phone Number"
                placeholder="Phone number"
                loading={isLoading}
                error={formState.errors.phoneNumber}
                registration={register("phoneNumber")}
                className="h-13 mt-0"
              />
              <InputField
                type="text"
                label="Occupation"
                placeholder="Occupation ex. Teacher"
                loading={isLoading}
                error={formState.errors.occupation}
                registration={register("occupation")}
                className="h-13 mt-0"
              />

              <div className="flex flex-col">
                <label htmlFor="" className="mb-2 block text-sm text-gray-600">
                  Gender
                </label>

                <Select
                  name="role"
                  value={selectedRole}
                  onChange={handleRoleChange}
                  registration={register("role")}
                >
                  <option value="">Select role</option>
                  <option value="user">User</option>
                  <option value ="teacher">Teacher</option>
                  <option value="parent"> Parent</option>
                  <option value="admin">Admin</option>
                  <option value="super_admin">Super Admin</option>
                </Select>
                {formState.errors.role && (
                  <span className="font-italics mt-2 block text-sm font-medium capitalize text-danger">
                    {formState.errors.role.message}
                  </span>
                )}
              </div>
              <InputField
                type="password"
                label="Password"
                placeholder="**************"
                loading={isLoading}
                error={formState.errors.password}
                registration={register("password")}
                className="h-13 mt-0"
              />
            </div>

            <button
              type="submit"
              className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Add User"
              )}
            </button>
          </>
        )}
      </Form>
    </div>
  );
}
