import { PatientAppointmentModel } from './model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	patientAppoints: PatientAppointmentModel | null;
}

const initialState: InitialState = {
	patientAppoints: null,
};
console.log(initialState)
export const patientAppointSlice = createSlice({
	initialState,
	name: 'appoints',
	reducers: {
		setPatientAppoints: (state, action: PayloadAction<PatientAppointmentModel>) => {
			state.patientAppoints = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
