
import config from '@/config';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import baseApi from './base';
import { authApi } from '@/app/auth/API';
import { userSlice } from '@/app/auth/API/redux/user';
import { categorySlice } from '@/app/dashboard/category/Api/redux/categories';
import { questionsSlice } from '@/app/dashboard/questions/API/redux/questions';
import { answerSlice } from '@/app/dashboard/answers/Api/redux/answers';
import { appointSlice } from '@/app/dashboard/appointment/redux/appoints';
import { patientSlice } from '@/app/dashboard/patients/API/redux/patients';
import { studentSlice } from '@/app/dashboard/students/API/redux/students';
import { usersSlice } from '@/app/auth/API/redux/user/usersSlice';
import { DatesSlice } from '@/app/dashboard/appointment/redux/appoints/available';
import { patientAppointSlice } from '@/app/dashboard/appointment-Appointment/redux/appoints';



const reducers = {  
    userState: userSlice.reducer,
	questionState:questionsSlice.reducer,
    categoryState: categorySlice.reducer, 
	answerState:answerSlice.reducer,
	usersState:usersSlice.reducer,
	availableDatesSlice:DatesSlice.reducer,
	patientState:patientSlice.reducer,
	studentState:studentSlice.reducer,
	patientAppointState:patientAppointSlice.reducer,
	appointsState:appointSlice.reducer,
	[authApi.reducerPath]: authApi.reducer,
};

const middlewares = [
	baseApi.middleware,
];

export const store = configureStore({
	reducer: reducers,
	devTools: config.NODE_ENV === 'development',
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(middlewares),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
