import React from "react";
import ReactApexChart from "react-apexcharts";

interface PieChartProps {
  chartData: {
    series: number[];
    labels: string[];
  };
}

const PieChart: React.FC<PieChartProps> = ({ chartData }) => {
  const options:any = {
    series: chartData.series,
    chart: {
      height: 350,
      type: "pie",
    },
    labels: chartData.labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <ReactApexChart options={options} series={chartData.series} type="pie" height={350} />
    </div>
  );
};

export default PieChart;
